
import PropTypes from "prop-types";
import React from "react";

const Plus = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M13 6a1 1 0 1 0-2 0v5H6a1 1 0 1 0 0 2h5v5a1 1 0 1 0 2 0v-5h5a1 1 0 1 0 0-2h-5V6z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default Plus;

Plus.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
