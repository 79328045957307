import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import InputField from 'Components/InputField';
import Chip from 'Components/Chip';

const normalizeString = (str = "") => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim();
}

const AutocompleteMultipleCategories = forwardRef((props, ref) => {
  const { options, label, onChange, value, error, disabled } = props;

  const valueFormatted = useMemo(() => value.map((id) => options.find((option) => option.id === id)).filter(Boolean), [value, options]);

  return (
    <Autocomplete
      ref={ref}
      multiple
      options={options}
      value={valueFormatted}
      disabled={disabled}
      filterOptions={(options, state) => {
        const inputValue = normalizeString(state.inputValue);
        return options
          .filter(option => valueFormatted.every((value) => value.id !== option.id))
          .filter((option) => {
            const name = normalizeString(option.name);
            return name.includes(inputValue);
          });
      }}
      noOptionsText="No hay opciones"
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      renderOption={(optionProps, option) => {
        return (
          <li {...optionProps} key={option.id} style={{ padding: option.parent_tag_id ? "6px 6px 6px 12px" : "6px" }}>
            <Chip color={option.color} icon={option.icon}>{option.name}</Chip>
          </li>
        )
      }}
      onChange={(_event, newValue) => {
        const ids = newValue.map((value) => value.id);
        onChange(ids);
      }}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => (
          <Chip color={option.color} icon={option.icon} {...getTagProps({ index })} key={option.id}>
            {option.name}
          </Chip>
        ))
      }}
      renderInput={(params) => (
        <InputField {...params} label={label} error={error} />
      )}
    />
  )
});

AutocompleteMultipleCategories.displayName = 'AutocompleteMultipleCategories';

AutocompleteMultipleCategories.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    color: PropTypes.string,
    icon: PropTypes.node,
    parent_tag_id: PropTypes.string,
    bgColor: PropTypes.string,
  })).isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
}

export default AutocompleteMultipleCategories;