import React from "react";
import FormProvider, { RHFTextField } from "Components/HookForm";
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from "Components/Button";
import PropTypes from "prop-types";
import { useQueryClient } from "@tanstack/react-query";
import { joinProject } from "Services/user";
import { useTranslation } from "react-i18next";

const AuthSignInEmailForm = ({ onComplete }) => {
    const { t } = useTranslation("common");

    const LoginSchema = Yup.object().shape({
        owner_email: Yup.string().required(t("join-project.email-valid")).email(t("join-project.email-valid")),
        support_password: Yup.string().required(t("join-project.password-valid")).min(3, t("join-project.password-valid"))
    });

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues: {
            owner_email: '',
            support_password: '',
        }
    });

    const queryClient = useQueryClient();

    const {
        handleSubmit,
        setError,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = async (data) => {
        try {
            await joinProject(data);
            await queryClient.invalidateQueries(["projects"]);
            onComplete();
        } catch (error) {
            if (error.response?.status === 400) {
                setError("support_password", { type: "manual", message: t("join-project.password-wrong") });
                return;
            }
        }
    };


    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex flex-column gap-2 mb-4">
                <RHFTextField
                    type="email"
                    name="owner_email"
                    label={t("join-project.owner-email")}
                />

                <RHFTextField
                    type="password"
                    name="support_password"
                    label={t("join-project.support-password")}
                />
            </div>
            <Button size="large" primary type="submit" className="w-100" isBusy={isSubmitting}>
                {t("join-project.join")}
            </Button>
        </FormProvider>
    )
}

export default AuthSignInEmailForm;

AuthSignInEmailForm.propTypes = {
    onComplete: PropTypes.func.isRequired,
};