
import PropTypes from "prop-types";
import React from "react";

const Tag2 = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M1 3a1 1 0 0 1 1-1h9.91a1 1 0 0 1 .708.293l8.515 8.517a2.983 2.983 0 0 1 0 4.206l-.002.002-7.106 7.107a2.982 2.982 0 0 1-4.22 0L1.294 13.62A1 1 0 0 1 1 12.913V3Zm2 1v8.499l8.22 8.213a.982.982 0 0 0 1.39 0l7.104-7.106h.001a.983.983 0 0 0 0-1.385L11.496 4H3Z" fill={color} fillRule="evenodd"/>
	<path d="M5.955 7.956a1 1 0 0 1 1-1h.01a1 1 0 0 1 0 2h-.01a1 1 0 0 1-1-1Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default Tag2;

Tag2.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
