import React, { Fragment } from "react";
import Container from "Components/Container"
import classes from "./Footer.module.css";
import { useTranslation } from "react-i18next";

const LEGAL_ROUTES = {
    ES: [{
        i18nKey: "privacy",
        url: "https://banktrack.com/privacidad",
    }, {
        i18nKey: "terms",
        url: "https://banktrack.com/condiciones-de-uso",
    }],
    EN: [{
        i18nKey: "privacy",
        url: "https://banktrack.com/en/privacy-policy",
    }, {
        i18nKey: "terms",
        url: "https://banktrack.com/en/terms-and-conditions",
    }]
}

export default function Footer() {
    const year = new Date().getFullYear();
    const { t, i18n } = useTranslation("footer");

    const links = LEGAL_ROUTES[i18n.language] || LEGAL_ROUTES.EN;

    return (
        <Container className="py-5 mt-5 opacity-25 text-small">
            <div className={classes.row}>
                <div>
                    {t("copy", { year })}
                </div>
                <div>
                    {links.map((link, index) => (
                        <Fragment key={index}>
                            {index > 0 && <span className="mx-3">/</span>}

                            <a href={link.url} target="_blank" rel="noreferrer" className="text-decoration-none">
                                {t(link.i18nKey)}
                            </a>
                        </Fragment>
                    ))}
                </div>
            </div>
        </Container>
    )
}
