import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import Editable from 'Components/Editable';

export default function RHFEditable({ name, ...other }) {
    const { control } = useFormContext();

    return (
        <div>
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <Editable
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        defaultValue={field.value}
                        error={error?.message}
                        {...other}
                    />
                )
                }
            />
        </div>
    );
}

RHFEditable.propTypes = {
    name: PropTypes.string,
};