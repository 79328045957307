
import PropTypes from "prop-types";
import React from "react";

const EyeOff = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M1.707.293A1 1 0 0 0 .293 1.707l4.276 4.275a19.45 19.45 0 0 0-4.45 5.545 1 1 0 0 0-.013.92c.016.032-.008-.013-.008-.013l.065.123a18.507 18.507 0 0 0 .732 1.235 20.67 20.67 0 0 0 2.25 2.892C5.105 18.774 8.1 21 12 21h.016a11.07 11.07 0 0 0 5.81-1.76l4.467 4.467a1 1 0 1 0 1.414-1.415l-5.052-5.051-.018-.018L6.775 5.36a1.009 1.009 0 0 0-.018-.018L1.707.293ZM5.987 7.4a17.45 17.45 0 0 0-3.844 4.602 18.674 18.674 0 0 0 2.462 3.313c1.789 1.908 4.29 3.68 7.387 3.684a9.07 9.07 0 0 0 4.38-1.215l-2.322-2.321A4 4 0 0 1 8.536 9.95L5.987 7.4Zm4.04 4.04a1.999 1.999 0 0 0 2.53 2.53l-2.53-2.53Zm.1-6.228A8.122 8.122 0 0 1 11.998 5H12c3.1 0 5.605 1.773 7.395 3.684.887.945 1.57 1.894 2.03 2.608.18.277.433.706.433.706s-1.114 1.753-1.783 2.548a1 1 0 0 0 1.53 1.288 19.5 19.5 0 0 0 2.277-3.363 1 1 0 0 0 .012-.918l-.004-.01c-.04-.073-.14-.265-.204-.377a20.673 20.673 0 0 0-2.831-3.85C18.895 5.226 15.9 3 12 3a10.12 10.12 0 0 0-2.329.266 1 1 0 0 0 .456 1.947Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default EyeOff;

EyeOff.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
