
import PropTypes from "prop-types";
import React from "react";

const Attachment = ({ color, size, ...props }) => (
    <svg viewBox="0 0 16 16" width={size} height={size} {...props}>
        
	<path d="M11.247 3.031a1.813 1.813 0 0 0-2.565 0L3.37 8.343a2.97 2.97 0 0 0 4.2 4.2l5.313-5.311a.5.5 0 1 1 .707.707L8.277 13.25a3.97 3.97 0 0 1-5.614-5.615l5.312-5.312a2.813 2.813 0 1 1 3.979 3.979l-5.318 5.312a1.657 1.657 0 1 1-2.343-2.343L9.2 4.37a.5.5 0 0 1 .706.708L5 9.979a.657.657 0 0 0 .929.929l5.318-5.312a1.813 1.813 0 0 0 0-2.565Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default Attachment;

Attachment.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
