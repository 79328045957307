import React, { useState } from "react";
import classes from "./PriceTable.module.css";
import Switch from "Components/Switch";
import SketchyArrow from "./SketchyArrow";
import clsx from "clsx";
import PricePlanTable from "./PricePlanTable";
import Tabs, { Tab } from "Components/Tabs";
import Icon from "Components/Icon";
import TextButton from "Components/TextButton";
import Collapse from "Components/Collapse";
import ComparativeTable from "./ComparativeTable";
import useProjectSelected from "Hooks/useProjectSelected";
import CardEnterprise from "./CardEnterprise";
import { useTranslation } from "react-i18next";
import usePriceTableConfig from "./usePriceTableConfig";

export const PLAN_NAMES = {
    BUSINESS: 'business',
    PERSONAL: 'personal',
}

export default function PriceTable() {
    const [isYearly, setIsYearly] = useState(true)
    const [tab, setTab] = useState(PLAN_NAMES.BUSINESS)
    const [showComparative, setShowComparative] = useState(false)
    const { project } = useProjectSelected();
    const { plans, table } = usePriceTableConfig(tab);

    const { t } = useTranslation("pricing");

    return (
        <>
            <section className={classes.section}>
                <div className="text-center">
                    <h1 className={classes.title}>
                        {t("title")}
                    </h1>
                    <p className={classes.description}>
                        {t("description")}
                    </p>
                </div>

                <div className="d-flex justify-content-center py-3">
                    <Tabs value={tab} onChange={setTab}>
                        <Tab className={classes.planTab} value={PLAN_NAMES.BUSINESS}>
                            {t("plans.business")}
                        </Tab>
                        <Tab className={classes.planTab} value={PLAN_NAMES.PERSONAL}>
                            {t("plans.personal")}
                        </Tab>
                    </Tabs>
                </div>

                <div className="d-flex flex-column align-items-center py-4">
                    <div className={clsx(classes.offerInfo, { [classes.textPrimary]: isYearly })}>
                        {t("2-months-free")}
                        <SketchyArrow className={classes.switchArrow} />
                    </div>
                    <div className="d-flex align-items-center gap-2">
                        <div className={clsx(classes.label, { [classes.textPrimary]: !isYearly })}>
                            {t("monthly-payment")}
                        </div>
                        <Switch
                            checked={isYearly}
                            onChange={() => setIsYearly(!isYearly)}
                        />

                        <div className={clsx(classes.label, { [classes.textPrimary]: isYearly })}>
                            {t("annual-payment")}
                        </div>
                    </div>
                </div>

                <div className={clsx(classes.grid, tab === PLAN_NAMES.PERSONAL && classes.columns2)}>
                    {plans.map(item => (
                        <PricePlanTable
                            key={item.title}
                            {...item}
                            currentPlan={project?.subscription?.plan === item.id}
                            periodicity={isYearly ? "yearly" : "monthly"}
                        />
                    ))}

                    {tab === PLAN_NAMES.BUSINESS && <CardEnterprise />}
                </div>
            </section>

            {/* <section className={classes.section}>
                <BannerEnerprise />
            </section> */}

            <section className={classes.section}>
                <div className="text-center py-3">
                    <TextButton className="d-inline-flex flex-column" onClick={() => setShowComparative(!showComparative)}>
                        <div>
                            {showComparative ? t("hide") : t("compare-plans")}
                        </div>
                        <div>
                            <Icon name="chevron-down" color="currentColor" size={28} className={clsx({ [classes.rotate]: showComparative })} />
                        </div>
                    </TextButton>
                </div>
                <Collapse in={showComparative} timeout="auto">
                    <ComparativeTable
                        title={t("comparative-table.title")}
                        description={t("comparative-table.description")}
                        columns={table.columns}
                        rows={table.rows}
                        periodicity={isYearly ? "yearly" : "monthly"}
                    />
                </Collapse>
            </section>

        </>
    )
}
