import { rest } from 'msw'
import { API_HOST } from 'config'
import reports from "./fixtures/reports.json"

const reportHandlers = [
    rest.get(`${API_HOST}/reports`, (req, res, ctx) => {
        return res(
            ctx.json(reports),
        )
    })
]

export default reportHandlers