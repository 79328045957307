import { rest } from 'msw'
import { API_HOST } from 'config'
import automations from "./fixtures/automations.json"

const automationsHandlers = [
    rest.get(`${API_HOST}/automations`, (req, res, ctx) => {
        return res(
            ctx.json(automations),
        )
    }),
    rest.post(`${API_HOST}/automations/:id/retrospect`, async (req, res, ctx) => {
        const { mode } = await req.json()
        if (mode === 'preview') {
            return res(
                ctx.delay(2000),
                ctx.json({
                    transactions_total: 100,
                    transactions_matched: 50
                })
            )
        }

        return res(
            ctx.delay(4000),
            ctx.status(200),
        )
    })
]

export default automationsHandlers