import PropTypes from 'prop-types';
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import InputSelectSimple from 'Components/InputSelectSimple';

export default function RHFSelect({ name, helperText, options, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <InputSelectSimple
                    {...field}
                    error={!!error}
                    helperText={error ? error?.message : helperText}
                    options={options}
                    {...other}
                />
            )}
        />
    );
}

RHFSelect.propTypes = {
    name: PropTypes.string,
    helperText: PropTypes.node,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
};
