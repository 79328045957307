
import PropTypes from "prop-types";
import React from "react";

const NavChannels = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M23.385 3c-2.55 0-4.616 2.108-4.616 4.71 0 2.6 2.067 4.709 4.616 4.709 2.549 0 4.615-2.109 4.615-4.71C28 5.11 25.934 3 23.385 3Zm0 1.884c1.529 0 2.769 1.265 2.769 2.825 0 1.56-1.24 2.826-2.77 2.826-1.529 0-2.769-1.265-2.769-2.826 0-1.56 1.24-2.825 2.77-2.825ZM8.615 11.79C6.066 11.79 4 13.9 4 16.5s2.066 4.71 4.615 4.71c2.55 0 4.616-2.11 4.616-4.71s-2.067-4.71-4.616-4.71Zm0 1.884c1.53 0 2.77 1.265 2.77 2.826 0 1.56-1.24 2.826-2.77 2.826-1.529 0-2.769-1.265-2.769-2.826 0-1.56 1.24-2.826 2.77-2.826ZM23.385 20.581c-2.55 0-4.616 2.109-4.616 4.71 0 2.6 2.067 4.709 4.616 4.709C25.934 30 28 27.892 28 25.29c0-2.6-2.066-4.709-4.615-4.709Zm0 1.884c1.529 0 2.769 1.265 2.769 2.826 0 1.56-1.24 2.825-2.77 2.825-1.529 0-2.769-1.265-2.769-2.825 0-1.56 1.24-2.826 2.77-2.826Z"/>
		<path d="M11.006 17.922a.913.913 0 0 1 1.262-.34l8.406 4.999c.44.262.59.838.333 1.288a.913.913 0 0 1-1.263.34l-8.406-4.999a.952.952 0 0 1-.332-1.288ZM19.732 8.792a.913.913 0 0 1 1.262.339.952.952 0 0 1-.332 1.288l-8.394 4.998a.913.913 0 0 1-1.262-.339.952.952 0 0 1 .332-1.288l8.394-4.998Z"/>
	</g>

    </svg>
);

export default NavChannels;

NavChannels.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
