import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import Button from "Components/Button";
import { mapDtoToProduct } from "Utils/mappers";
import classes from "./ProviderConnectionsErrorManager.module.css";
import clsx from "clsx";
import Badge from "Components/Badge";
import { confirmProviderConnection } from "Services/providersConnections";
import { useQueryClient } from "@tanstack/react-query";
import { useErrors } from "Components/ErrorsProvider";
import { useTranslation } from "react-i18next";

export default function CreatingPartialMatch(props) {
    const { providerConnection, description, onClose, onClearConnection } = props;
    const [loading, setLoading] = useState(false);
    const [processing, setProcessing] = useState(false);
    const queryClient = useQueryClient();
    const { showUnknownError } = useErrors();

    const handleReject = async () => {
        setProcessing(true);
        await onClearConnection();
        onClose();
    }

    const { t } = useTranslation("providers");

    const productsChanged = useMemo(() => {
        if (description === "reconnecting_partial_match") {
            const { old_products, new_products } = providerConnection.status_message.info;
            return [
                ...new_products.map(p => ({
                    ...mapDtoToProduct(p),
                    isNewProduct: true,
                })),
                ...old_products.map(p => ({
                    ...mapDtoToProduct(p),
                    isOldProduct: true,
                }))
            ]
        }

        if (description === "creating_partial_match") {
            const { common_products } = providerConnection.status_message.info;
            return common_products.map(mapDtoToProduct)
        }

        return [];
    }, [providerConnection, description]);

    const confirmCreationPartialMatch = async () => {
        if (processing) return;

        setLoading(true);

        try {
            await confirmProviderConnection(providerConnection.id);
            await queryClient.invalidateQueries(['providers-connections']);
            onClose()
        } catch (error) {
            showUnknownError(error);
            onClose();
        } finally {
            setLoading(false);
        }
    };

    const getProductDescription = (product) => {
        switch (product.type) {
            case "checking":
                return t("product-checking");
            case "investment":
            case "pensionplan":
            case "stocks":
            case "securities":
            case "saving":
                return t("product-investment");
            case "card":
            case "debit":
                return t("product-card");
            case "loan":
            case "lineofcredit":
                return t("product-loan");
            default:
                return t("product-others");
        }
    }

    return (
        <div className="text-center">
            <div>
                <img src={providerConnection.logo_url} alt={"Logo de " + providerConnection.name} className="ms-3 shadowed-icon" style={{ height: "4rem" }} />
                <img alt="" src="/img/arrows-connecting.svg" className={"ms-4"} style={{ height: "2rem" }} />
                <img alt="" src="/img/bt-icon.png" className={"ms-4 shadowed-icon"} style={{ height: "4rem" }} />
            </div>

            <h3 className="fw-500 mt-5">
                {t("reconnecting-partial-match-title")}
            </h3>
            <div className="mt-2 text-muted">
                {t("reconnecting-partial-match-description")}
            </div>

            <div className="my-4">
                {productsChanged.map((product, index) => (
                    <div className={classes.productRow} key={`product-${index}`}>
                        <div className='d-flex gap-3 align-items-center justify-content-between text-start'>
                            <div className={clsx({ [classes.isDeleted]: product.isOldProduct }, 'd-flex', 'gap-2', 'align-items-center', 'overflow-hidden')}>
                                <div className="flex-shrink-1">
                                    <img src={product.icon} alt={product.type} width={32} />
                                </div>
                                <div style={{ minWidth: 0 }}>
                                    <div className='fw-bold text-truncate'>
                                        {product.name}
                                    </div>

                                    <div className='text-small text-muted'>
                                        {getProductDescription(product)}
                                    </div>
                                </div>
                            </div>

                            <div className='fw-bold'>
                                {product.isNewProduct ? <Badge color="success">{t("new")}</Badge> : <Badge color="black">{t("deleted")}</Badge>}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="mt-4 text-muted">¿
                {t("reconnecting-partial-match-confirm")}
            </div>
            <div className="mt-4 d-flex gap-3 justify-content-center">
                <Button primary isBusy={loading} size="large" onClick={confirmCreationPartialMatch}>
                    {t("yes-continue")}
                </Button>
                <Button variant="outlined" size="large" isBusy={processing} onClick={handleReject}>
                    {t("no")}
                </Button>
            </div>
        </div>
    )
}

CreatingPartialMatch.propTypes = {
    providerConnection: PropTypes.object.isRequired,
    description: PropTypes.string.isRequired,
    info: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onClearConnection: PropTypes.func.isRequired,
};
