import React, { useEffect, useRef, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import useProvidersConnections from "Hooks/useProvidersConnections";
import { useSnackbar } from "notistack";
import SyncContext from "./SyncContext";
import { useEventDispatcher } from "Components/EventDispatcherContext";
import { UPDATE_PROVIDER_CONNECTION } from "Utils/eventTypes";
import { getProviderConnection, synchronizeProviderConnection } from "Services/providersConnections";
import { PROVIDER_CONNECTION_STATUSES } from "Utils/constants";

const DELAY_BETWEEN_REQUESTS = 7000;

const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export default function SyncProvider({ children }) {
    const { validProvidersConnections: providersConnections } = useProvidersConnections();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const hasBeenChecked = useRef(false);
    const intervalRef = useRef({});
    const eventDispatcher = useEventDispatcher();

    const startSynchronization = useCallback(({ providerConnection, isCompleteSync = false, otp = null, session_id = null, counter_id = null } = {}) => {
        enqueueSnackbar(providerConnection.name, { variant: "providerConnectionSynchronize", key: `${providerConnection.id}-synchronizing`, persist: true, icon: providerConnection.logo_url })

        synchronizeProviderConnection(providerConnection.id, isCompleteSync, otp, session_id, counter_id)
            .then(() => {
                eventDispatcher.dispatch(UPDATE_PROVIDER_CONNECTION, { ...providerConnection, status: PROVIDER_CONNECTION_STATUSES.SYNCHRONIZING });
                intervalRef.current[providerConnection.id] = setInterval(() => checkSynchronization({ providerConnection, isCompleteSync }), DELAY_BETWEEN_REQUESTS);
            })
            .catch(errorResponse => {
                if (errorResponse.response?.status === 400 && errorResponse.response?.data?.description === "invalid_synchronization_status") {
                    enqueueSnackbar(providerConnection.name, { variant: "providerConnectionError", key: `${providerConnection.id}-error` })
                }
            })
    }, [enqueueSnackbar]);

    const checkSynchronization = useCallback((params) => {
        const { providerConnection } = params;

        getProviderConnection(providerConnection.id).then(async providerConnectionUpdated => {
            if (providerConnectionUpdated?.status !== PROVIDER_CONNECTION_STATUSES.SYNCHRONIZING) {
                closeSnackbar(`${providerConnection.id}-synchronizing`)
                clearInterval(intervalRef.current[providerConnection.id] ?? null);
                await wait(500);
            }

            if (providerConnectionUpdated?.status === PROVIDER_CONNECTION_STATUSES.OK) {
                eventDispatcher.dispatch(UPDATE_PROVIDER_CONNECTION, providerConnectionUpdated);
                enqueueSnackbar(providerConnection.name, { variant: "providerConnectionSuccess", key: `${providerConnection.id}-success` })

            } else if (providerConnectionUpdated?.status === PROVIDER_CONNECTION_STATUSES.SYNCHRONIZING_ERROR_OTP) {
                const actionParams = {
                    ...params,
                    otp: null,
                    session_id: providerConnectionUpdated.status_message?.info?.session_id,
                    counter_id: providerConnectionUpdated.status_message?.info?.counter_id
                }

                if (providerConnectionUpdated.status_message.description === "requires_signature") {
                    enqueueSnackbar(providerConnection.name, {
                        variant: "providerConnectionVerify", action: () => startSynchronization(actionParams), icon: providerConnection.logo_url, persist: true, key: `${providerConnection.id}-verify`
                    })
                } else {
                    const message = providerConnectionUpdated.status_message.info?.service_message || "Introduce el código que te ha enviado tu banco.";
                    const action = (options) => {
                        startSynchronization({
                            ...actionParams,
                            otp: options.otp
                        });
                    }
                    enqueueSnackbar(message, { variant: "providerConnectionRequestOtp", persist: true, action, icon: providerConnection.logo_url, key: `${providerConnection.id}-request-otp` })
                }
            } else if (providerConnectionUpdated?.status !== PROVIDER_CONNECTION_STATUSES.SYNCHRONIZING) {
                enqueueSnackbar(providerConnection.name, { variant: "providerConnectionError" })
            }
        }).catch((errorResponse) => {
            clearInterval(intervalRef.current[providerConnection.id] ?? null);
            if (errorResponse.response?.status === 400 && errorResponse.response?.data?.description === "invalid_synchronization_status") {
                enqueueSnackbar(providerConnection.name, { variant: "providerConnectionError", key: `${providerConnection.id}-error` })
            }
        })
    }, [closeSnackbar, enqueueSnackbar, eventDispatcher, startSynchronization]);

    useEffect(() => {
        if (!providersConnections.length || hasBeenChecked.current) return;

        hasBeenChecked.current = true;
        providersConnections.forEach(providerConnection => {
            if (providerConnection.status === PROVIDER_CONNECTION_STATUSES.SYNCHRONIZING) {
                enqueueSnackbar(providerConnection.name, { variant: "providerConnectionSynchronize", key: `${providerConnection.id}-synchronizing`, persist: true, icon: providerConnection.logo_url })
                intervalRef.current[providerConnection.id] = setInterval(() => checkSynchronization({ providerConnection }), DELAY_BETWEEN_REQUESTS);
                return;
            }

            if (providerConnection.status === PROVIDER_CONNECTION_STATUSES.SYNCHRONIZING_ERROR_OTP) {
                const actionParams = {
                    providerConnection,
                    otp: null,
                    session_id: providerConnection.status_message?.info?.session_id,
                    counter_id: providerConnection.status_message?.info?.counter_id
                }

                if (providerConnection.status_message.description === "requires_signature") {
                    enqueueSnackbar(providerConnection.name, { variant: "providerConnectionVerify", action: () => startSynchronization(actionParams), icon: providerConnection.logo_url, persist: true })
                    return;
                }

                const message = providerConnection.status_message.info?.service_message || "Introduce el código que te ha enviado tu banco.";
                const action = (options) => {
                    startSynchronization({
                        ...actionParams,
                        otp: options.otp,
                    });
                }
                enqueueSnackbar(message, { variant: "providerConnectionRequestOtp", persist: true, action, icon: providerConnection.logo_url })
                return;
            }

            if (providerConnection.status === PROVIDER_CONNECTION_STATUSES.SYNCHRONIZING_ERROR) {
                enqueueSnackbar(providerConnection.name, { variant: "providerConnectionError" })
                return;
            }
        });
    }, [providersConnections, enqueueSnackbar, checkSynchronization, startSynchronization]);

    const contextValue = useMemo(() => ({ synchronize: startSynchronization }), [startSynchronization]);

    return (
        <SyncContext.Provider value={contextValue}>
            {children}
        </SyncContext.Provider>
    )
}


SyncProvider.propTypes = {
    children: PropTypes.node.isRequired,
};