import React from "react";
import PropTypes from "prop-types";

export default function UpIcon({ color = "#FFF" }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="14"
            viewBox="0 0 12 14"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fill={color} fillRule="nonzero" transform="translate(-1266 -34)">
                    <g transform="translate(1253 22)">
                        <g transform="translate(13.91 12.357)">
                            <path d="M9.76 7.386H7.506v4.807c0 .446-.359.807-.802.807H3.857a.805.805 0 01-.802-.807V7.386H.803c-.668 0-1.042-.775-.633-1.303L4.65.31a.8.8 0 011.265 0l4.478 5.773c.41.528.036 1.303-.633 1.303z"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

UpIcon.propTypes = {
    color: PropTypes.string,
};