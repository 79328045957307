import React, { useState } from "react";
import FormProvider, { RHFSelect, RHFTextField } from 'Components/HookForm';
import { useForm } from 'react-hook-form';
import Button from "Components/Button";
import PropTypes from "prop-types";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import classes from "./ProviderConnectionsErrorManager.module.css";
import Radio, { RadioGroup } from "Components/Radio";

const normalizeString = (string) => string.replace(/[^a-z0-9]/gi, '_').toLowerCase();

export default function MulticontractFormWithCredentials(props) {
    const {
        onSubmit, fieldsNames, documentType, fieldsDescriptions,
        fieldsTypes, documentTypes, onCancel, fieldValues,
        disabledFields, canceling, submitting, accounts
    } = props;

    const [account, setAccount] = useState(() => {
        if (Array.isArray(accounts) && accounts.length > 0) {
            return `radio-${accounts[0].account_id}`;
        }
        return null;
    });

    const normalizedFieldsNames = fieldsNames.map((name) => normalizeString(name));
    const processing = canceling || submitting;
    const fields = fieldsNames.map((name, index) => ({
        label: name,
        name: normalizedFieldsNames[index],
        description: fieldsDescriptions[index],
        type: fieldsTypes[index],
    }));

    const defaultValues = {
        ...documentTypes && ({
            document_type: documentType,
        }),
        ...normalizedFieldsNames.reduce((acc, name, index) => {
            const value = Array.isArray(fieldValues) ? fieldValues[index] ?? "" : "";
            acc[name] = value
            return acc;
        }, {}),
    }

    const schema = yup.object().shape({
        ...documentTypes && ({
            document_type: yup.string().required("Este campo es obligatorio"),
        }),
        ...normalizedFieldsNames.reduce((acc, name) => {
            if (disabledFields.includes(name)) return acc;

            acc[name] = yup.string().required("Este campo es obligatorio");
            return acc;
        }, {}),
    });

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues
    });

    const { handleSubmit } = methods;

    const onSubmitForm = (data) => {

        if (processing) return;
        const { document_type, ...credentialsData } = data;

        const credentials = Object.values(credentialsData);
        const originalAccount = accounts.find(a => `radio-${a.account_id}` === account);
        onSubmit({
            documentType: documentTypes ? documentTypes.indexOf(document_type) : null,
            accountId: originalAccount?.account_id ?? null,
            credentials
        });
    }

    const documentTypesOptions = documentTypes?.map(documentType => ({
        id: documentType,
        label: documentType,
    }));

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitForm)}>
            <div className="d-grid gap-4 text-start">
                <RadioGroup name="account" label="Cuenta" value={account} onChange={ev => setAccount(ev.target.value)}>
                    {accounts.map(account => (
                        <Radio id={`radio-${account.account_id}`} key={`radio-${account.account_id}`} value={`radio-${account.account_id}`}>
                            <div className="pt-1">{account.description}</div>
                        </Radio>
                    ))}
                </RadioGroup>

                <div className={classes.divider} />

                {documentTypes && (
                    <RHFSelect disabled={disabledFields.includes("document_type")} name="document_type" label="Tipo de documento" options={documentTypesOptions} />
                )}

                {fields.map((field, index) => (
                    <RHFTextField
                        key={index}
                        name={field.name}
                        label={field.label}
                        type={field.type}
                        disabled={disabledFields.includes(field.label)}
                        helperText={`Introduce tu ${field.label.toLocaleLowerCase()}`}
                        inputProps={{
                            autoComplete: 'new-password',
                        }}
                    />
                ))}
            </div>

            <div className="pt-4 d-flex gap-2 justify-content-center">
                <Button size="large" primary type="submit" isBusy={submitting}>
                    <img alt="" src="/img/icon-padlock.svg" />
                    <span className={"ms-2"}>Conectar</span>
                </Button>
                <Button size="large" variant="outlined" onClick={onCancel} isBusy={canceling}>
                    Cancelar
                </Button>
            </div>
        </FormProvider>
    )
}


MulticontractFormWithCredentials.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    fieldsNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    fieldsDescriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
    fieldsTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    documentTypes: PropTypes.arrayOf(PropTypes.string),
    onCancel: PropTypes.func.isRequired,
    documentType: PropTypes.string,
    fieldValues: PropTypes.arrayOf(PropTypes.string),
    canceling: PropTypes.bool,
    submitting: PropTypes.bool,
    disabledFields: PropTypes.arrayOf(PropTypes.string).isRequired,
    accounts: PropTypes.arrayOf(PropTypes.shape({
        account_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        description: PropTypes.string.isRequired,
    })).isRequired,
}