
import PropTypes from "prop-types";
import React from "react";

const Ngos = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M18.608 3.006c-.931.05-1.82.403-2.52 1l-.089.08.083.075c-1.62-1.548-4.228-1.548-5.848 0a3.923 3.923 0 0 0 0 5.713l4.384 4.189a2 2 0 0 0 2.763 0l4.384-4.189A3.95 3.95 0 0 0 23 7.018a3.95 3.95 0 0 0-1.235-2.857A4.233 4.233 0 0 0 18.842 3l-.234.006ZM18.842 5c.581 0 1.137.22 1.542.607.396.379.616.885.616 1.41a1.95 1.95 0 0 1-.616 1.411L16 12.617l-4.384-4.189a1.923 1.923 0 0 1 0-2.82c.847-.81 2.238-.81 3.085 0l.608.58a1 1 0 0 0 1.382 0l.608-.58c.405-.388.96-.608 1.543-.608Z"/>
		<path d="m1.586 14 3.663.008c.841 0 .841.403 1.145.992H7l3.912.166c.616 0 1.226.14 1.778.404l.234.121 3.095 1.734a.586.586 0 0 0 .188.066l.1.009h3.794a2.341 2.341 0 0 1 2.327 2.61l-.021.137 5.524-1.89c1.074-.384 2.266.033 2.776.971.665 1.227.138 2.611-.98 3.151l-11.073 4.855a7.498 7.498 0 0 1-6.64-.227l-.295-.167-3.064-1.83c-.482-.289-1.997-.5-2.66-.582-.238.612 0 1.132-.746 1.132l-.147.007H1.586a.585.585 0 0 1-.578-.49L1 25.084v-10.5c0-.29.212-.53.49-.575L1.587 14Zm4.409 2.016v7.436c.713.085 2.4.214 3.034.532l.229.125 3.064 1.83a6.319 6.319 0 0 0 5.538.463l11.285-4.941c.591-.236.88-.935.53-1.578-.214-.396-.736-.586-1.229-.465l-.298.098-6.948 2.377c-.28.149-.59.24-.907.266l-.192.008h-5.956a.584.584 0 0 1-.095-1.159l.095-.008h5.956c.646 0 1.172-.526 1.172-1.167 0-.6-.458-1.096-1.044-1.16l-.128-.006h-3.794c-.241 0-.48-.05-.701-.145l-.161-.08-3.096-1.734a2.94 2.94 0 0 0-1.222-.367l-.215-.008-4.917-.317ZM5 15H2v9.528h3V15Z" stroke={color} strokeWidth=".8"/>
	</g>

    </svg>
);

export default Ngos;

Ngos.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
