import { rest } from 'msw'
import { API_HOST } from 'config'
import dashboards from "./fixtures/dashboards.json"
import { v4 as uuid } from 'uuid';

const dashboardsHandlers = [
    rest.get(`${API_HOST}/dashboards`, (req, res, ctx) => {
        return res(
            ctx.json(dashboards),
        )
    }),
    rest.post(`${API_HOST}/dashboards`, (req, res, ctx) => {
        return res(
            ctx.json(dashboards[0]),
        )
    }),
    rest.get(`${API_HOST}/dashboards/:dashboardId`, (req, res, ctx) => {
        const { dashboardId } = req.params
        const dashboard = dashboards.find(dashboard => dashboard.id === dashboardId)
        return res(
            ctx.json(dashboard),
        )
    }),
    rest.put(`${API_HOST}/dashboards/:dashboardId`, async (req, res, ctx) => {
        const partialDashboard = await req.json()
        const { dashboardId } = req.params
        const dashboard = dashboards.find(dashboard => dashboard.id === dashboardId)

        return res(
            ctx.json({
                ...dashboard,
                ...partialDashboard,
            }),
        )
    }),
    rest.delete(`${API_HOST}/dashboards/:dashboardId`, (req, res, ctx) => {
        return res(
            ctx.json(dashboards[0]),
        )
    }),
    rest.post(`${API_HOST}/dashboards/:dashboardId/duplicate`, async (req, res, ctx) => {
        const { dashboardId } = req.params
        const partialDashboard = await req.json()
        const dashboard = dashboards.find(dashboard => dashboard.id === dashboardId)

        return res(
            ctx.json({
                ...dashboard,
                ...partialDashboard,
                id: uuid(),
            }),
        )
    }),
    rest.post(`${API_HOST}/dashboards/sort`, async (req, res, ctx) => {
        const dashboardIds = await req.json()
        const sortedDashboards = dashboardIds.map(dashboardId => dashboards.find(dashboard => dashboard.id === dashboardId))

        return res(
            ctx.json(sortedDashboards),
        )
    }),
]

export default dashboardsHandlers