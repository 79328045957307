
import PropTypes from "prop-types";
import React from "react";

const NavCategories = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M16.521 3H3.943A.943.943 0 0 0 3 3.944v12.58c0 .25.1.49.277.668L14.08 27.986a3.458 3.458 0 0 0 4.894 0l9.017-9.02a3.46 3.46 0 0 0 .002-4.88L17.188 3.275A.943.943 0 0 0 16.521 3Zm-.391 1.887 10.528 10.53a1.572 1.572 0 0 1-.002 2.216L17.64 26.65a1.572 1.572 0 0 1-1.113.462l-.177-.01a1.572 1.572 0 0 1-.935-.451L4.887 16.133V4.887H16.13Z"/>
		<path d="M10.245 8.347a1.887 1.887 0 0 1 0 3.774 1.887 1.887 0 0 1-.194-3.766l.194-.008Z"/>
	</g>

    </svg>
);

export default NavCategories;

NavCategories.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
