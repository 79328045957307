
import PropTypes from "prop-types";
import React from "react";

const BulletPoint = ({ color, size, ...props }) => (
    <svg viewBox="0 0 12 12" width={size} height={size} {...props}>
        
	<path d="M6 8.727a2.727 2.727 0 1 0 0-5.454 2.727 2.727 0 0 0 0 5.454Z" fill={color}/>

    </svg>
);

export default BulletPoint;

BulletPoint.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
