
import PropTypes from "prop-types";
import React from "react";

const Download = ({ color, size, ...props }) => (
    <svg viewBox="0 0 16 16" width={size} height={size} {...props}>
        
	<g clipPath="url(#a)" fill={color} fillRule="evenodd">
		<path d="M1 9.633a.7.7 0 0 1 .7.7v3.111c0 .473.383.856.855.856h10.89a.856.856 0 0 0 .855-.856v-3.11a.7.7 0 1 1 1.4 0v3.11a2.256 2.256 0 0 1-2.256 2.256H2.555A2.256 2.256 0 0 1 .3 13.444v-3.11a.7.7 0 0 1 .7-.7Z"/>
		<path d="M3.616 5.95a.7.7 0 0 1 .99 0L8 9.342l3.394-3.394a.7.7 0 0 1 .99.99l-3.889 3.889a.7.7 0 0 1-.99 0L3.616 6.939a.7.7 0 0 1 0-.99Z"/>
		<path d="M8 .3a.7.7 0 0 1 .7.7v9.333a.7.7 0 1 1-1.4 0V1A.7.7 0 0 1 8 .3Z"/>
	</g>
	<defs>
		<clipPath id="a">
			<path d="M0 0h16v16H0z" fill={color}/>
		</clipPath>
	</defs>

    </svg>
);

export default Download;

Download.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
