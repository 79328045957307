import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTellerConnect } from "teller-connect-react";
import { createProviderConnectionWithSelector } from "Services/providersConnections";
import Loading from "Components/Loading";
import { useErrors } from "Components/ErrorsProvider";

export default function ProviderConnectionConnectWithSelector(props) {
    const { onConnect, providerConnectionId, onCancel, providerName, connectorId, serviceProviderId } = props;

    const { showUnknownError } = useErrors();
    const { open, ready } = useTellerConnect({
        applicationId: "app_p0ap3m9ubkij41ud02000",
        institution: serviceProviderId,
        onExit: onCancel,
        selectAccount: "disabled",
        // environment: "sandbox",
        onSuccess: async (authorization) => {
            try {
                const conexionId = await createProviderConnectionWithSelector({
                    connectorId,
                    providerConnectionId,
                    name: providerName,
                    token: authorization.accessToken,
                });
                const credentials = {}
                onConnect(conexionId, credentials);
            } catch (error) {
                showUnknownError(error);
                onCancel();
            }
        },
    });
    useEffect(() => {
        if (ready) {
            open();
        }
    }, [ready, open]);


    if (ready) {
        return (
            <div style={{ position: "fixed", inset: 0, backgroundColor: "rgb(209 213 219)", zIndex: 99999 }} />
        )
    }

    return (
        <div className="text-center p-4">
            <Loading />
        </div>
    )
}

ProviderConnectionConnectWithSelector.propTypes = {
    providerConnectionId: PropTypes.string,
    providerName: PropTypes.string.isRequired,
    connectorId: PropTypes.string.isRequired,
    onConnect: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    serviceProviderId: PropTypes.string.isRequired,
};
