export function isLocalStorageAvailable() {
    try {
        // Incognito mode might reject access to the localStorage for security reasons.
        // https://stackoverflow.com/questions/16427636/check-if-localstorage-is-available
        const key = '__test-local-storage__';
        window.localStorage.setItem(key, key);
        window.localStorage.removeItem(key);

        return true;
    } catch (err) {
        return false;
    }
}

class Storage {
    constructor({ sessionStorage = false } = {}) {
        this.isLocalStorageAvailable = isLocalStorageAvailable();
        this.windowStorage = sessionStorage ? window.sessionStorage : window.localStorage;
        this.memoryStorage = new Map();
    }

    setItem(key, value) {
        if (this.isLocalStorageAvailable) {
            this.windowStorage.setItem(key, value);
        } else {
            this.memoryStorage.set(key, value);
        }
    }

    getItem(key) {
        if (this.isLocalStorageAvailable) {
            return this.windowStorage.getItem(key);
        } else {
            return this.memoryStorage.get(key);
        }
    }

    removeItem(key) {
        if (this.isLocalStorageAvailable) {
            this.windowStorage.removeItem(key);
        } else {
            this.memoryStorage.delete(key);
        }
    }

    clear() {
        if (this.isLocalStorageAvailable) {
            this.windowStorage.clear();
        } else {
            this.memoryStorage.clear();
        }
    }
}

export const sessionStorage = new Storage({ sessionStorage: true });

const storage = new Storage();
export default storage;

