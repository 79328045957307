
import PropTypes from "prop-types";
import React from "react";

const Pets = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M16.586 12.957c-1.823 0-3.292 1.116-5.245 3.675-.332.434-.75.837-1.375 1.343l-.341.271-.957.747-.227.185c-1.206 1-1.815 1.858-1.815 3.008 0 3.29 3.266 5.035 5.905 3.438l.157-.1c1.153-.765 2.626-1.294 3.898-1.294.24 0 .51.04.81.118.43.113.895.296 1.438.559l.398.199.885.469c3.29 1.736 6.43.223 6.43-3.389 0-1.088-.604-1.918-1.78-2.893l-.447-.358-.805-.62c-.822-.634-1.319-1.08-1.677-1.537l-.094-.125c-1.904-2.632-3.294-3.696-5.158-3.696Zm0 2c1.05 0 1.974.707 3.538 2.868.465.643 1.026 1.175 1.917 1.877l1.193.923.257.208c.752.623 1.056 1.041 1.056 1.353l-.004.178c-.082 1.8-1.425 2.446-3.308 1.535l-1.219-.638c-.78-.396-1.448-.674-2.114-.848a5.198 5.198 0 0 0-1.316-.183c-1.706 0-3.547.661-5.003 1.627-1.351.896-2.957.07-2.957-1.67 0-.36.264-.756.931-1.334l.292-.242 1.11-.87c.885-.696 1.474-1.244 1.971-1.895 1.607-2.105 2.639-2.89 3.656-2.89ZM12.878 6C10.96 6 9.464 7.716 9.464 9.77c0 2.054 1.496 3.77 3.414 3.77 1.919 0 3.415-1.716 3.415-3.77 0-2.054-1.496-3.77-3.415-3.77Zm0 2c.749 0 1.415.764 1.415 1.77s-.666 1.77-1.415 1.77c-.748 0-1.414-.764-1.414-1.77S12.13 8 12.878 8Z"/>
		<path d="M20.122 6c-1.919 0-3.415 1.716-3.415 3.77 0 2.054 1.496 3.77 3.415 3.77 1.918 0 3.414-1.716 3.414-3.77 0-2.054-1.496-3.77-3.414-3.77Zm0 2c.748 0 1.414.764 1.414 1.77s-.666 1.77-1.414 1.77c-.749 0-1.415-.764-1.415-1.77S19.373 8 20.122 8ZM27.118 10.997c-1.788-.675-3.802.395-4.548 2.307-.75 1.92.025 4.058 1.823 4.736 1.789.675 3.802-.395 4.549-2.307.725-1.857.023-3.92-1.653-4.666l-.17-.07Zm-.706 1.871c.692.261 1.032 1.2.666 2.138-.368.944-1.278 1.428-1.979 1.163-.691-.261-1.032-1.2-.666-2.138.348-.892 1.179-1.372 1.86-1.2l.12.037ZM5.882 10.997c1.788-.675 3.802.395 4.548 2.307.75 1.92-.025 4.058-1.823 4.736-1.789.675-3.802-.395-4.549-2.307-.725-1.857-.023-3.92 1.653-4.666l.17-.07Zm.706 1.871c-.692.261-1.032 1.2-.666 2.138.368.944 1.278 1.428 1.979 1.163.691-.261 1.032-1.2.666-2.138-.348-.892-1.179-1.372-1.86-1.2l-.12.037Z"/>
	</g>

    </svg>
);

export default Pets;

Pets.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
