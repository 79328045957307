import React, { useState } from "react";
import { createProviderConnectionWithCredentials } from "Services/providersConnections";
import PropTypes from "prop-types";
import ProviderConnectionConnectWithCredentialsLogin from "./ProviderConnectionConnectWithCredentialsLogin";
import { useErrors } from "Components/ErrorsProvider";

export default function ProviderConnectionConnectWithCredentials(props) {
    const { providerLogoUrl, providerName, providerConnectionId, connectorId, connectorMeta, onConnect, onCancel, serviceId } = props;
    const [processing, setProcessing] = useState(false);
    const { showUnknownError } = useErrors();

    const handleSubmit = async (data) => {
        setProcessing(true);
        const { documentType, credentials } = data;
        try {
            const conexionId = await createProviderConnectionWithCredentials({
                connectorId,
                name: providerName,
                providerConnectionId,
                credentials,
                documentType
            });

            onConnect(conexionId, credentials);
        } catch (error) {
            showUnknownError(error);
            onCancel();
        } finally {
            setProcessing(false);
        }
    }

    return (
        <div className="text-center p-3 mb-3">
            <div className="d-flex align-items-center justify-content-center mb-5">
                <img src={providerLogoUrl} alt={providerName} style={{ height: "4rem" }} />
                <h4 className="fw-500 m-0 ms-3 text-start" style={{ width: "min-content" }}>{providerName}</h4>
            </div>

            <ProviderConnectionConnectWithCredentialsLogin
                fieldsNames={connectorMeta.fields_names}
                fieldsDescriptions={connectorMeta.fields_descriptions}
                fieldsTypes={connectorMeta.fields_types}
                documentTypes={connectorMeta.document_types}
                processing={processing}
                serviceId={serviceId}
                onSubmit={handleSubmit}
            />
        </div>
    )

}

ProviderConnectionConnectWithCredentials.propTypes = {
    connectorId: PropTypes.string.isRequired,
    providerName: PropTypes.string.isRequired,
    providerLogoUrl: PropTypes.string.isRequired,
    connectorMeta: PropTypes.object.isRequired,
    onConnect: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    providerConnectionId: PropTypes.string,
    serviceId: PropTypes.string,
}
