
import PropTypes from "prop-types";
import React from "react";

const Calendar = ({ color, size, ...props }) => (
    <svg viewBox="0 0 20 20" width={size} height={size} {...props}>
        
	<path d="M7 0a1 1 0 0 1 1 1v1h4V1a1 1 0 1 1 2 0v1h1a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h1V1a1 1 0 0 1 1-1Zm5 4v1a1 1 0 1 0 2 0V4h1a1 1 0 0 1 1 1v2H4V5a1 1 0 0 1 1-1h1v1a1 1 0 0 0 2 0V4h4Zm4 5v6a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9h12Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default Calendar;

Calendar.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
