import React from "react";

export const Check = () => (
  <svg
    stroke="currentcolor"
    width="12"
    height="12"
    fill="none"
    viewBox="0 0 12 12"
  >
    <path
      strokeLinecap="round"
      strokeWidth="2"
      d="M2 6l2.894 2.894a.15.15 0 00.212 0L11 3"
    ></path>
  </svg>
);

export const Indeterminate = () => (
  <svg
    strokeLinejoin="round"
    stroke="currentcolor"
    strokeWidth="4"
    viewBox="0 0 24 24"
  >
    <path d="M21 12L3 12" />
  </svg>
);

