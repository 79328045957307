import React, { useLayoutEffect, useRef } from "react";
import classes from "./Drawer.module.css";
import PropTypes from "prop-types";
import clsx from "clsx";

export function DrawerFooter(props) {
    const { children, className } = props;

    const ref = useRef(null);

    useLayoutEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {

            window.requestAnimationFrame(() => {
                if (!Array.isArray(entries) || entries.length === 0) {
                    return;
                }

                if (!ref.current) return;

                const { height } = ref.current.getBoundingClientRect();
                document.documentElement.style.setProperty(
                    "--drawer-footer-height",
                    `${height}px`
                );
            });
        });

        resizeObserver.observe(ref.current);

        return () => {
            resizeObserver.disconnect();
            document.documentElement.style.removeProperty("--drawer-footer-height");
        };
    }, []);

    return (
        <div ref={ref} className={clsx(classes.footer, className)}>
            {children}
        </div>
    );
}

DrawerFooter.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

