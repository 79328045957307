import React, { useEffect, useState } from "react";
import Button from "Components/Button";
import classes from "./AppLayout.module.css";
import useProvidersConnections from "Hooks/useProvidersConnections";
import { PROVIDER_CONNECTION_STATUSES } from "Utils/constants";
import useProviderConnector from "Hooks/useProviderConnector";
import { trackError } from "Utils/errorMonitoring";
import ProviderConnectionConnectDialog from "Sections/ProviderConnectionConnectDialog";
import { useQueryClient } from "@tanstack/react-query";
import { Trans, useTranslation } from "react-i18next";

const MODAL_TYPES = {
    SUCCESS: "success",
    CONNECT: "connect",
};

const getExpiredProvider = (providersConnections) => {
    const expiredProviders = providersConnections
        .filter(item => [PROVIDER_CONNECTION_STATUSES.EXPIRED].includes(item.status))
        .filter(item => item.products.some(product => !product.is_hidden && !product.is_deleted))
        .filter(item => !item.is_premium_blocked);

    if (expiredProviders.length === 0) {
        return null;
    }

    return expiredProviders[0];
}

export default function AppLayoutProvidersCTA() {
    const [showModal, setShowModal] = useState(null);
    const { validProvidersConnections: providersConnections } = useProvidersConnections();
    const expiredProvider = getExpiredProvider(providersConnections);
    const { connector, isLoading } = useProviderConnector(expiredProvider?.provider_connector_id)

    const { t } = useTranslation("common");

    const queryClient = useQueryClient();

    const closeDialog = () => setShowModal(null);

    useEffect(() => {
        if (!isLoading && !connector && expiredProvider?.provider_connector_id) {
            trackError(`connector not found: ${expiredProvider?.provider_connector_id}`);
        }
    }, [isLoading, connector, expiredProvider?.provider_connector_id]);

    const handleConnect = () => {
        setShowModal(null)
        queryClient.invalidateQueries(["providers-connections"]);
    }

    if (!expiredProvider || !connector) {
        return null;
    }

    return (
        <>
            <ProviderConnectionConnectDialog
                open={showModal === MODAL_TYPES.CONNECT}
                onClose={closeDialog}
                onConnect={handleConnect}
                providerConnection={expiredProvider}
            />

            <div className={classes.cardCTA}>
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-center py-3 px-4">
                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-1">
                            <img alt="" src={expiredProvider.logo_url} height="50" />
                        </div>
                        <div className="ms-4">
                            <span className="opacity-50">
                                {t("provider-connections.connection-error")}
                            </span>
                            <h5 className="m-0 opacity-75">
                                <Trans t={t} i18nKey="provider-connections.connection-error-description">
                                    {{ provider: expiredProvider.name }}
                                </Trans>
                            </h5>
                        </div>
                    </div>
                    <Button primary size="large" className={"mt-4 mt-md-0"} onClick={() => setShowModal(MODAL_TYPES.CONNECT)}>
                        {t("provider-connections.fix-connection")}
                    </Button>
                </div>
            </div>
        </>
    );
}
