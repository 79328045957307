import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { SnackbarContent, useSnackbar } from "notistack";
import classes from "./Snackbar.module.css";
import Button from "Components/Button";
import { useTranslation } from "react-i18next";

const SnackbarProviderConnectionVerify = forwardRef(function SnackbarProviderConnectionVerify(props, ref) {
    const { icon, message, action, id } = props;
    const { closeSnackbar } = useSnackbar();

    const { t } = useTranslation("providers");

    const handleAction = () => {
        closeSnackbar(id);
        action?.();
    }

    return (
        <SnackbarContent ref={ref} className={classes.root}>
            <div className={classes.card}>
                <div className={classes.layout}>
                    <div className={classes.icon}>
                        <img src={icon} alt="" />
                    </div>
                    <div className={classes.content}>
                        <div className={classes.actionText}>
                            {t("approval-required")}
                        </div>
                        <div className={classes.title}>{message}</div>
                    </div>
                    <div className={classes.action}>
                        <Button size="small" primary onClick={handleAction}>
                            {t("approve")}
                        </Button>
                    </div>
                </div>
            </div>
        </SnackbarContent>
    );
});

export default SnackbarProviderConnectionVerify;

SnackbarProviderConnectionVerify.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    icon: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    action: PropTypes.func,
};