import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { ErrorsContext } from "./ErrorsContext";
import ModalPaidSubscriptionRequired from 'Components/ModalPaidSubscriptionRequired';
import imageWrongPass from 'assets/icon-wrong-pass.svg';
import * as errorMonitoring from 'Utils/errorMonitoring';
import PlansAndPricingProvider from 'Components/PlansAndPricingProvider';
import Dialog from "Components/Dialog";
import { Trans, useTranslation } from "react-i18next";
import Button from "Components/Button";

export default function ErrorsProvider({ children }) {
    const [showUnknownError, setShowUnknownError] = useState(false);
    const [showPaidSubscriptionRequired, setShowPaidSubscriptionRequired] = useState(false);
    const [showPlansAndPricing, setShowPlansAndPricing] = useState(false);

    const { t } = useTranslation("common");

    const showUnknownErrorHandler = useCallback((error, extraData = {}) => {
        const hasAxiosRequiresPaidError = error?.response?.status === 400 && error?.response?.data?.description === "requires_paid_subscription";

        if (hasAxiosRequiresPaidError) {
            setShowPaidSubscriptionRequired(true)
            return;
        }

        if (error) {
            errorMonitoring.trackError(error, extraData);
        }

        setShowUnknownError(true)
    }, []);

    const contextValue = useMemo(() => ({
        showUnknownError: showUnknownErrorHandler,
    }), [showUnknownErrorHandler]);

    return (
        <ErrorsContext.Provider value={contextValue}>
            <PlansAndPricingProvider open={showPlansAndPricing} setOpen={setShowPlansAndPricing}>
                <ModalPaidSubscriptionRequired
                    open={showPaidSubscriptionRequired}
                    onClose={() => setShowPaidSubscriptionRequired(false)}
                    showPlansAndPricing={() => setShowPlansAndPricing(true)}
                />

                <Dialog
                    open={showUnknownError}
                    onClose={() => setShowUnknownError(false)}
                    size="sm"
                >
                    <div className="text-center">
                        <img src={imageWrongPass} alt="" />
                        <h3 className="fw-500 mt-4">
                            {t("unknown-error")}
                        </h3>
                        <div className="mt-2 mb-5 text-muted">
                            <Trans t={t} i18nKey="unknown-error-description" />
                        </div>
                        <Button size="large" primary onClick={() => setShowUnknownError(false)}>
                            {t("close")}
                        </Button>
                    </div>
                </Dialog>

                {children}
            </PlansAndPricingProvider>
        </ErrorsContext.Provider>
    )
}

ErrorsProvider.propTypes = {
    children: PropTypes.node.isRequired,
};