
import PropTypes from "prop-types";
import React from "react";

const Cash = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M28 7H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2ZM4 9h24v14H4V9Z"/>
		<path d="M5 13v6a1 1 0 0 0 .963 1l.15.005c.444.033.824.406.88.876L7 21a1 1 0 0 0 1 1h16a1 1 0 0 0 1-.963l.005-.15c.033-.444.406-.824.876-.88L26 20a1 1 0 0 0 1-1v-6a1 1 0 0 0-.963-1l-.15-.005c-.444-.033-.824-.406-.88-.876L25 11a1 1 0 0 0-1-1H8a1 1 0 0 0-1 .963l-.005.15c-.033.444-.406.824-.876.88L6 12a1 1 0 0 0-1 1Zm2 .83.153-.06a3.008 3.008 0 0 0 1.658-1.72l.017-.05H23.17l.06.153a3.008 3.008 0 0 0 1.72 1.658l.05.018v4.342l-.153.059a3.008 3.008 0 0 0-1.658 1.72l-.018.05H8.828l-.058-.153a3.008 3.008 0 0 0-1.72-1.658L7 18.172V13.83Z"/>
		<path d="M16 13a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0 2a1 1 0 1 1 0 2 1 1 0 0 1 0-2Z"/>
	</g>

    </svg>
);

export default Cash;

Cash.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
