import { useEffect } from "react";
import storage from 'Utils/storage';
import { useTranslation } from "react-i18next";
import { getCookie } from "Utils/cookies";
import { COOKIES } from "Utils/constants";

const availableLanguages = ["ES", "EN"];
const defaultLanguage = "EN";

function isValidLanguage(language) {
    return availableLanguages.includes(language);
}

function getBrowserLanguage() {
    const browserLocale = navigator.language.split('-')[0];
    return browserLocale.toUpperCase();
}

function getLanguage() {
    const appLocale = storage.getItem('app-locale');

    if (isValidLanguage(appLocale)) {
        return appLocale;
    }

    const landingsLocale = getCookie(COOKIES.LOCALE);

    const landingsLocaleFormatted = landingsLocale?.toUpperCase();

    if (isValidLanguage(landingsLocaleFormatted)) {
        return landingsLocaleFormatted;
    }

    const browserLocale = getBrowserLanguage();

    if (isValidLanguage(browserLocale)) {
        return browserLocale;
    }

    return defaultLanguage;
}

export default function useRegisterUserLocale(user) {
    const { i18n } = useTranslation();

    useEffect(() => {
        if (user) {
            if (isValidLanguage(user.language)) {
                i18n.changeLanguage(user.language);
                storage.setItem('app-locale', user.language);
            } else {
                i18n.changeLanguage(defaultLanguage);
            }
        } else {
            const language = getLanguage();
            i18n.changeLanguage(language);
        }
    }, [user?.language]);

    return null
}
