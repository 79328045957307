
import PropTypes from "prop-types";
import React from "react";

const Supplies = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="m23.28 16.306-2.827 2.93c-1.419 1.467-1.842 3.666-1.077 5.578C20.146 26.738 21.969 28 24 28c2.031 0 3.855-1.262 4.624-3.186.765-1.912.342-4.11-1.078-5.58l-2.826-2.928a1 1 0 0 0-1.44 0Zm-1.388 4.319 2.107-2.185 2.109 2.184c.87.9 1.132 2.264.66 3.448C26.297 25.243 25.204 26 24 26c-1.205 0-2.299-.757-2.767-1.928-.473-1.184-.211-2.547.659-3.447ZM4.186 18.419l-.06.096A1 1 0 0 0 5 20h5.866l-.858 6.876c-.13 1.038 1.219 1.553 1.814.693l9-13 .06-.096A1 1 0 0 0 20 13h-5.754l1.73-7.783c.236-1.063-1.157-1.685-1.79-.798l-10 14Zm8.771-8.84-.933 4.204-.02.117A1 1 0 0 0 13 15h5.09l-5.592 8.078.494-3.954.008-.118A1 1 0 0 0 12 18H6.942l6.015-8.421Z"/>
	</g>

    </svg>
);

export default Supplies;

Supplies.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
