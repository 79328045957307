import React from "react";
import {
    useFloating,
    useInteractions,
    useRole,
    useDismiss,
    FloatingPortal,
    FloatingOverlay,
    FloatingFocusManager,
} from "@floating-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";
import classes from "./Dialog.module.css";
import clsx from "clsx";
import IconButton from "Components/IconButton";
import useFloatingProps from "Hooks/useFloatingProps";
import Icon from "Components/Icon";

const sizeClasses = {
    md: false,
    sm: classes.small,
    lg: classes.large,
    xl: classes.extraLarge,
}

const Overlay = motion(FloatingOverlay);

export default function Dialog(props) {
    const { open, onClose, children, size = "md", className, disableClose = false, blockClose = false, title } = useFloatingProps(props);

    const { refs, context } = useFloating({
        open,
        onOpenChange: (open) => {
            if (blockClose) return;

            if (!open) onClose();
        },
    });

    const { getFloatingProps } = useInteractions([
        useRole(context, { role: "dialog" }),
        useDismiss(context, { enabled: !blockClose }),
    ]);

    return (
        <FloatingPortal>
            <AnimatePresence initial={false}>
                {open && (
                    <Overlay
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0, transition: { delay: .3 } }}
                        lockScroll
                        className={classes.overlay}
                        style={{ zIndex: "var(--z-index-modal)" }}
                    >
                        <FloatingFocusManager context={context}>
                            <motion.div
                                initial={{ opacity: 0, y: -28 }}
                                animate={{ opacity: 1, y: 0, transition: { delay: .2 } }}
                                exit={{ opacity: 0, y: -28 }}
                                {...getFloatingProps({
                                    ref: refs.setFloating,
                                    className: clsx(classes.dialog, sizeClasses[size], className),
                                })}
                            >
                                {!disableClose && (
                                    <IconButton className={classes.closeButton} onClick={onClose}>
                                        <Icon size={24} name="close" color="#C0BFBE" />
                                    </IconButton>
                                )}
                                {title && (
                                    <div className={classes.title}>
                                        {title}
                                    </div>
                                )}

                                <div className={clsx(classes.content, { [classes.hasTitle]: !!title })}>
                                    {children}
                                </div>
                            </motion.div>
                        </FloatingFocusManager>
                    </Overlay>
                )}
            </AnimatePresence>
        </FloatingPortal>
    )
}

Dialog.propTypes = {
    open: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    className: PropTypes.string,
    size: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
    disableClose: PropTypes.bool,
    blockClose: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}
