
import PropTypes from "prop-types";
import React from "react";

const Back2 = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M20.954 11.006H6.412L10.924 6.5c.943-.942-.473-2.356-1.417-1.414L5.289 9.304l-1.998 1.998a.997.997 0 0 0 0 1.406L9.5 18.916a.998.998 0 0 0 .724.302c.9 0 1.34-1.094.692-1.716l-4.512-4.5h14.602c1.384-.068 1.281-2.064-.052-1.996Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default Back2;

Back2.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
