import axiosInstance from "Utils/axiosInstance";

const sortByOrderOrName = (a, b) => {
    if (a.order === null && b.order === null) {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    }

    if (a.order === null) return 1;
    if (b.order === null) return -1;

    return a.order > b.order ? 1 : -1;
}

export async function getProviders() {
    const response = await axiosInstance.get("/providers");

    if (!response?.data) return [];

    return response.data
        .map(item => ({
            ...item,
            connectors: item.connectors
                .sort(sortByOrderOrName),
        }))
        .sort(sortByOrderOrName);
}
