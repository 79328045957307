import React, { useState, useEffect } from "react";
import { verifyLoginWithGoogle } from "Services/user";
import { useAuth } from "Components/AuthProvider";
import { Navigate } from "react-router-dom";
import Loading from "Components/Loading";

export default function CallbackGoogle() {
    const { loginUser } = useAuth();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const urlParams = new URLSearchParams((new URL(window.location.href)).search);

        if (urlParams.get('code')) {
            verifyLoginWithGoogle(urlParams.get('code'))
                .then(apiResponse => {
                    loginUser(apiResponse.data.id_token);
                }).finally(() => {
                    setLoading(false);
                })
        }
    }, []);

    if (loading) {
        return <Loading />;
    }

    return <Navigate to="/" />;
}
