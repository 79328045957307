import axios from 'axios';
import storage from './storage';
import { API_HOST } from "../config";
import { fetchAuthSession } from "aws-amplify/auth";

async function getToken() {
  const storageToken = storage.getItem('token') ?? null;

  try {
    const session = await fetchAuthSession();
    const token = session.tokens?.idToken?.toString()
    if (!token) {
      return storageToken
    }

    if (storageToken !== token) {
      storage.setItem('token', token);
    }
    return token;
  } catch (e) {
    return storageToken;
  }
}

const tokenInjection = async (config) => {
  const accessToken = await getToken();

  if (accessToken) {
    config.headers = { ...config.headers, Authorization: `Bearer ${accessToken}` };
  }

  return config;
};

const axiosInstance = axios.create({ baseURL: API_HOST });

axiosInstance.interceptors.request.use(tokenInjection);

export default axiosInstance;