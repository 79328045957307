
import PropTypes from "prop-types";
import React from "react";

const ArrowDownSmall = ({ color, size, ...props }) => (
    <svg viewBox="0 0 28 28" width={size} height={size} {...props}>
        
	<path d="M12.644 19.543 8.162 15.06c-1.287-1.288-3.218.643-1.93 1.93l4.087 4.082 2.73 2.725c.53.528 1.388.528 1.92 0l6.814-6.806c.266-.26.415-.616.412-.987 0-1.226-1.493-1.827-2.343-.944l-4.482 4.492V5.55c-.093-1.888-2.82-1.747-2.726.07v13.923Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default ArrowDownSmall;

ArrowDownSmall.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
