import axiosInstance from "Utils/axiosInstance";
import { mapDtoToAlert, sortItems } from "Utils/mappers";

export async function getAlertsConnections() {
    const response = await axiosInstance.get("/automations/alerts");

    if (!response?.data) return [];

    return response.data.sort(sortItems).map(mapDtoToAlert);
}

export async function deleteAlertConnection(id) {
    await axiosInstance.delete(`/automations/${id}`);
}

export async function createAlertConnection({ name, rules, channels_connections_ids, providers_connections_products_ids, is_rules_advanced }) {
    const alertConnection = {
        channels_connections_ids,
        providers_connections_products_ids,
        name,
        rules,
        is_rules_advanced,
        action: { type: 'send_alert', settings: {} },
        is_enabled: true
    }

    const response = await axiosInstance.post("/automations", alertConnection);

    if (!response?.data) return null;

    return mapDtoToAlert(response.data);
}

export async function editAlertConnection({ id, name, rules, channels_connections_ids, providers_connections_products_ids, is_rules_advanced, is_enabled = true }) {
    const alertConnection = {
        channels_connections_ids,
        providers_connections_products_ids,
        name,
        rules,
        is_rules_advanced,
        is_enabled,
        action: { type: 'send_alert', settings: {} },
    }

    const response = await axiosInstance.put(`/automations/${id}`, alertConnection);

    if (!response?.data) return null;

    return mapDtoToAlert(response.data);
}

export async function sortAlertsConnections(ids) {
    await axiosInstance.post("/automations/sort", ids);
}