
import PropTypes from "prop-types";
import React from "react";

const Padlock = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M5.58 22H17.77c.874 0 1.584-.71 1.584-1.584v-8.738c0-.874-.71-1.584-1.584-1.584h-.04v-2.09A6.011 6.011 0 0 0 11.727 2a6.011 6.011 0 0 0-6.005 6.004v2.09h-.138c-.874 0-1.584.71-1.584 1.584v8.738A1.58 1.58 0 0 0 5.58 22ZM8.347 8.004a3.384 3.384 0 0 1 3.38-3.38 3.384 3.384 0 0 1 3.38 3.38v2.09h-6.76v-2.09Z" fill={color}/>

    </svg>
);

export default Padlock;

Padlock.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
