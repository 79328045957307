import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUser } from "Components/UserProvider/useUser";
import { ROUTES } from "Utils/constants";

export default function GuardPublic() {
    const { user } = useUser();

    if (user && user.is_onboarded) {
        return <Navigate to={ROUTES.dashboards} />;
    }

    if (user && !user.is_onboarded) {
        return <Navigate to={ROUTES.onboarding} />;
    }

    return <Outlet />;
}