
import PropTypes from "prop-types";
import React from "react";

const Taxes = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="evenodd">
		<path d="M24 3H9a3 3 0 0 0-3 3v20a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3ZM9 5h15a1 1 0 0 1 1 1v20a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z" fillRule="nonzero"/>
		<path d="M21 7h-9a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2Zm-9 2h9v2h-9V9Z" fillRule="nonzero"/>
		<g transform="translate(10 15)">
			<circle cx="1.5" cy="1.5" r="1.5"/>
			<circle cx="6.5" cy="1.5" r="1.5"/>
			<circle cx="1.5" cy="7.5" r="1.5"/>
			<circle cx="6.5" cy="7.5" r="1.5"/>
			<circle cx="11.5" cy="1.5" r="1.5"/>
			<circle cx="11.5" cy="7.5" r="1.5"/>
		</g>
	</g>

    </svg>
);

export default Taxes;

Taxes.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
