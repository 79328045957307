import React from "react";
import PropTypes from "prop-types";
import classes from "./Chip.module.css";
import clsx from "clsx";
import IconButton from "Components/IconButton";

const toRgb = (hex) => {
    if (hex.length !== 7) throw new Error("Invalid hex color");

    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `${r}, ${g}, ${b}`;
}

const Chip = ({ children, className, onClick, icon, onDelete, color = "#3582C4" }) => {
    const isClickable = Boolean(onClick);

    const ariaProps = {
        role: isClickable ? "button" : undefined,
        tabIndex: isClickable ? 0 : undefined,
    }

    const rgb = toRgb(color);

    const chipClasses = clsx(classes.chip, className);

    const handleDelete = (event) => {
        event.stopPropagation();
        onDelete();
    }

    return (
        <span style={{ "--chip-color-rgb": rgb }} className={chipClasses} onClick={onClick} {...ariaProps}>
            {icon && <span className={classes.chipIcon}>{icon}</span>}
            {onDelete && (
                <IconButton className={classes.chipDelete} onClick={handleDelete} />
            )}
            <span className={classes.chipText}>
                {children}
            </span>
        </span>
    )
}

Chip.propTypes = {
    children: PropTypes.node.isRequired,
    icon: PropTypes.string,
    className: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
    onDelete: PropTypes.func,
}

export default Chip;