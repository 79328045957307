export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

export const IS_PRODUCTION = process.env.NODE_ENV === 'production';

export const USE_STAGING_COGNITO = process.env.REACT_APP_USE_STAGING_COGNITO === 'true';

export const API_HOST = process.env.REACT_APP_API_HOST || 'http://localhost:8080';

export const COMMIT_HASH = process.env.REACT_APP_COMMIT_HASH || null;

export const COGNITO_POOL_ID = (IS_DEVELOPMENT || USE_STAGING_COGNITO) ? 'eu-west-1_f30oMd6sO' : 'eu-west-1_f9Enh6hBe';

export const COGNITO_CLIENT_ID = (IS_DEVELOPMENT || USE_STAGING_COGNITO) ? '5bgv24ph8lmek6pssk9k4lq4ur' : '473ft22do45h1frtjmktd79m03';