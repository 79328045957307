import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useUser } from "Components/UserProvider/useUser";
import { ROUTES, ROUTES_WITH_SUBROUTES } from "Utils/constants";
import { isLocalStorageAvailable } from 'Utils/storage';

const isValidPath = (path) => {
    const hasRoute = Object.values(ROUTES).includes(path);
    if (hasRoute) return true;

    const hasSubroute = ROUTES_WITH_SUBROUTES.some(subroute => {
        const regex = new RegExp(`^${subroute}/[a-zA-Z0-9]+$`);
        return regex.test(path);
    })

    return hasSubroute;
}

export default function GuardPrivate() {
    const { user } = useUser();
    const { pathname } = useLocation();

    if (!user) {
        if (isValidPath(pathname) && isLocalStorageAvailable()) {
            sessionStorage.setItem('redirect', pathname);
        }
        return <Navigate to={ROUTES.signIn} />;
    }

    if (!user.is_onboarded) {
        return <Navigate to={ROUTES.onboarding} />;
    }

    if (!user.set_project_workspace_id) {
        return <Navigate to={ROUTES.selectWorkspace} />;
    }

    return <Outlet />;
}