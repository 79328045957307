
import PropTypes from "prop-types";
import React from "react";

const Filter2 = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M1.093 2.58A1 1 0 0 1 2 2h20a1 1 0 0 1 .764 1.646L15.6 12.934V21.4a1 1 0 0 1-1.447.894l-5.2-2.6A1 1 0 0 1 8.4 18.8v-5.866L1.236 3.646a1 1 0 0 1-.143-1.067ZM4.155 4l6.009 7.922a1 1 0 0 1 .236.646v5.614l3.2 1.6v-7.214a1 1 0 0 1 .236-.646L19.845 4H4.155Z" fill={color}/>

    </svg>
);

export default Filter2;

Filter2.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
