
import PropTypes from "prop-types";
import React from "react";

const Check = ({ color, size, ...props }) => (
    <svg viewBox="0 0 13 13" width={size} height={size} {...props}>
        
	<path d="M10.245 2.635a1.147 1.147 0 0 1 1.849 1.354l-.07.094-5.853 7.194a1.147 1.147 0 0 1-1.685.102l-.081-.086-3.75-4.434A1.147 1.147 0 0 1 2.327 5.29l.08.086 2.857 3.378 4.981-6.12Z" fill={color}/>

    </svg>
);

export default Check;

Check.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
