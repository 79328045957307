import { getCurrencies } from "Services/others";
import { useQuery } from "@tanstack/react-query";
import { trackError } from "Utils/errorMonitoring";

export default function useCurrencies() {
    const { data: currencies = [], isLoading } = useQuery(["currencies"], getCurrencies, {
        onError: error => trackError(error),
        staleTime: Infinity,
    });

    return {
        currencies,
        isLoading
    }
}