import React, { useState, useEffect, useLayoutEffect } from "react";
import classes from "./ProviderConnectionsErrorManager.module.css";
import PropTypes from "prop-types";
import useProviderConnector from "Hooks/useProviderConnector";
import useProviderConnectionActions from "Hooks/useProviderConnectionActions";
import { useErrors } from "Components/ErrorsProvider";
import RequiresSignatureForm from "./RequiresSignatureForm";
import Loading from "Components/Loading";
import { createProviderConnection } from "Services/providersConnections";
import useProviderConnectionsCredentials from "Hooks/useProviderConnectionsCredentials";
import Button from "Components/Button";

export default function RequiresSignature(props) {
    const { info, onClose, onEnableClose, onClearConnection, providerConnection } = props;
    const [canceling, setCanceling] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [credentials, setCredentials] = useState(null)
    const { connector, isLoading } = useProviderConnector(providerConnection.provider_connector_id);
    const { get } = useProviderConnectionsCredentials()
    const { checkConnection } = useProviderConnectionActions()
    const { showUnknownError } = useErrors();

    useEffect(() => {
        onEnableClose()
    }, [])

    useLayoutEffect(() => {
        const storageCredentials = get(providerConnection.id)
        if (storageCredentials) {
            setCredentials(storageCredentials)
        }
    }, [])

    const handleCancel = async () => {
        setCanceling(true)
        await onClearConnection(false)

        setCanceling(false)
        onClose()
    }

    const handleSubmitFormWithCredentials = async (data) => {
        setSubmitting(true)

        try {
            onClearConnection()
            const conexionId = await createProviderConnection({
                connectorId: providerConnection.provider_connector_id,
                providerConnectionId: providerConnection.provider_connection_reference_id,
                name: providerConnection.name,
                accountId: info.account_id,
                counterId: info.counter_id,
                sessionId: info.session_id,
                otp: info.otp,
                ...data
            });

            checkConnection({
                id: conexionId,
                logo_url: connector.logo_url,
                name: connector.name,
            });
        } catch (error) {
            showUnknownError(error)
        } finally {
            setSubmitting(false)
            onClose()
        }
    }

    const handleSubmit = async () => {
        setSubmitting(true)

        try {
            onClearConnection()
            const conexionId = await createProviderConnection({
                connectorId: providerConnection.provider_connector_id,
                providerConnectionId: providerConnection.provider_connection_reference_id,
                name: providerConnection.name,
                accountId: info.account_id,
                counterId: info.counter_id,
                sessionId: info.session_id,
                documentType: info.document_type,
                otp: info.otp,
                credentials,
            });

            checkConnection({
                id: conexionId,
                logo_url: connector.logo_url,
                name: connector.name,
                credentials
            });
        } catch (error) {
            showUnknownError(error)
        } finally {
            setSubmitting(false)
            onClose()
        }
    }

    if (isLoading) {
        return (
            <div>
                <Loading />
            </div>
        )
    }

    const { fields_types, document_types, fields_descriptions, fields_names = [] } = connector.service_provider_meta
    const documentIndex = Number.isNaN(Number(info.document_type)) ? 0 : Number(info.document_type)
    const documentType = Array.isArray(document_types) ? document_types[documentIndex] : null

    const fieldsNamesWithContent = Array.isArray(info.credentials) ? fields_names.filter((_name, index) => info.credentials[index] != null) : []

    const disabledFields = ["document_type", ...fieldsNamesWithContent]

    if (credentials) {
        return (
            <div className="text-center">
                <h4>Verifica tu acceso</h4>
                <div className="text-muted mb-4">Necesitamos que autorices el acceso desde la app de tu banco.</div>

                <div className="pt-2 d-flex flex-column gap-2 justify-content-center align-items-center">
                    <Button size="large" primary onClick={handleSubmit} isBusy={submitting}>
                        <img alt="" src="/img/icon-padlock.svg" />
                        <span className="ms-2">He autorizado el acceso</span>
                    </Button>
                    <button className={classes.textButton} type="button" onClick={handleCancel} disabled={canceling}>
                        Cancelar conexión
                    </button>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className="mb-5">
                <h4>Verifica tu acceso</h4>
                <div className="text-muted mb-4">Necesitamos que autorices el acceso desde la app de tu banco.</div>

                <div className={classes.divider} />
            </div>
            <RequiresSignatureForm
                onSubmit={handleSubmitFormWithCredentials}
                fieldsNames={fields_names}
                fieldsDescriptions={fields_descriptions}
                fieldsTypes={fields_types}
                documentTypes={document_types}
                documentType={documentType}
                fieldValues={info.credentials}
                disabledFields={disabledFields}
                onCancel={handleCancel}
                canceling={canceling}
                submitting={submitting}
            />
        </div>
    )
}

RequiresSignature.propTypes = {
    providerConnection: PropTypes.object.isRequired,
    description: PropTypes.string.isRequired,
    info: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onEnableClose: PropTypes.func.isRequired,
    onClearConnection: PropTypes.func.isRequired,
}