import { rest } from 'msw'
import { API_HOST } from 'config'
import user from "./fixtures/user.json"
import projectSelected from "./fixtures/projectSelected.json"
import workspaceSelected from "./fixtures/workspaceSelected.json"
import billing from "./fixtures/billing.json"
import projects from "./fixtures/projects.json"
import projectUsers from "./fixtures/projectUsers.json"
import workspaces from "./fixtures/workspaces.json"

const usersHandlers = [
    rest.get(`${API_HOST}/users/me`, (req, res, ctx) => {
        if (req.headers.get('Authorization') !== 'Bearer 123') {
            return res(
                ctx.status(401),
                ctx.json({
                    message: 'Unauthorized'
                })
            )
        }
        return res(
            ctx.json(user),
        )
    }),

    rest.put(`${API_HOST}/users/me`, async (req, res, ctx) => {
        const updatedUser = {
            ...user,
            ...(await req.json())
        }

        return res(
            ctx.json(updatedUser),
        )
    }),

    rest.post(`${API_HOST}/users/me/log_event`, (req, res, ctx) => {
        return res(
            ctx.status(204),
        )
    }),

    rest.delete(`${API_HOST}/users/me`, (req, res, ctx) => {
        return res(
            ctx.status(204),
        )
    }),

    rest.get(`${API_HOST}/users/projects/selected`, (req, res, ctx) => {
        return res(
            ctx.json(projectSelected),
        )
    }),

    rest.get(`${API_HOST}/users/projects/workspaces/selected`, (req, res, ctx) => {
        return res(
            ctx.json(workspaceSelected),
        )
    }),

    rest.get(`${API_HOST}/users/projects/selected/billing`, (req, res, ctx) => {
        return res(
            ctx.json(billing),
        )
    }),

    rest.get(`${API_HOST}/users/invitations`, (req, res, ctx) => {
        return res(
            ctx.json([]),
        )
    }),

    rest.get(`${API_HOST}/users/projects`, (req, res, ctx) => {
        return res(
            ctx.json(projects),
        )
    }),

    rest.get(`${API_HOST}/users/projects/selected/users`, (req, res, ctx) => {
        return res(
            ctx.json(projectUsers),
        )
    }),

    rest.get(`${API_HOST}/users/projects/selected/workspaces`, (req, res, ctx) => {
        return res(
            ctx.json(workspaces),
        )
    }),

    rest.put(`${API_HOST}/users/projects/selected/subscription`, (req, res, ctx) => {
        return res(
            ctx.json({
                "link": "https://checkout.banktrack.com/p/session/live_YWNjdF8xTUVzd1FGWUsyU250dW9LLF9QSjRaaEJxZlJWNWZ5c2ZMREY2WkxoR0FWM2NhS0pY0100y3xl8Muq"
            }),
        )
    }),
]

export default usersHandlers