
import PropTypes from "prop-types";
import React from "react";

const NavAlerts = ({ color, size, ...props }) => (
    <svg viewBox="0 0 28 28" width={size} height={size} {...props}>
        
	<path d="M7 10a7 7 0 0 1 14 0c0 3.353.717 5.435 1.378 6.646.332.608.655 1.008.88 1.244a3.026 3.026 0 0 0 .306.284l.002.002A1 1 0 0 1 23 20H5a1 1 0 0 1-.563-1.826l.052-.042c.053-.044.141-.123.254-.242.224-.236.547-.636.88-1.244C6.282 15.435 7 13.353 7 10Zm-2.556 8.169ZM7.149 18h13.702a9.421 9.421 0 0 1-.229-.396C19.783 16.065 19 13.647 19 10a5 5 0 0 0-10 0c0 3.647-.783 6.065-1.622 7.604-.076.14-.153.271-.229.396ZM11.768 22.135a1 1 0 0 1 1.367.363 1 1 0 0 0 1.73 0 1 1 0 0 1 1.73 1.004 3 3 0 0 1-5.19 0 1 1 0 0 1 .363-1.367Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default NavAlerts;

NavAlerts.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
