
import PropTypes from "prop-types";
import React from "react";

const User = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M16 13.852c2.759 0 5 2.213 5 4.95v.976a1 1 0 1 1-2 0v-.976c0-1.626-1.34-2.95-3-2.95H8c-1.66 0-3 1.324-3 2.95v.976a1 1 0 1 1-2 0v-.976c0-2.736 2.241-4.95 5-4.95h8ZM12 2C9.241 2 7 4.214 7 6.95c0 2.738 2.241 4.951 5 4.951s5-2.213 5-4.95S14.759 2 12 2Zm0 2c1.66 0 3 1.324 3 2.95 0 1.627-1.34 2.951-3 2.951s-3-1.324-3-2.95C9 5.324 10.34 4 12 4Z" fill={color}/>

    </svg>
);

export default User;

User.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
