import React from "react";
import PropTypes from "prop-types";
import classes from "./TransactionCard.module.css";
import clsx from "clsx";
import { formatCurrency } from "Utils/formatters";
import Tooltip from "Components/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import useProvidersConnections from "Hooks/useProvidersConnections";
import useLocale from "Hooks/useLocale";
import { useTranslation } from "react-i18next";

export default function TransactionCard(props) {
    const { date_booked, date_executed, description_original, description, provider_connection_product, amount, currency, reverse_sign } = props;
    const { providersConnections } = useProvidersConnections();
    const provider = providersConnections.find((providerConnection) => providerConnection.id === provider_connection_product.provider_connection_id);

    const isExpense = amount * (reverse_sign ? -1 : 1) < 0;
    const { t } = useTranslation("transactions");
    const locale = useLocale();

    return (
        <div className={classes.card}>
            <div className={classes.header}>
                <img src={provider_connection_product.provider_logo_url} className={classes.logo} height={20} width={20} alt="" />
                <div>
                    <div className={classes.nameProvider}>{provider?.name}</div>
                    <div className="opacity-50">{provider_connection_product.name}</div>
                </div>
            </div>

            <div className={classes.content}>
                <div>
                    <div className={classes.primaryText}>{description}</div>
                    <div className="opacity-50">{description_original}</div>
                </div>
                <div className={classes.flexEnd}>
                    <div className={clsx(classes.primaryText, "sentry-block", { [classes.isIncome]: !isExpense })}>
                        {formatCurrency(amount, { currency })}
                    </div>
                    <div className={classes.date}>
                        <div className="opacity-50">{date_booked.toLocaleDateString(locale)}</div>
                        {date_executed && date_executed.getUTCDate() !== date_booked.getUTCDate() && (
                            <Tooltip label={t("executed-on", { date: date_executed.toLocaleDateString(locale) })}>
                                <div className="ms-1 cursor-pointer">
                                    <FontAwesomeIcon icon={faInfoCircle} color="#9a9b9d" />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

TransactionCard.propTypes = {
    date_booked: PropTypes.objectOf(Date).isRequired,
    date_executed: PropTypes.objectOf(Date),
    description_original: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    provider_connection_product: PropTypes.object.isRequired,
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    reverse_sign: PropTypes.bool,
}