import React from "react";
import PropTypes from "prop-types";
import classes from "./Container.module.css";
import clsx from "clsx";

export default function Container(props) {
    const { children, className } = props;

    return (
        <div className={clsx(classes.container, className)}>
            {children}
        </div>
    );
}

Container.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};