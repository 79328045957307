import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getCategories, sortCategories, removeParentCategory, addParentCategory } from "Services/categories";
import { trackError } from "Utils/errorMonitoring";
import { useState, useLayoutEffect, useMemo } from "react";
import { UNCATEGORIZED_CATEGORY } from "Utils/constants";

const mapCategoryToOption = (category) => {
    const hasParent = !!category.parent_tag_id;
    return {
        id: category.id,
        label: category.name,
        color: category.color,
        className: hasParent ? 'ms-2' : '',
        icon: hasParent ? null : category.icon
    }
}

export default function useCategories() {
    const { data, isLoading } = useQuery(["categories"], getCategories, {
        onError: error => trackError(error),
    })

    const queryClient = useQueryClient();
    const [categories, setCategories] = useState([]);

    const categoriesWithUncategorized = useMemo(() => categories.concat(UNCATEGORIZED_CATEGORY), [categories]);
    const formattedCategoryOptions = useMemo(() => categories.map(mapCategoryToOption).concat(UNCATEGORIZED_CATEGORY), [categories]);

    useLayoutEffect(() => {
        if (!isLoading && Array.isArray(data)) {
            setCategories(data);
        }
    }, [data, isLoading]);

    const { mutate: sort } = useMutation(sortCategories, {
        onMutate: (ids) => {
            const newCategories = ids
                .map(id => categories.find(item => item.id === id))
                .filter(Boolean)

            setCategories(newCategories);
            queryClient.setQueryData(["categories"], newCategories);
        }
    });

    const { mutate: sortParents } = useMutation(sortCategories, {
        onMutate: (parentIds) => {
            const childrenCategories = categories.filter(category => category.parent_tag_id)
            const newCategories = parentIds
                .map(id => categories.find(item => item.id === id))
                .filter(Boolean)
                .map((item, index) => ({ ...item, order: index + 1 }));

            const result = newCategories.flatMap(parent => {
                const children = childrenCategories.filter(category => category.parent_tag_id === parent.id)
                const formattedChildren = children.map(child => ({
                    ...child,
                    icon: parent.icon,
                    color: parent.color,
                    bgColor: parent.bgColor,
                }))

                return [parent, ...formattedChildren]
            })

            setCategories(result);
            queryClient.setQueryData(["categories"], result);
        }
    });

    const { mutate: sortChildren } = useMutation(({ ids }) => sortCategories(ids), {
        onMutate: ({ parentId, ids }) => {
            const parentsWithOutCurrentChildren = categories.filter(category => category.parent_tag_id !== parentId)
            const sortedChildren = ids.map(id => categories.find(category => category.id === id))

            const newCategories = parentsWithOutCurrentChildren.flatMap((parent) => {
                if (parent.id === parentId) {
                    return [parent, ...sortedChildren]
                }

                return [parent]
            })

            setCategories(newCategories);
            queryClient.setQueryData(["categories"], newCategories);
        },
    })

    const { mutate: upgradeChild } = useMutation(({ category, ids }) => removeParentCategory(category, ids), {
        onMutate: ({ category, ids }) => {
            const categoryId = category.id;

            const current = categories.find(category => category.id === categoryId)
            const updatedCategory = {
                ...current,
                parent_tag_id: null,
            }

            const children = categories.filter(category => category.parent_tag_id && category.id !== categoryId)
            const parents = ids.map(id => categories.find(category => category.id === id))

            const newCategories = parents.flatMap((parent) => {
                const childrenToAdd = children.filter(category => category.parent_tag_id === parent.id)
                if (parent.id === categoryId) {
                    return [updatedCategory]
                }
                return [parent, ...childrenToAdd]
            })

            setCategories(newCategories);
            queryClient.setQueryData(["categories"], newCategories);
        }
    });

    const { mutate: updateParentCategory } = useMutation(({ category, parentId, ids }) => addParentCategory(category, parentId, ids), {
        onMutate: ({ category, parentId, ids }) => {
            const categoryId = category.id;
            const parent = categories.find(category => category.id === parentId)
            const updatedCategory = {
                ...category,
                parent_tag_id: parentId,
                icon: parent.icon,
                color: parent.color,
                bgColor: parent.bgColor,
            }

            const updatedCategories = categories.map(category => category.id === categoryId ? updatedCategory : category)
            const parents = updatedCategories.filter(category => !category.parent_tag_id);

            const newChildren = ids.map(id => updatedCategories.find(category => category.id === id))
            const newCategories = parents.flatMap((parent) => {
                if (parent.id === parentId) {
                    return [parent, ...newChildren]
                }
                const childrenToAdd = updatedCategories.filter(category => category.parent_tag_id === parent.id)
                return [parent, ...childrenToAdd]
            })

            setCategories(newCategories);
            queryClient.setQueryData(["categories"], newCategories);
        }
    });

    return {
        categories,
        isLoading,
        sort,
        categoriesCount: categories.length,
        flatCategories: categories,
        sortParents,
        sortChildren,
        upgradeChild,
        updateParentCategory,
        formattedCategoryOptions,
        categoriesWithUncategorized
    }
}