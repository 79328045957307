import axiosInstance from "Utils/axiosInstance";
import { mapDtoToAutomation, sortItems } from "Utils/mappers";

// Services
export async function getAutomations() {
    const response = await axiosInstance.get("/automations");

    if (!response?.data) return [];

    return response.data
        .filter(item => item.action.type !== 'send_alert')
        .sort(sortItems)
        .map(mapDtoToAutomation);
}

export async function deleteAutomation(id) {
    await axiosInstance.delete(`/automations/${id}`);
}

export async function createAutomation({ name, rules, providers_connections_products_ids, is_rules_advanced, action }) {
    const automation = {
        providers_connections_products_ids,
        channels_connections_ids: [],
        name,
        rules,
        is_rules_advanced,
        action,
        is_enabled: true
    }

    const response = await axiosInstance.post("/automations", automation);

    if (!response?.data) return null;

    return mapDtoToAutomation(response.data);
}

export async function editAutomation({ id, name, rules, providers_connections_products_ids, is_rules_advanced, action, is_enabled = true }) {
    const automation = {
        providers_connections_products_ids,
        channels_connections_ids: [],
        name,
        rules,
        is_rules_advanced,
        is_enabled,
        action
    }

    const response = await axiosInstance.put(`/automations/${id}`, automation);

    if (!response?.data) return null;

    return mapDtoToAutomation(response.data);
}

export async function getAutomationRetrospectPreview(id) {
    const response = await axiosInstance.post(`/automations/${id}/preview`);
    return response?.data;
}

export async function consolidateAutomationRetrospect(id) {
    await axiosInstance.post(`/automations/${id}/retrospect`);
}

export async function sortAutomations(ids) {
    await axiosInstance.post("/automations/sort", ids);
}

export async function consolidateAutomationRetrospectAll() {
    await axiosInstance.post("/automations/retrospect");
}