import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import InputField from 'Components/InputField';

export default function RHFTextField({ name, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <InputField
                    {...field}
                    value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
                    error={error?.message}
                    {...other}
                />
            )}
        />
    );
}

RHFTextField.propTypes = {
    name: PropTypes.string,
};