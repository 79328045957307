class EventDispatcher {
    #listeners;

    constructor() {
        this.#listeners = new Map();
    }

    subscribe(type, listener) {
        if (!this.#listeners.has(type)) {
            this.#listeners.set(type, []);
        }

        this.#listeners.get(type).push(listener);

        return () => {
            this.#unsubscribe(type, listener);
        }
    }

    dispatch(type, ...args) {
        if (!this.#listeners.has(type)) return;

        const listeners = this.#listeners.get(type);
        listeners.forEach(listener => listener(...args));
    }

    #unsubscribe(type, listener) {
        if (!this.#listeners.has(type)) return;

        const listeners = this.#listeners.get(type);
        const index = listeners.indexOf(listener);
        if (index !== -1) {
            listeners.splice(index, 1);
        }
    }
}

const eventDispatcher = new EventDispatcher();
export default eventDispatcher;