import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useRadioProps } from "./RadioGroupContext";
import classes from "./Radio.module.css";

const RadioCard = React.forwardRef((props, inputRef) => {
  const { label, image, className, id, ...inputProps } = useRadioProps(props);

  return (
    <label className={clsx(classes.radioCard, {[classes.radioCardSelected]: inputProps.checked}, className)} htmlFor={id}>

      {inputProps.checked && (
        <div className={classes.radioCardChecked}>
          <svg stroke="currentcolor" width="16"  height="16" viewBox="0 0 12 12">
            <path strokeLinecap="round" strokeWidth="2" d="M2 6l2.894 2.894a.15.15 0 00.212 0L11 3" />
          </svg>
        </div>
      )}

      <input type="radio" ref={inputRef} className={classes.radioCardInput} id={id} {...inputProps} />
        <div className={classes.radioCardImageWrapper}>
          <img src={image} alt="" className={classes.radioCardImage} />
        </div>
        <div className={classes.radioCardLabel}>{label}</div>
    </label>
  )
});

RadioCard.propTypes = {
    label: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    name: PropTypes.string,
    checked: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    className: PropTypes.string,
};

RadioCard.displayName = "RadioCard";

export default RadioCard;
