import React, { useEffect } from "react";
import ErrorFallback from "./ErrorFallback";
import { useRouteError } from "react-router-dom";
import { trackError } from "Utils/errorMonitoring";

const ErrorBoundaryRoute = () => {
    const error = useRouteError();

    useEffect(() => {
        if (error) {
            trackError(error);
        }
    }, [error]);

    if (error) {
        return (
            <ErrorFallback />
        )
    }

    return null;
}


export default ErrorBoundaryRoute;