import React from "react";
import Button from "Components/Button";
import PropTypes from "prop-types";

export default function ReconnectingEmptyMatch(props) {
    const { onClearConnection, onClose } = props
    const [processing, setProcessing] = React.useState(false)

    const handleClick = async () => {
        setProcessing(true)
        await onClearConnection()
        onClose()
        setProcessing(false)
    }

    return (
        <div className={"text-center"}>
            <img src="/img/icon-error.svg" alt="Icono de Error" />
            <h3 className={"fw-500 mt-4"}>Banco no Coincide</h3>
            <span className={"d-block mt-2 text-muted"}>Los productos del banco que intentas reconectar no coinciden.</span>
            <div className="mt-4">
                <Button primary size="large" onClick={handleClick} isBusy={processing}>Cerrar</Button>
            </div>
        </div>
    )
}

ReconnectingEmptyMatch.propTypes = {
    onClearConnection: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
}
