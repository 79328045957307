import React from "react";
import classes from "./PriceTable.module.css";
import Icon from "Components/Icon";
import Tooltip from "Components/Tooltip";
import iconInfinite from "./assets/icon-infinite.svg";
import { PLANS } from "Utils/constants";
import { useTranslation } from "react-i18next";
import useLocale from "Hooks/useLocale";

export const PLAN_NAMES = {
    BUSINESS: 'business',
    PERSONAL: 'personal',
}

const PRICES = {
    BUSINESS: {
        STARTER: {
            monthly: 19.9,
            yearly: 16.58,
            yearlyFullPrice: 199,
        },
        ADVANCED: {
            monthly: 99.9,
            yearly: 83.25,
            yearlyFullPrice: 999,
        }
    },
    PERSONAL: {
        BASIC: {
            monthly: 5.9,
            yearly: 4.92,
            yearlyFullPrice: 59,
        },
        FREE: null
    }
}

function formatList(list, locale) {
    if (typeof Intl.ListFormat === 'undefined') {
        return list.join(', ');
    }

    const formatter = new Intl.ListFormat(locale, {
        style: 'long',
        type: 'conjunction',
    });
    return formatter.format(list);
}

function formatDisjunctiveList(list, locale) {
    if (typeof Intl.ListFormat === 'undefined') {
        const disjuntive = locale === "es-ES" ? 'o' : 'or';

        return list.join(` ${disjuntive} `);
    }

    const formatter = new Intl.ListFormat(locale, {
        style: 'long',
        type: 'disjunction',
    });
    return formatter.format(list);
}

export default function usePriceTableConfig(plan) {

    const { t } = useTranslation("pricing");
    const locale = useLocale();

    const priceConfig = {
        [PLAN_NAMES.BUSINESS]: {
            plans: [
                {
                    id: PLANS.BUSINESS_1,
                    title: t("business-starter.title"),
                    description: t("business-starter.description"),
                    classes: {
                        title: classes.gradientTextPrimary,
                    },
                    price: PRICES.BUSINESS.STARTER,
                    iconList: [{
                        iconName: "banks",
                        title: t("business-starter.features.connected-banks.title"),
                        description: (
                            <>
                                {t("business-starter.features.connected-banks.description-slot-1")}
                                {" "}
                                <strong className={classes.highlight}>{t("business-starter.features.connected-banks.description-slot-2")}</strong>
                                {" "}
                                {t("business-starter.features.connected-banks.description-slot-3")}
                            </>
                        )
                    }, {
                        iconName: "dashboards",
                        title: t("business-starter.features.dashboards.title"),
                        description: t("business-starter.features.dashboards.description"),
                    }, {
                        iconName: "alerts",
                        title: t("business-starter.features.alerts-reports.title"),
                        description: t("business-starter.features.alerts-reports.description"),
                    }, {
                        iconName: "categories",
                        title: t("business-starter.features.categorization.title"),
                        description: t("business-starter.features.categorization.description"),
                    }, {
                        iconName: "users",
                        title: t("business-starter.features.multiuser.title"),
                        description: (
                            <>
                                {t("business-starter.features.multiuser.description-slot-1")}
                                {" "}
                                <strong className={classes.highlight}>{t("business-starter.features.multiuser.description-slot-2")}</strong>
                            </>
                        )
                    }, {
                        iconName: "invoices",
                        title: t("business-starter.features.invoices.title"),
                        description: t("business-starter.features.invoices.description"),
                        disabled: true
                    }, {
                        iconName: "workspaces",
                        title: t("business-starter.features.workspaces.title"),
                        description: t("business-starter.features.workspaces.description"),
                        disabled: true
                    }, {
                        iconName: "forecast",
                        title: t("business-starter.features.forecasting.title"),
                        description: t("business-starter.features.forecasting.description"),
                        disabled: true
                    }, {
                        iconName: "integrations",
                        title: t("business-starter.features.integrations.title"),
                        description: t("business-starter.features.integrations.description"),
                        disabled: true
                    }, {
                        iconName: "support",
                        title: t("business-starter.features.support.title"),
                        description: t("business-starter.features.support.description"),
                        disabled: true
                    }]
                }, {
                    id: PLANS.BUSINESS_3,
                    title: t("business-advanced.title"),
                    description: t("business-advanced.description"),
                    classes: {
                        title: classes.gradientTextBlack,
                    },
                    price: PRICES.BUSINESS.ADVANCED,
                    iconList: [{
                        iconName: "banks",
                        title: t("business-advanced.features.connected-banks.title"),
                        description: (
                            <>
                                {t("business-advanced.features.connected-banks.description-slot-1")}
                                {" "}
                                <strong className={classes.highlight}>{t("business-advanced.features.connected-banks.description-slot-2")}</strong>
                                {" "}
                                {t("business-advanced.features.connected-banks.description-slot-3")}
                            </>
                        ),
                    }, {
                        iconName: "dashboards",
                        title: t("business-advanced.features.dashboards.title"),
                        description: t("business-advanced.features.dashboards.description"),
                    }, {
                        iconName: "alerts",
                        title: t("business-advanced.features.alerts-reports.title"),
                        description: t("business-advanced.features.alerts-reports.description"),
                    }, {
                        iconName: "categories",
                        title: t("business-advanced.features.categorization.title"),
                        description: t("business-advanced.features.categorization.description"),
                    }, {
                        iconName: "users",
                        title: t("business-advanced.features.multiuser.title"),
                        description: (
                            <>
                                {t("business-advanced.features.multiuser.description-slot-1")}
                                {" "}
                                <strong className={classes.highlight}>{t("business-advanced.features.multiuser.description-slot-2")}</strong>
                            </>
                        ),
                    }, {
                        iconName: "invoices",
                        title: t("business-advanced.features.invoices.title"),
                        description: (
                            <>
                                {t("business-advanced.features.invoices.description-slot-1")}
                                {" "}
                                <strong className={classes.highlight}>{t("business-advanced.features.invoices.description-slot-2")}</strong>
                                {" "}
                                {t("business-advanced.features.invoices.description-slot-3")}
                            </>
                        ),
                    }, {
                        iconName: "workspaces",
                        title: t("business-starter.features.workspaces.title"),
                        description: (
                            <>
                                {t("business-advanced.features.workspaces.description-slot-1")}
                                {" "}
                                <strong className={classes.highlight}>{t("business-advanced.features.workspaces.description-slot-2")}</strong>
                            </>
                        ),
                    }, {
                        iconName: "forecast",
                        title: t("business-advanced.features.forecasting.title"),
                        description: t("business-advanced.features.forecasting.description"),
                    }, {
                        iconName: "integrations",
                        title: t("business-advanced.features.integrations.title"),
                        description: t("business-advanced.features.integrations.description"),
                    }, {
                        iconName: "support",
                        title: t("business-advanced.features.support.title"),
                        description: t("business-advanced.features.support.description"),
                    }],
                }],
            table: {
                columns: [{
                    title: <div className={classes.gradientTextPrimary}>{t("business-starter.title")}</div>,
                    price: PRICES.BUSINESS.STARTER,
                }, {
                    title: <div className={classes.gradientTextBlack}>{t("business-advanced.title")}</div>,
                    price: PRICES.BUSINESS.ADVANCED,
                }],
                rows: [{
                    isDivider: true,
                    title: t("cross-table.connected-banks"),
                }, {
                    title: t("cross-table.syncronized-banks"),
                    cells: ['10', '25']
                }, {
                    title: <div className="d-flex align-items-center gap-1" key="importacion-datos-plus2">
                        <span>{t("cross-table.business-use")}</span>
                        <Tooltip label={t("cross-table.business-use-description")}>
                            <span className="cursor-help"><Icon name="circle-info" size={20} color="rgba(32,32,32, .25)" /></span>
                        </Tooltip>
                    </div>,
                    cells: [
                        <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />,
                        <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />,
                    ]
                }, {
                    isDivider: true,
                    title: t("cross-table.categorization"),
                }, {
                    title: t("cross-table.categorization-suggestions"),
                    cells: [
                        <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />,
                        <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />
                    ]
                }, {
                    title: t("cross-table.automatic-rules"),
                    cells: [
                        <img key="infinite-bank-sync" src={iconInfinite} alt={t("cross-table.unlimited")} />,
                        <img key="infinite-bank-sync" src={iconInfinite} alt={t("cross-table.unlimited")} />
                    ]
                }, {
                    isDivider: true,
                    title: t("cross-table.search"),
                }, {
                    title: t("cross-table.history"),
                    cells: [
                        <img key="infinite-bank-sync" src={iconInfinite} alt={t("cross-table.unlimited")} />,
                        <img key="infinite-bank-sync" src={iconInfinite} alt={t("cross-table.unlimited")} />
                    ]
                }, {
                    title: t("cross-table.export"),
                    cells: [
                        formatList(['PDF', 'CSV', 'JSON'], locale),
                        formatList(['PDF', 'CSV', 'JSON'], locale)
                    ]
                }, {
                    title: t("cross-table.import"),
                    cells: [
                        t("cross-table.self-service"),
                        <div className="d-flex align-items-center gap-1 justify-content-center" key="importacion-datos-plus">
                            <span>{t("cross-table.ocr")}</span>
                            <Tooltip label={t("cross-table.ocr-description")}>
                                <span className="cursor-help"><Icon name="circle-info" size={20} color="rgba(32,32,32, .25)" /></span>
                            </Tooltip>
                        </div>,
                    ]
                }, {
                    isDivider: true,
                    title: t("cross-table.cash-flow"),
                }, {
                    title: t("cross-table.dashboards-reports-alerts"),
                    cells: [
                        <img key="infinite-bank-sync" src={iconInfinite} alt={t("cross-table.unlimited")} />,
                        <img key="infinite-bank-sync" src={iconInfinite} alt={t("cross-table.unlimited")} />
                    ]
                }, {
                    title: t("cross-table.channels"),
                    cells: [
                        formatList(["WhatsApp", "Email", "Telegram", "SMS", "Slack"], locale),
                        formatList(["WhatsApp", "Email", "Telegram", "SMS", "Slack"], locale)
                    ]
                }, {
                    title: t("cross-table.forecasting"),
                    cells: [
                        <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />,
                        <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />
                    ]
                }, {
                    isDivider: true,
                    title: t("cross-table.concilation"),
                }, {
                    title: t("cross-table.invoices"),
                    cells: [
                        <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />,
                        <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />
                    ]
                }, {
                    title: t("cross-table.contacts"),
                    cells: [
                        <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />,
                        <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />
                    ]
                }, {
                    title: t("cross-table.invoices-quarter"),
                    cells: [
                        <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />,
                        "1000"]
                }, {
                    isDivider: true,
                    title: t("cross-table.multi-users"),
                }, {
                    title: t("cross-table.users"),
                    cells: ["2", "10"]
                }, {
                    title: t("cross-table.workspaces"),
                    cells: ["1", "3"]
                }, {
                    isDivider: true,
                    title: t("cross-table.support"),
                }, {
                    title: t("cross-table.account-manager"),
                    cells: [
                        <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />,
                        <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />,
                    ]
                }, {
                    title: t("cross-table.onboarding"),
                    cells: [
                        t("cross-table.yes-annual-plan"),
                        <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />
                    ]
                }, {
                    title: t("cross-table.contact-methods"),
                    cells: [
                        formatDisjunctiveList(["Chat", "Email"], locale),
                        formatDisjunctiveList(["Chat", "Email, WhatsApp"], locale),
                    ]
                }]
            }
        },
        [PLAN_NAMES.PERSONAL]: {
            plans: [{
                title: t("basic.title"),
                description: t("basic.description"),
                price: PRICES.PERSONAL.FREE,
                iconList: [{
                    iconName: "banks",
                    title: t("basic.features.connected-banks.title"),
                    description: (
                        <>
                            {t("basic.features.connected-banks.description-slot-1")}
                            {" "}
                            <strong className={classes.highlight}>{t("basic.features.connected-banks.description-slot-2")}</strong>
                            {" "}
                            {t("basic.features.connected-banks.description-slot-3")}
                        </>
                    )
                }, {
                    iconName: "dashboards",
                    title: t("basic.features.dashboards.title"),
                    description: t("basic.features.dashboards.description"),
                }, {
                    iconName: "alerts",
                    title: t("basic.features.alerts-reports.title"),
                    description: t("basic.features.alerts-reports.description"),
                }, {
                    iconName: "categories",
                    title: t("basic.features.categorization.title"),
                    description: t("basic.features.categorization.description"),
                    disabled: true
                }, {
                    iconName: "users",
                    title: t("basic.features.multiuser.title"),
                    description: t("basic.features.multiuser.description"),
                    disabled: true
                }, {
                    iconName: "invoices",
                    title: t("basic.features.invoices.title"),
                    description: t("basic.features.invoices.description"),
                    disabled: true
                }, {
                    iconName: "workspaces",
                    title: t("basic.features.workspaces.title"),
                    description: t("basic.features.workspaces.description"),
                    disabled: true
                }, {
                    iconName: "forecast",
                    title: t("basic.features.forecasting.title"),
                    description: t("basic.features.forecasting.description"),
                    disabled: true
                }, {
                    iconName: "integrations",
                    title: t("basic.features.integrations.title"),
                    description: t("basic.features.integrations.description"),
                    disabled: true
                }, {
                    iconName: "support",
                    title: t("basic.features.support.title"),
                    description: t("basic.features.support.description"),
                    disabled: true
                }]
            },
            {
                id: PLANS.PERSONAL,
                title: t("personal.title"),
                description: t("personal.description"),
                classes: {
                    title: classes.gradientTextSecondary,
                },
                price: PRICES.PERSONAL.BASIC,
                iconList: [{
                    iconName: "banks",
                    title: t("personal.features.connected-banks.title"),
                    description: (
                        <>
                            {t("personal.features.connected-banks.description-slot-1")}
                            {" "}
                            <strong className={classes.highlight}>{t("personal.features.connected-banks.description-slot-2")}</strong>
                            {" "}
                            {t("personal.features.connected-banks.description-slot-3")}
                        </>
                    ),
                }, {
                    iconName: "dashboards",
                    title: t("personal.features.dashboards.title"),
                    description: t("personal.features.dashboards.description"),
                }, {
                    iconName: "alerts",
                    title: t("personal.features.alerts-reports.title"),
                    description: t("personal.features.alerts-reports.description"),
                }, {
                    iconName: "categories",
                    title: t("personal.features.categorization.title"),
                    description: t("personal.features.categorization.description"),
                }, {
                    iconName: "users",
                    title: t("personal.features.multiuser.title"),
                    description: (
                        <>
                            {t("personal.features.multiuser.description-slot-1")}
                            {" "}
                            <strong className={classes.highlight}>{t("personal.features.multiuser.description-slot-2")}</strong>
                        </>
                    ),
                }, {
                    iconName: "invoices",
                    title: t("personal.features.invoices.title"),
                    description: t("personal.features.invoices.description"),
                    disabled: true
                }, {
                    iconName: "workspaces",
                    title: t("personal.features.workspaces.title"),
                    description: t("personal.features.workspaces.description"),
                    disabled: true
                }, {
                    iconName: "forecast",
                    title: t("personal.features.forecasting.title"),
                    description: t("personal.features.forecasting.description"),
                    disabled: true
                }, {
                    iconName: "integrations",
                    title: t("personal.features.integrations.title"),
                    description: t("personal.features.integrations.description"),
                    disabled: true
                }, {
                    iconName: "support",
                    title: t("personal.features.support.title"),
                    description: t("personal.features.support.description"),
                    disabled: true
                }]
            }],
            table: {
                columns: [{
                    title: <div className={classes.gradientTextGray}>{t("basic.title")}</div>,
                    price: PRICES.PERSONAL.FREE
                }, {
                    title: <div className={classes.gradientTextSecondary}>{t("personal.title")}</div>,
                    price: PRICES.PERSONAL.BASIC
                }],
                rows: [{
                    isDivider: true,
                    title: t("cross-table.connected-banks"),
                }, {
                    title: t("cross-table.syncronized-banks"),
                    cells: ['1', '5']
                }, {
                    title: <div className="d-flex align-items-center gap-1" key="importacion-datos-plus2">
                        <span>{t("cross-table.business-use")}</span>
                        <Tooltip label={t("cross-table.business-use-description")}>
                            <span className="cursor-help"><Icon name="circle-info" size={20} color="rgba(32,32,32, .25)" /></span>
                        </Tooltip>
                    </div>,
                    cells: [
                        <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />,
                        <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />
                    ]
                }, {
                    isDivider: true,
                    title: t("cross-table.categorization"),
                }, {
                    title: t("cross-table.categorization-suggestions"),
                    cells: [
                        <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />,
                        <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />
                    ]
                }, {
                    title: t("cross-table.automatic-rules"),
                    cells: [
                        <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />,
                        <img key="infinite-bank-sync" src={iconInfinite} alt={t("cross-table.unlimited")} />,
                    ]
                }, {
                    isDivider: true,
                    title: t("cross-table.search"),
                }, {
                    title: t("cross-table.history"),
                    cells: [
                        t("cross-table.6-months"),
                        <img key="infinite-bank-sync" src={iconInfinite} alt={t("cross-table.unlimited")} />,
                    ]
                }, {
                    title: t("cross-table.export"),
                    cells: ['PDF', formatList(['PDF', 'CSV', 'JSON'], locale)]
                }, {
                    title: t("cross-table.import"),
                    cells: [t("cross-table.self-service"), t("cross-table.self-service")]
                }, {
                    isDivider: true,
                    title: t("cross-table.cash-flow"),
                }, {
                    title: t("cross-table.dashboards-reports-alerts"),
                    cells: [
                        <img key="infinite-bank-sync" src={iconInfinite} alt={t("cross-table.unlimited")} />,
                        <img key="infinite-bank-sync" src={iconInfinite} alt={t("cross-table.unlimited")} />
                    ]
                }, {
                    title: t("cross-table.channels"),
                    cells: [
                        formatList(['Email', 'Telegram'], locale),
                        formatList(['WhatsApp', 'Email', 'Telegram', 'SMS'], locale),
                    ]
                }, {
                    isDivider: true,
                    title: t("cross-table.concilation"),
                }, {
                    title: t("cross-table.invoices"),
                    cells: [
                        <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />,
                        <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />
                    ]
                }, {
                    title: t("cross-table.contacts"),
                    cells: [
                        <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />,
                        <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />
                    ]
                }, {
                    title: t("cross-table.invoices-quarter"),
                    cells: [
                        <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />,
                        <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />
                    ]
                }, {
                    isDivider: true,
                    title: t("cross-table.multi-users"),
                }, {
                    title: t("cross-table.users"),
                    cells: [
                        "1",
                        "2",
                    ]
                }, {
                    title: t("cross-table.workspaces"),
                    cells: [
                        "1",
                        "1"
                    ]
                }, {
                    isDivider: true,
                    title: t("cross-table.support"),
                }, {
                    title: t("cross-table.account-manager"),
                    cells: [
                        <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />,
                        <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />
                    ]
                }, {
                    title: t("cross-table.onboarding"),
                    cells: [
                        <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />,
                        <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />
                    ]
                }, {
                    title: t("cross-table.contact-methods"),
                    cells: ["Email", formatDisjunctiveList(["Chat", "Email"], locale)]
                }]
            }
        }
    }

    return priceConfig[plan]
}