import { useEffect, useId } from "react";
import { useGlobalFloating } from "Components/GlobalFloatingProvider";

export default function useDialogProps(props) {
    const { open } = props;
    const id = useId();
    const { addFloatingKey, removeFloatingKey } = useGlobalFloating();

    useEffect(() => {
        if (open) {
            addFloatingKey(id);

            return () => {
                removeFloatingKey(id);
            }
        }
    }, [open]);

    return props
}