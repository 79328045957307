
import PropTypes from "prop-types";
import React from "react";

const Travels = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="m12.415 9.19-10.14 7.322a3 3 0 0 0 2.408 5.36l8.534-1.896A1 1 0 0 0 14 19v-9a1 1 0 0 0-1.585-.81ZM12 11.954v6.242L4.249 19.92a1 1 0 0 1-.803-1.787L12 11.955ZM18 10v9a1 1 0 0 0 .783.976l8.534 1.897a3 3 0 0 0 2.408-5.36l-10.14-7.324A1 1 0 0 0 18 10Zm2 8.197v-6.241l8.554 6.177a1 1 0 0 1 .39 1.028l-.031.112a1 1 0 0 1-1.162.647L20 18.197Z"/>
		<path d="M15.8 2.005A4 4 0 0 0 12 6v17.696l-1.832 2.75A1 1 0 0 0 10 27v2l.006.116a1 1 0 0 0 1.365.812L16 28.077l4.629 1.851A1 1 0 0 0 22 29v-2l-.007-.118a1 1 0 0 0-.16-.437L20 23.697V6a4 4 0 0 0-4-4l-.2.005ZM16 4a2 2 0 0 1 2 2v18l.007.118a1 1 0 0 0 .16.437L20 27.304v.219l-3.629-1.451-.12-.04a1 1 0 0 0-.622.04L12 27.522v-.22l1.832-2.747A1 1 0 0 0 14 24V6a2 2 0 0 1 2-2Z"/>
	</g>

    </svg>
);

export default Travels;

Travels.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
