import React from "react";
import PropTypes from "prop-types";
import AutocompleteMultipleCategories from "Components/AutocompleteMultipleCategories";
import { useFormContext, Controller } from 'react-hook-form';
import useCategories from "Hooks/useCategories";

export default function RHFTagSelectMultiple({ name, ...other }) {
    const { control } = useFormContext();
    const { categories, isLoading } = useCategories();

    if (isLoading) return <div style={{ height: 56 }} />

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <AutocompleteMultipleCategories
                    {...field}
                    options={categories}
                    error={error?.message}
                    {...other}
                />
            )}
        />
    );
}

RHFTagSelectMultiple.propTypes = {
    name: PropTypes.string.isRequired,
};
