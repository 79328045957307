
import PropTypes from "prop-types";
import React from "react";

const Insurances = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M21.317 12.27a1 1 0 0 1 1.452 1.37l-.086.09-6.417 6a1 1 0 0 1-1.233.105l-.1-.076-4.084-3.5a1 1 0 0 1 1.201-1.594l.1.076 3.404 2.916 5.763-5.387Z"/>
		<path d="m16.152 2.063-10.5 3.9A1 1 0 0 0 5 6.9V16c0 3.612 1.959 6.906 5.217 9.846a28.499 28.499 0 0 0 3.675 2.791l.538.34.255.154.479.282.432.241.452.238c.282.143.615.144.906 0l.064-.033c.12-.062.247-.13.384-.204l.433-.242.48-.282.255-.155.538-.339a28.499 28.499 0 0 0 3.675-2.79C26.041 22.905 28 19.611 28 16V6.9a1 1 0 0 0-.652-.937l-10.5-3.9a1 1 0 0 0-.696 0Zm.348 2.003L26 7.595V16c0 2.941-1.679 5.763-4.557 8.361a26.524 26.524 0 0 1-3.416 2.594l-.5.315-.463.278-.419.24-.145.08-.147-.08c-.394-.221-.862-.5-1.38-.833a26.524 26.524 0 0 1-3.416-2.594l-.294-.27C8.565 21.57 7 18.84 7 16V7.595l9.5-3.529Z"/>
	</g>

    </svg>
);

export default Insurances;

Insurances.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
