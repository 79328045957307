
import PropTypes from "prop-types";
import React from "react";

const CircleAlert = ({ color, size, ...props }) => (
    <svg viewBox="0 0 28 28" width={size} height={size} {...props}>
        
	<path d="M15.166 15.167h-2.333v-7h2.333m0 11.666h-2.333V17.5h2.333M14 2.333a11.667 11.667 0 1 0 0 23.334 11.667 11.667 0 0 0 0-23.334Z" fill={color}/>

    </svg>
);

export default CircleAlert;

CircleAlert.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
