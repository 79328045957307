
import PropTypes from "prop-types";
import React from "react";

const ModuleChart = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="evenodd" transform="translate(1 2)">
		<rect height="13" width="5" rx="1" y="7"/>
		<rect height="16" width="5" rx="1" x="8" y="4"/>
		<rect height="20" width="5" rx="1" x="16"/>
	</g>

    </svg>
);

export default ModuleChart;

ModuleChart.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
