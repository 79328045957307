
import PropTypes from "prop-types";
import React from "react";

const RotateCcw = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M17.837 3.248A10 10 0 0 0 5.944 4.912L3 7.68V3.991a1 1 0 1 0-2 0v6a1.01 1.01 0 0 0 .189.585 1.007 1.007 0 0 0 .811.415h6a1 1 0 1 0 0-2H4.524l2.8-2.631.023-.021a8 8 0 1 1-1.894 8.32 1 1 0 0 0-1.886.664 10 10 0 1 0 14.27-12.075Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default RotateCcw;

RotateCcw.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
