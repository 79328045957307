
import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import InputField from 'Components/InputField';
import classes from './AutocompleteContacts.module.css';
import Icon from 'Components/Icon';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const normalizeString = (str = "") => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim();
}

const addButton = {
  id: '__add__',
  name: '',
}

const AutocompleteContacts = forwardRef(function AutocompleteContacts(props, ref) {
  const { options, label, onChange, onAdd, value, error, disabled, InputLabelProps } = props;

  const { t } = useTranslation("contacts");

  const valueFormatted = useMemo(() => options.find((option) => option.id === value), [value, options]);
  const optionsFormatted = useMemo(() => [addButton, ...options], [options]);

  return (
    <Autocomplete
      ref={ref}
      options={optionsFormatted}
      value={valueFormatted ?? null}
      disabled={disabled}
      filterOptions={(options, state) => {
        const inputValue = normalizeString(state.inputValue);
        return options
          .filter((option) => {
            const name = normalizeString(option.name);
            return name.includes(inputValue) || option.id === '__add__';
          });
      }}
      noOptionsText={t("no-contacts")}
      getOptionLabel={(option) => option?.name ?? ""}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      onChange={(_event, newValue) => {
        if (newValue?.id === '__add__') {
          onAdd?.();
          onChange(null);
          return;
        }
        if (newValue) {
          onChange(newValue.id);
        } else {
          onChange(null);
        }
      }}
      renderOption={(optionProps, option) => {
        if (option.id === '__add__') {
          return (
            <li {...optionProps} key={option.id} className={clsx(classes.add, optionProps.className)}>
              <div className={classes.addButton}>
                <Icon name="circle-plus" size={20} color='currentColor' />
                <div>
                  {t("add-contact")}
                </div>
              </div>
            </li>
          )
        }
        return (
          <li {...optionProps} key={option.id}>
            <div>
              <div className='text-small'><strong>{option.name}</strong></div>
              <div className='text-small opacity-50'>{option.description}</div>
            </div>
          </li>
        )
      }}
      renderInput={(params) => (
        <InputField {...params} InputLabelProps={InputLabelProps} label={label} error={error} />
      )}
    />
  )
});

AutocompleteContacts.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    description: PropTypes.string,
  })).isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  InputLabelProps: PropTypes.object,
  onAdd: PropTypes.func,
}

export default AutocompleteContacts;