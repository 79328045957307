
import PropTypes from "prop-types";
import React from "react";

const FamilyFriends = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M17 19a5 5 0 0 1 4.995 4.783L22 24v2a1 1 0 0 1-1.993.117L20 26v-2a3 3 0 0 0-2.824-2.995L17 21H9a3 3 0 0 0-2.995 2.824L6 24v2a1 1 0 0 1-1.993.117L4 26v-2a5 5 0 0 1 4.783-4.995L9 19h8ZM13 7a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm0 2a3 3 0 1 1 0 6 3 3 0 0 1 0-6ZM23.032 19.88a1 1 0 0 1 1.218-.718 5 5 0 0 1 3.745 4.611L28 24v2a1 1 0 0 1-1.993.117L26 26v-2a3 3 0 0 0-2.25-2.902 1 1 0 0 1-.718-1.218ZM19.031 7.882a1 1 0 0 1 1.217-.72 5 5 0 0 1 0 9.687 1 1 0 0 1-.496-1.938 3 3 0 0 0 0-5.812 1 1 0 0 1-.72-1.217Z"/>
	</g>

    </svg>
);

export default FamilyFriends;

FamilyFriends.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
