import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classes from './TextButton.module.css';
import clsx from 'clsx';

const TextButton = forwardRef((props, ref) => {
    const { className, children, href, target, isBold = false, disabled, component: Component, type = "button", color = "primary", size, ...sharedProps } = props;
    const buttonClassName = clsx(
        classes.textButton,
        {
            [classes.bold]: isBold,
            [classes.small]: size === "small",
        },
        className,
        classes[color]
    );

    if (Component) {
        return (
            <Component ref={ref} className={buttonClassName} {...sharedProps}>
                {children}
            </Component>
        )
    }

    if (href) {
        const defaultRel = target === "_blank" ? "noopener" : undefined;
        return (
            <a
                ref={ref}
                className={buttonClassName}
                href={href}
                target={target}
                rel={defaultRel}
                {...sharedProps}
            >
                {children}
            </a>
        );
    }

    return (
        <button ref={ref} type={type} className={buttonClassName} disabled={disabled} {...sharedProps}>
            {children}
        </button>
    );
});

export default TextButton;

TextButton.displayName = 'TextButton';

TextButton.propTypes = {
    isBold: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    href: PropTypes.string,
    target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
    children: PropTypes.node,
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    color: PropTypes.oneOf(['grey', 'primary', 'black']),
    size: PropTypes.oneOf(['small', 'medium']),
};