
import PropTypes from "prop-types";
import React from "react";

const Exit = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M9.667 4.25a.75.75 0 0 1 0 1.5H6.556a.806.806 0 0 0-.806.806v10.888c0 .445.36.806.806.806h3.11a.75.75 0 1 1 0 1.5h-3.11a2.306 2.306 0 0 1-2.306-2.306V6.556A2.306 2.306 0 0 1 6.556 4.25h3.11ZM14.58 7.58a.75.75 0 0 1 1.061 0l3.89 3.89a.75.75 0 0 1 0 1.06l-3.89 3.89a.75.75 0 0 1-1.06-1.061L17.939 12l-3.358-3.359a.75.75 0 0 1-.073-.976l.073-.084Z"/>
		<path d="M19 11.25a.75.75 0 1 1 0 1.5H9.667a.75.75 0 1 1 0-1.5H19Z"/>
	</g>

    </svg>
);

export default Exit;

Exit.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
