import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import classes from "./ButtonAction.module.css";
import { Check, Cancel, Skip } from "./ActionIcons";

const icons = {
    green: Check,
    red: Cancel,
    grey: Skip,
}
export default function ButtonAction(props) {
    const { onClick, children, actived, className, disabled, variant } = props;

    const buttonClass = clsx(
        classes.button,
        variant && classes[variant],
        actived && classes.actived,
        className,
    );

    const Icon = icons[variant];
    return (
        <button type="button" className={buttonClass} onClick={onClick} disabled={disabled}>
            <Icon />
            {children}
        </button>
    )
}

ButtonAction.propTypes = {
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    actived: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    variant: PropTypes.oneOf(["green", "red", "grey"]),
}