import { rest } from 'msw'
import { API_HOST } from 'config'
import invoicesParties from "./fixtures/invoicesParties.json"
import invoices from "./fixtures/invoices.json"
import InvoicesQuota from './fixtures/invoicesQuota.json'

const invoicesHandlers = [
    rest.get(`${API_HOST}/invoices/parties`, (req, res, ctx) => {
        return res(
            ctx.json(invoicesParties),
        )
    }),

    rest.get(`${API_HOST}/invoices`, (req, res, ctx) => {
        return res(
            ctx.json(invoices),
        )
    }),

    rest.get(`${API_HOST}/invoices/quotas`, (req, res, ctx) => {
        return res(
            ctx.json(InvoicesQuota),
        )
    })
]

export default invoicesHandlers