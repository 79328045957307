
import PropTypes from "prop-types";
import React from "react";

const NavInvoices = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M4.17 4.8A2.63 2.63 0 0 1 6.8 2.17H14a.83.83 0 0 1 .587.243l5.4 5.4a.83.83 0 0 1 .243.587v10.8a2.63 2.63 0 0 1-2.63 2.63H6.8a2.63 2.63 0 0 1-2.63-2.63V4.8Zm2.63-.97a.97.97 0 0 0-.97.97v14.4c0 .536.434.97.97.97h10.8a.97.97 0 0 0 .97-.97V8.744L13.656 3.83H6.8Z" fill={color} fillRule="evenodd"/>
	<path d="M9.5 7.3a.2.2 0 0 0-.2.2v1c0 .11.089.2.2.2h2a.2.2 0 0 0 .2-.2v-1a.2.2 0 0 0-.2-.2h-2Zm-1.8.2a1.8 1.8 0 0 1 1.8-1.8h2a1.8 1.8 0 0 1 1.8 1.8v1a1.8 1.8 0 0 1-1.8 1.8h-2a1.8 1.8 0 0 1-1.8-1.8v-1ZM7.77 12.9a.83.83 0 0 1 .83-.83h3.2a.83.83 0 1 1 0 1.66H8.6a.83.83 0 0 1-.83-.83ZM13.77 12.9a.83.83 0 0 1 .83-.83h1.2a.83.83 0 1 1 0 1.66h-1.2a.83.83 0 0 1-.83-.83ZM13.77 16.5a.83.83 0 0 1 .83-.83h1.2a.83.83 0 1 1 0 1.66h-1.2a.83.83 0 0 1-.83-.83ZM7.77 16.5a.83.83 0 0 1 .83-.83h3.2a.83.83 0 1 1 0 1.66H8.6a.83.83 0 0 1-.83-.83Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default NavInvoices;

NavInvoices.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
