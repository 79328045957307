import "./InputSelectSimple.css";
import { MenuItem, TextField } from "@mui/material";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { styled } from '@mui/material/styles';
import SubscriptionBadgeRequired from "Components/SubscriptionBadgeRequired";

const TextFieldStyled = styled(TextField)({
    '& *': {
        fontFamily: 'var(--f-family-primary)!important'
    },
    '& div input': {
        background: 'white'
    },
    '& div.Mui-focused input': {
        color: '#2E16BA'
    },
    '& label.Mui-focused': {
        color: '#2E16BA'
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#2E16BA',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#DDDDDD',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#2E16BA',
        },
    }
});

const MenuItemStyled = styled(MenuItem)({
    fontFamily: 'var(--f-family-primary)'
});

const InputSelectSimple = forwardRef((props, ref) => {
    const { options, ...other } = props;
    return (
        <TextFieldStyled
            ref={ref}
            select
            fullWidth
            {...other}
        >
            {options.map(item => (
                <MenuItemStyled key={item.id} value={item.id}>
                    {item.label}
                    {item.subscription && (<SubscriptionBadgeRequired type={item.subscription} />)}
                </MenuItemStyled>
            ))}
        </TextFieldStyled>
    )
})

InputSelectSimple.displayName = 'InputSelectSimple';

InputSelectSimple.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            label: PropTypes.string.isRequired,
            subscription: PropTypes.oneOf(["personal", "business"])
        }),
    ).isRequired,
    onChange: PropTypes.func,
    label: PropTypes.string,
};

export default InputSelectSimple;