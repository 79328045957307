
import PropTypes from "prop-types";
import React from "react";

const GearBig = ({ color, size, ...props }) => (
    <svg viewBox="0 0 25 25" width={size} height={size} {...props}>
        
	<g clipPath="url(#a)" fill={color} fillRule="evenodd">
		<path d="M12.5 10.363a2.136 2.136 0 1 0 0 4.273 2.136 2.136 0 0 0 0-4.273ZM8.363 12.5a4.136 4.136 0 1 1 8.273 0 4.136 4.136 0 0 1-8.273 0Z"/>
		<path d="M12.5 2a1.09 1.09 0 0 0-1.09 1.09v.183a2.725 2.725 0 0 1-1.652 2.493 1 1 0 0 1-.27.073 2.725 2.725 0 0 1-2.81-.622L6.67 5.21l-.063-.062a1.092 1.092 0 0 0-1.544 0 1.09 1.09 0 0 0 0 1.544l.07.07c.772.79.991 1.965.558 2.977a2.725 2.725 0 0 1-2.483 1.755H3.091a1.09 1.09 0 1 0 0 2.181h.182a2.725 2.725 0 0 1 2.491 1.647 2.725 2.725 0 0 1-.547 3.001l-.008.008-.062.063a1.092 1.092 0 0 0 0 1.544 1.091 1.091 0 0 0 1.544 0l.07-.07a2.725 2.725 0 0 1 2.977-.558 2.725 2.725 0 0 1 1.755 2.483V21.909a1.09 1.09 0 1 0 2.181 0v-.182a2.725 2.725 0 0 1 1.647-2.491 2.725 2.725 0 0 1 3.001.547l.008.008.063.062a1.092 1.092 0 0 0 1.544 0 1.091 1.091 0 0 0 0-1.544l-.07-.07a2.725 2.725 0 0 1-.548-3.001 2.725 2.725 0 0 1 2.492-1.647h.098a1.09 1.09 0 1 0 0-2.182h-.182a2.725 2.725 0 0 1-2.493-1.651 1 1 0 0 1-.073-.27 2.725 2.725 0 0 1 .622-2.81l.008-.008.062-.063a1.092 1.092 0 0 0 0-1.544 1.091 1.091 0 0 0-1.544 0l-.07.07a2.725 2.725 0 0 1-3.002.548 2.725 2.725 0 0 1-1.646-2.492v-.098A1.09 1.09 0 0 0 12.5 2ZM9.41 3.09a3.09 3.09 0 1 1 6.18 0v.093a.725.725 0 0 0 .44.662l.01.004c.27.119.585.063.797-.142l.057-.058a3.091 3.091 0 1 1 4.374 4.373l-.058.058a.725.725 0 0 0-.142.796c.029.067.051.137.065.208.132.2.356.324.6.325h.176a3.09 3.09 0 1 1 0 6.182h-.091a.725.725 0 0 0-.663.44l-.004.01a.725.725 0 0 0 .142.796l.058.057a3.092 3.092 0 0 1 0 4.374l-.707-.707.708.706a3.091 3.091 0 0 1-4.374 0l-.058-.058a.725.725 0 0 0-.796-.141l-.01.004a.725.725 0 0 0-.44.662v.175a3.09 3.09 0 1 1-6.181 0v-.08a.725.725 0 0 0-.475-.654 1.027 1.027 0 0 1-.058-.024.725.725 0 0 0-.797.142l-.057.058a3.092 3.092 0 1 1-4.374-4.373l.058-.058a.725.725 0 0 0 .142-.796l-.004-.01a.725.725 0 0 0-.662-.44h-.175a3.09 3.09 0 1 1 0-6.181h.08a.725.725 0 0 0 .654-.475c.007-.02.015-.039.024-.058a.725.725 0 0 0-.142-.797l-.058-.057a3.09 3.09 0 1 1 4.373-4.374l.058.058a.725.725 0 0 0 .796.142 1 1 0 0 1 .208-.065.725.725 0 0 0 .325-.6V3.09Z"/>
	</g>
	<defs>
		<clipPath id="a">
			<path d="M0 0h25v25H0z" fill={color}/>
		</clipPath>
	</defs>

    </svg>
);

export default GearBig;

GearBig.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
