import { useMemo } from "react";
import useProviders from "Hooks/useProviders";

export default function useCountryProviders(country) {
    const { providers, availableProviders, isLoading } = useProviders()

    const providersForCountry = useMemo(() => {
        return providers.filter(provider => !provider.country_code || provider.country_code === country);
    }, [country, providers]);

    const availableProvidersForCountry = useMemo(() => {
        return availableProviders.filter(provider => !provider.country_code || provider.country_code === country);
    }, [country, availableProviders]);

    return {
        isLoading,
        providers: providersForCountry,
        availableProviders: availableProvidersForCountry
    }
}