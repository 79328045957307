
import PropTypes from "prop-types";
import React from "react";

const Gear = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M12.73 23h-1.46C10.02 23 9 21.98 9 20.73c0-.14-.12-.29-.3-.35l-.3-.12c-.16-.07-.36-.06-.46.04-.86.86-2.35.86-3.21 0l-1.04-1.04c-.43-.43-.66-1-.66-1.6 0-.6.24-1.17.66-1.6.1-.1.12-.29.04-.47l-.12-.28c-.07-.18-.22-.31-.36-.31C2 15 .98 13.98.98 12.73v-1.46C.98 10.02 2 9 3.25 9c.14 0 .29-.12.35-.3.04-.1.08-.19.12-.29.08-.17.06-.36-.04-.46a2.271 2.271 0 0 1 0-3.2l1.03-1.03c.86-.86 2.35-.86 3.21 0 .1.1.29.11.47.04l.28-.12c.18-.07.31-.22.31-.36 0-1.25 1.02-2.27 2.27-2.27h1.46c1.25 0 2.27 1.02 2.27 2.27 0 .14.12.29.3.36.1.04.2.08.29.12.17.08.36.06.46-.04.85-.85 2.36-.85 3.21 0l1.03 1.03c.43.43.66 1 .66 1.6 0 .6-.24 1.18-.67 1.6-.1.1-.11.29-.04.47l.12.28c.07.18.22.31.36.31 1.25 0 2.27 1.02 2.27 2.27v1.46c0 1.25-1.02 2.27-2.27 2.27-.14 0-.29.12-.35.3-.04.1-.08.2-.12.29-.07.17-.06.36.04.46a2.271 2.271 0 0 1 0 3.2l-1.03 1.03c-.21.21-.45.37-.73.49a2.28 2.28 0 0 1-2.48-.49c-.1-.1-.29-.12-.46-.04l-.3.12c-.16.07-.29.21-.29.35 0 1.25-1.02 2.27-2.27 2.27l.02.01Zm-4.54-4.79c.34 0 .69.07 1.02.22l.23.1c.95.37 1.56 1.24 1.56 2.21 0 .15.12.27.27.27h1.46c.15 0 .27-.12.27-.27 0-.97.61-1.84 1.57-2.22.08-.03.15-.06.22-.09.94-.41 1.99-.23 2.68.46.03.03.06.04.09.06.06.03.14.03.21 0 .03-.01.06-.03.08-.05l1.04-1.04c.05-.05.08-.12.08-.19s-.03-.14-.08-.19c-.69-.69-.87-1.74-.46-2.68l.1-.23c.37-.95 1.24-1.56 2.21-1.56.15 0 .27-.12.27-.27v-1.46c0-.15-.12-.27-.27-.27-.97 0-1.84-.62-2.22-1.57l-.09-.22c-.41-.94-.23-1.99.46-2.68.05-.05.08-.12.08-.19s-.03-.14-.08-.19l-1.04-1.04c-.1-.1-.28-.1-.38 0-.69.69-1.74.87-2.68.46l-.23-.1C13.61 5.11 13 4.24 13 3.27c0-.15-.12-.27-.27-.27h-1.46c-.15 0-.27.12-.27.27 0 .97-.62 1.84-1.57 2.22l-.22.09c-.94.4-1.99.23-2.67-.46-.1-.1-.28-.1-.38 0L5.13 6.15c-.05.05-.08.12-.08.19s.03.14.08.19c.69.69.87 1.74.46 2.68l-.09.22C5.13 10.38 4.26 11 3.28 11c-.15 0-.27.12-.27.27v1.46c0 .15.12.27.27.27.97 0 1.84.62 2.22 1.57l.09.21c.41.95.23 2-.45 2.69-.05.05-.08.12-.08.19s.03.14.08.19l1.03 1.03c.1.1.28.1.38 0a2.33 2.33 0 0 1 1.65-.67h-.01Z" fill={color}/>
	<path d="M12 17a5.01 5.01 0 0 1-3.54-1.46C7.52 14.6 7 13.34 7 12c0-1.34.52-2.59 1.46-3.54C9.4 7.52 10.66 7 12 7c1.34 0 2.59.52 3.54 1.46.94.94 1.46 2.2 1.46 3.54 0 1.34-.52 2.59-1.46 3.54-.94.95-2.2 1.46-3.54 1.46Zm0-8c-.8 0-1.55.31-2.12.88C9.32 10.44 9 11.21 9 12s.31 1.56.88 2.12c1.13 1.12 3.12 1.12 4.24 0a2.997 2.997 0 0 0 0-4.24C13.55 9.31 12.8 9 12 9Z" fill={color}/>

    </svg>
);

export default Gear;

Gear.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
