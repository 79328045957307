import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "Utils/constants";
import { useUser } from "Components/UserProvider/useUser";
import useProjectSelected from "Hooks/useProjectSelected";
import Skeleton from "Components/Skeleton";
import classes from "./AppLayoutProfileMenu.module.css";
import clsx from "clsx";
import Icon from "Components/Icon";
import {
    FloatingFocusManager,
    autoUpdate,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useRole,
} from "@floating-ui/react";
import ChangeWorkspaceButton from "./ChangeWorkspaceButton";
import AvatarUser from "Components/AvatarUser";
import { useTranslation } from "react-i18next";

export default function AppLayoutProfileMenu(props) {
    const { className } = props;
    const { logout } = useUser();
    const navigate = useNavigate();
    const { project, isLoading } = useProjectSelected();
    const [expanded, setExpanded] = useState(false);
    const { t } = useTranslation("common");

    const name = project.name || "";

    const handleToggle = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    }


    const { x, y, refs, strategy, context } = useFloating({
        whileElementsMounted: autoUpdate,
        open: expanded,
        placement: "bottom-end",
        onOpenChange: setExpanded,
        strategy: "absolute"
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        useClick(context),
        useRole(context, { role: "menu" }),
        useDismiss(context)
    ]);

    const menuItems = [{
        icon: "user",
        children: t("edit-profile"),
        onClick: () => {
            navigate(ROUTES.profile)
            setExpanded(false)
        }
    }, {
        icon: "coins",
        children: t("invite-and-earn"),
        onClick: () => {
            navigate(ROUTES.affiliates)
            setExpanded(false)
        }
    }, {
        icon: "gear",
        children: t("settings"),
        onClick: () => {
            navigate(ROUTES.settings);
            setExpanded(false)
        }
    }, {
        icon: "exit",
        children: t("logout"),
        onClick: () => logout()
    }]

    return (
        <div className={clsx(classes.root, className)}>
            <button onClick={handleToggle} ref={refs.setReference} type="button" className={clsx(classes.handle, expanded && classes.expanded)} {...getReferenceProps()}>
                {isLoading ? (
                    <Skeleton variant="rounded" width={38} height={38} sx={{ borderRadius: 999 }} />
                ) : (
                    <AvatarUser name={name} size={38} />
                )}

                <div className={classes.name}>
                    {isLoading ? (
                        <Skeleton variant="text" width={96} />
                    ) : (
                        <>{name}</>
                    )}
                </div>

                <Icon name="chevron-down" className="flex-shrink-0" size={20} color="currentColor" />
            </button>
            {expanded && (
                <FloatingFocusManager context={context}>
                    <div className={classes.dropdown} {...getFloatingProps({
                        ref: refs.setFloating,
                        style: {
                            position: strategy,
                            top: y ?? 0,
                            left: x ?? 0,
                            zIndex: "var(--z-index-dropdown)",
                        }
                    })}>
                        <div className={classes.menu}>
                            <div className={clsx(classes.divider, classes.showInLargeDropdown)} />

                            <ChangeWorkspaceButton />

                            <div className={clsx(classes.divider, "mb-1")} />
                            {menuItems.map((item, index) => (
                                <button key={index} className={classes.menuItem} onClick={item.onClick}>
                                    <Icon name={item.icon} className={classes.menuItemIcon} size={18} color="currentColor" />
                                    <div className={classes.menuItemText}>
                                        {item.children}
                                    </div>
                                </button>
                            ))}
                        </div>
                    </div>
                </FloatingFocusManager>
            )}
        </div>
    )
}

AppLayoutProfileMenu.propTypes = {
    className: PropTypes.string,
};