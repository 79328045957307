
import PropTypes from "prop-types";
import React from "react";

const Euro = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<g clipPath="url(#a)">
		<path d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Zm-18.016.967v1.04H7.47c.297 1.276.908 2.293 1.832 3.053.924.759 2.084 1.138 3.481 1.138 1.331 0 2.41-.314 3.234-.94.825-.627 1.353-1.496 1.584-2.607h-1.534c-.176.704-.534 1.267-1.073 1.69-.539.424-1.276.636-2.21.636-.969 0-1.788-.261-2.46-.784-.67-.522-1.138-1.251-1.402-2.186h4.191v-1.04H8.74a8.393 8.393 0 0 1 0-1.502h4.372v-1.022H8.938c.253-.946.715-1.678 1.386-2.195.67-.517 1.49-.775 2.458-.775.935 0 1.672.217 2.211.652.54.434.897 1.009 1.073 1.724H17.6c-.23-1.133-.759-2.016-1.584-2.648-.825-.633-1.903-.949-3.234-.949-1.386 0-2.54.38-3.465 1.138-.924.76-1.534 1.777-1.831 3.053H5.984v1.023h1.337a8.753 8.753 0 0 0 0 1.502H5.984Z" fill={color} fillRule="evenodd"/>
	</g>
	<defs>
		<clipPath id="a">
			<path d="M0 0h24v24H0z" fill={color}/>
		</clipPath>
	</defs>

    </svg>
);

export default Euro;

Euro.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
