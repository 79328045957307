
import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'Components/Dialog';
import ProviderConnectionConnectDialogContent from './ProviderConnectionConnectDialogContent';

export default function ProviderConnectionConnectDialog(props) {
    const { open, onClose, providerConnection, onConnect } = props;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            size="sm"
        >
            <ProviderConnectionConnectDialogContent
                providerConnection={providerConnection}
                onConnect={onConnect}
                onCancel={onClose}
            />
        </Dialog>
    );
}

ProviderConnectionConnectDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConnect: PropTypes.func.isRequired,
    providerConnection: PropTypes.object
}