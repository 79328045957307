
import PropTypes from "prop-types";
import React from "react";

const Health = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M10.551 9.684c.282-.844 1.421-.915 1.825-.168l.052.113 3.437 8.59 2.928-2.926a1 1 0 0 1 .576-.284L19.5 15H31a1 1 0 0 1 .117 1.993L31 17H19.914l-3.707 3.707a1 1 0 0 1-1.582-.223l-.053-.113-2.987-7.465-1.136 3.41a1 1 0 0 1-.833.677L9.5 17H1a1 1 0 0 1-.117-1.993L1 15h7.779l1.772-5.316Z"/>
		<path d="M21.316 5.006a7.148 7.148 0 0 0-4.728 2.05l-.476.469-.475-.468c-2.776-2.742-7.273-2.742-10.05 0a6.98 6.98 0 0 0 0 9.957l9.823 9.698a1 1 0 0 0 1.405 0l9.822-9.698a6.995 6.995 0 0 0 2.089-4.979c0-1.868-.752-3.66-2.089-4.979A7.15 7.15 0 0 0 21.613 5l-.297.006ZM21.613 7c1.358 0 2.66.533 3.619 1.48a4.995 4.995 0 0 1 1.494 3.555 4.995 4.995 0 0 1-1.494 3.556l-9.12 9.003-9.12-9.003a4.98 4.98 0 0 1 0-7.111c1.999-1.972 5.243-1.972 7.24 0l1.178 1.163a1 1 0 0 0 1.405 0l1.178-1.163A5.151 5.151 0 0 1 21.613 7Z"/>
	</g>

    </svg>
);

export default Health;

Health.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
