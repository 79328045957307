import React, { forwardRef, useId } from "react";
import PropTypes from "prop-types";
import styles from './TextFieldBase.module.css';
import clsx from 'clsx';

const TextfieldBase = forwardRef((props, ref) => {
  const {
    component: Component = "input",
    className,
    label,
    id,
    endAdornment = null,
    startAdornment = null,
    size = "medium",
    helperText,
    classes = {},
    ...htmlProps
  } = props;

  const defaultId = useId();
  const inputId = id || defaultId;

  const inputClasses = clsx(
    styles.input,
    classes.input,
    {
      [styles.endAdornmentSpace]: endAdornment,
      [styles.startAdornmentSpace]: startAdornment,
    },
  );

  return (
    <div className={clsx({ [styles.large]: size === "large" }, className)}>
      {label && (
        <label className={styles.label} htmlFor={inputId}>
          {label}
        </label>
      )}

      <div className={styles.inputWrap}>
        {startAdornment}

        <Component
          ref={ref}
          id={inputId}
          className={inputClasses}
          aria-describedby={helperText && `${defaultId}-helper-text`}
          {...htmlProps} />

        {endAdornment}
      </div>

      {helperText && (
        <div className={clsx(styles.helperText, classes.helperText)} id={`${defaultId}-helper-text`}>
          {helperText}
        </div>
      )}
    </div>
  );
});

export default TextfieldBase;

TextfieldBase.displayName = "TextfieldBase";

TextfieldBase.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  onChange: PropTypes.func,
  multiline: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  id: PropTypes.string,
  endAdornment: PropTypes.node,
  startAdornment: PropTypes.node,
  helperText: PropTypes.node,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(["medium", "large"]),
  multiple: PropTypes.bool,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  classes: PropTypes.object
};
