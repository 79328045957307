import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getCategoriesSuggestions, applyCategorySuggestion } from "Services/transactions";
import { trackError } from "Utils/errorMonitoring";
import { useSearch } from "Components/SearchProvider";

export default function useCategoriesSuggestions() {
    const { data = [], isLoading } = useQuery(["categories-suggestions"], getCategoriesSuggestions, {
        onError: error => trackError(error),
    })
    const queryClient = useQueryClient();
    const { searchParams } = useSearch();

    const { mutate: applysuggestion } = useMutation(applyCategorySuggestion, {
        onMutate: ({ id, action, categoryId, transactionId }) => {
            if (action === "accept") {
                queryClient.setQueryData(["transactions", transactionId], old => {
                    if (!old) return old;

                    return {
                        ...old,
                        tags_ids: [...old.tags_ids, categoryId]
                    }
                });

                queryClient.setQueryData(["transactions", searchParams], old => {
                    if (!old) return old;

                    return {
                        ...old,
                        pages: old.pages.map(page => {
                            return {
                                ...page,
                                items: page.items.map(transaction => {
                                    if (transaction.id === transactionId) {
                                        return {
                                            ...transaction,
                                            tags_ids: [...transaction.tags_ids, categoryId]
                                        }
                                    }
                                    return transaction
                                })
                            }
                        })
                    }
                });
            }

            queryClient.setQueryData(["categories-suggestions"], (previousData) => {
                return previousData.filter(suggestion => suggestion.id !== id);
            });
        },
    })

    return {
        categoriesSuggestions: data,
        applysuggestion,
        isLoading
    }
}