import PropTypes from 'prop-types';
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import InputSelectSimple from 'Components/InputSelectSimple';

const options = [{
    id: "income",
    label: "Ingreso",
}, {
    id: "spending",
    label: "Gasto",
}];

export default function RHFTransactionTypeSelect({ name, helperText, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <InputSelectSimple
                    {...field}
                    value={field.value[0]}
                    onChange={(e) => field.onChange([e.target.value])}
                    error={!!error}
                    helperText={error ? error?.message : helperText}
                    options={options}
                    {...other}
                />
            )}
        />
    );
}

RHFTransactionTypeSelect.propTypes = {
    name: PropTypes.string,
    helperText: PropTypes.node,
};
