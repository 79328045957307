
import PropTypes from "prop-types";
import React from "react";

const Beauty = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M12.127 8h-.103a7.327 7.327 0 0 0-4.968 1.928l-4.6 4.224a1.408 1.408 0 0 0-.168 1.891l2.186 2.869a11.946 11.946 0 0 0 4.571 3.625C11.163 23.5 13.585 24 16 24c2.421 0 4.842-.503 6.991-1.48a11.907 11.907 0 0 0 4.535-3.609l2.185-2.867c.442-.58.37-1.399-.168-1.892l-4.598-4.224A7.328 7.328 0 0 0 19.975 8h-.102c-.83 0-1.66.193-2.405.558l-.184.098-.276.162-.517.311-.182.1-.222.107-.087.035-.087-.035-.135-.062-.184-.096-.335-.197-.327-.2-.215-.125-.185-.097A5.48 5.48 0 0 0 12.127 8Zm-.103 2h.103a3.48 3.48 0 0 1 1.523.354l.104.055.65.391.221.127c.518.287.93.444 1.375.444.444 0 .857-.157 1.375-.444l.222-.127.65-.391.103-.056c.47-.23.998-.353 1.523-.353h.103l.269.007a5.321 5.321 0 0 1 3.347 1.394l4.202 3.86-1.858 2.438a9.907 9.907 0 0 1-3.772 3C20.28 21.556 18.14 22 16 22c-2.136 0-4.276-.443-6.128-1.284l-.346-.165a9.918 9.918 0 0 1-3.461-2.852l-1.859-2.438 4.202-3.86A5.327 5.327 0 0 1 12.024 10Z"/>
		<path d="m11.904 14.858 1.053.337c1.044.324 1.93.483 3.043.483.933 0 1.678-.115 2.545-.357l.576-.174.607-.192c.321-.098.576-.166.829-.216l.148-.028.327-.054.369-.053.41-.05.452-.047.756-.068.556-.042.913-.06.662-.037 1.072-.052 1.578-.063.853-.028a1 1 0 1 1 .062 2l-1.996.071-1.734.084-1.197.075-.535.04-.722.063-.428.044-.385.046-.342.047-.298.049-.133.025c-.07.014-.143.03-.222.05l-.26.072-.95.297c-1.213.374-2.223.558-3.513.558-1.188 0-2.17-.153-3.232-.453l-.478-.143-.823-.266-.24-.072-.197-.053-.15-.031-.193-.032-.239-.032-.282-.033-.696-.066-1.113-.085-1.38-.085-2-.103-1.54-.068a1 1 0 1 1 .084-1.998l1.977.088 1.701.09 1.165.072.992.073.633.056.538.056.445.057.351.059.184.04.258.068.14.041Z"/>
	</g>

    </svg>
);

export default Beauty;

Beauty.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
