import React from "react";
import PropTypes from "prop-types";

export default function SketchyArrow({ className }) {
    return (
        <svg
            width="22"
            height="19"
            className={className}
            viewBox="0 0 22 19"
        >
            <path
                fill="currentColor"
                d="M.725 0c9.03.01 16.341 7.4 16.333 16.506l3.446-3.476a.72.72 0 01.999.027c.273.276.283.72.023 1.008l-4.681 4.72a.722.722 0 01-1.026 0l-4.681-4.72a.736.736 0 010-1.035.72.72 0 011.025 0l3.446 3.476C15.617 8.206 8.954 1.472.725 1.462A.728.728 0 010 .73C0 .327.324 0 .725 0z"
            ></path>
        </svg>
    )
}

SketchyArrow.propTypes = {
    className: PropTypes.string,
};