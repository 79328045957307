
import PropTypes from "prop-types";
import React from "react";

const Shopping = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M23.829 27A3.001 3.001 0 0 1 21 29H7a3 3 0 0 1-3-3V10a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v1h-2v-.001H6V26a1 1 0 0 0 .883.993L7 27Z"/>
		<path d="M26 9H8a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h16a3 3 0 0 0 3-3V10a1 1 0 0 0-1-1Zm-1 1.999V26l-.007.117A1 1 0 0 1 24 27l-15-.001v-16h16Z"/>
		<path d="M13 13a1 1 0 0 1-.993-.883L12 12V9a5 5 0 0 1 9.995-.217L22 9v3a1 1 0 0 1-1.993.117L20 12V9a3 3 0 0 0-5.995-.176L14 9v3a1 1 0 0 1-1 1Z"/>
		<path d="M9 10a1 1 0 0 1-1-1 5 5 0 0 1 10 0 1 1 0 1 1-2 0 3 3 0 0 0-5.995-.176L10 9a1 1 0 0 1-1 1Z"/>
	</g>

    </svg>
);

export default Shopping;

Shopping.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
