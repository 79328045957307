
import PropTypes from "prop-types";
import React from "react";

const SquareCheck = ({ color, size, ...props }) => (
    <svg viewBox="0 0 16 16" width={size} height={size} {...props}>
        
	<path d="M5 2.75A2.25 2.25 0 0 0 2.75 5v6A2.25 2.25 0 0 0 5 13.25h6A2.25 2.25 0 0 0 13.25 11V5A2.25 2.25 0 0 0 11 2.75H5ZM1.25 5A3.75 3.75 0 0 1 5 1.25h6A3.75 3.75 0 0 1 14.75 5v6A3.75 3.75 0 0 1 11 14.75H5A3.75 3.75 0 0 1 1.25 11V5Z" fill={color} fillRule="evenodd"/>
	<path d="M11.53 5.47a.75.75 0 0 1 0 1.06l-4 4a.75.75 0 0 1-1.06 0l-1.5-1.5a.75.75 0 0 1 1.06-1.06l.97.97 3.47-3.47a.75.75 0 0 1 1.06 0Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default SquareCheck;

SquareCheck.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
