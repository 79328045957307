
import PropTypes from "prop-types";
import React from "react";

const Time = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1Zm0 2a9 9 0 1 1 0 18 9 9 0 0 1 0-18Z" fill={color}/>
	<path d="M12 5a1 1 0 0 1 1 1v5.382l3.447 1.724a1 1 0 0 1 .494 1.234l-.047.107a1 1 0 0 1-1.341.447l-4-2A1 1 0 0 1 11 12V6a1 1 0 0 1 1-1Z" fill={color}/>

    </svg>
);

export default Time;

Time.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
