import { useQuery } from "@tanstack/react-query";
import { getProviders } from "Services/providers";
import { trackError } from "Utils/errorMonitoring";
import { useMemo } from "react";

export default function useProviders() {
    const { data: providers = [], isLoading } = useQuery(["providers"], getProviders, {
        onError: error => trackError(error),
    });

    const availableProviders = useMemo(() => {
        return providers.map(item => ({
            ...item,
            connectors: item.connectors.filter(item => item.is_enabled)
        })).filter(item => item.is_enabled && item.connectors.length > 0)
    }, [providers]);

    const manualProvider = useMemo(() => providers.find((item) => item.connectors.find(connector => connector.service === 'manual'), [providers]));

    const fakeProvider = useMemo(() => providers.find((item) => item.connectors.find(connector => connector.service === 'fake_data'), [providers]));

    return {
        isLoading,
        manualProvider,
        fakeProvider,
        availableProviders,
        providers
    }
}