
import PropTypes from "prop-types";
import React from "react";

const Transportation = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M21.83 6a3.4 3.4 0 0 1 2.912 1.647l.103.183 2.5 4.803a1.4 1.4 0 0 0 .712.65l.133.046 1.371.404a3.4 3.4 0 0 1 2.433 3.066l.006.196V20.6a3.4 3.4 0 0 1-3.213 3.395L28.6 24h-1.567v-2H28.6a1.4 1.4 0 0 0 1.394-1.265L30 20.6v-3.605a1.4 1.4 0 0 0-.88-1.3l-.124-.043-1.37-.404a3.4 3.4 0 0 1-1.952-1.507l-.104-.185-2.499-4.802a1.4 1.4 0 0 0-1.1-.747L21.828 8H12.6a1.4 1.4 0 0 0-1.17.632l-.071.122-2.543 4.885a3.4 3.4 0 0 1-1.643 1.541l-.195.08-4.062 1.499a1.4 1.4 0 0 0-.909 1.18L2 18.071V20.6a1.4 1.4 0 0 0 1.265 1.394L3.4 22h.625v2H3.4a3.4 3.4 0 0 1-3.395-3.213L0 20.6v-2.528a3.4 3.4 0 0 1 2.043-3.117l.18-.072 4.06-1.5a1.4 1.4 0 0 0 .683-.54l.075-.127L9.583 7.83a3.4 3.4 0 0 1 2.806-1.824L12.6 6h9.23Zm-1.805 16v2h-8.004v-2h8.004Z"/>
		<path d="M8.286 17.8c-2.562 0-4.643 2.057-4.643 4.6 0 2.543 2.081 4.6 4.643 4.6 2.561 0 4.643-2.057 4.643-4.6 0-2.543-2.082-4.6-4.643-4.6Zm0 2c1.462 0 2.643 1.167 2.643 2.6S9.748 25 8.286 25c-1.463 0-2.643-1.167-2.643-2.6s1.18-2.6 2.643-2.6ZM23.714 17.8c-2.561 0-4.643 2.057-4.643 4.6 0 2.543 2.082 4.6 4.643 4.6 2.562 0 4.643-2.057 4.643-4.6 0-2.543-2.081-4.6-4.643-4.6Zm0 2c1.463 0 2.643 1.167 2.643 2.6s-1.18 2.6-2.643 2.6c-1.462 0-2.643-1.167-2.643-2.6s1.181-2.6 2.643-2.6ZM20.382 9h-6.764a2 2 0 0 0-1.789 1.106l-1.362 2.723A1.5 1.5 0 0 0 11.81 15h10.382a1.5 1.5 0 0 0 1.342-2.17l-1.362-2.724A2 2 0 0 0 20.382 9Zm-.001 2 1 2h-8.763l1-2h6.763Z"/>
	</g>

    </svg>
);

export default Transportation;

Transportation.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
