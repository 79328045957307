
import PropTypes from "prop-types";
import React from "react";

const Description2 = ({ color, size, ...props }) => (
    <svg viewBox="0 0 16 16" width={size} height={size} {...props}>
        
	<path d="M.25 8A.75.75 0 0 1 1 7.25h13a.75.75 0 0 1 0 1.5H1A.75.75 0 0 1 .25 8ZM.25 4A.75.75 0 0 1 1 3.25h13a.75.75 0 0 1 0 1.5H1A.75.75 0 0 1 .25 4ZM.25 12a.75.75 0 0 1 .75-.75h6.067a.75.75 0 0 1 0 1.5H1A.75.75 0 0 1 .25 12Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default Description2;

Description2.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
