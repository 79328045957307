
import PropTypes from "prop-types";
import React from "react";

const Code = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M4.06 14.26c-.192.492-.528.678-1.008.558-.36-.096-.597-.3-.711-.612-.114-.312-.015-.594.297-.846l1.476-1.224-1.692-.072c-.528-.012-.828-.234-.9-.666a.906.906 0 0 1 .36-.882c.264-.216.558-.228.882-.036l1.548.882-.468-1.656c-.108-.36-.072-.645.108-.855.18-.21.456-.321.828-.333.36-.024.624.069.792.279.168.21.186.513.054.909l-.504 1.656 1.53-.882c.432-.264.774-.21 1.026.162.216.336.24.672.072 1.008-.144.264-.39.402-.738.414l-1.638.072 1.458 1.278c.36.3.39.63.09.99-.252.324-.558.456-.918.396-.3-.036-.516-.228-.648-.576l-.612-1.656-.684 1.692ZM11.548 14.26c-.192.492-.528.678-1.008.558-.36-.096-.597-.3-.71-.612-.115-.312-.016-.594.296-.846l1.476-1.224-1.692-.072c-.528-.012-.828-.234-.9-.666a.906.906 0 0 1 .36-.882c.264-.216.558-.228.882-.036l1.548.882-.468-1.656c-.108-.36-.072-.645.108-.855.18-.21.456-.321.828-.333.36-.024.624.069.792.279.168.21.186.513.054.909l-.504 1.656 1.53-.882c.432-.264.774-.21 1.026.162.216.336.24.672.072 1.008-.144.264-.39.402-.738.414l-1.638.072 1.458 1.278c.36.3.39.63.09.99-.252.324-.558.456-.918.396-.3-.036-.516-.228-.648-.576l-.612-1.656-.684 1.692ZM19.037 14.26c-.192.492-.528.678-1.008.558-.36-.096-.597-.3-.711-.612-.114-.312-.015-.594.297-.846l1.476-1.224-1.692-.072c-.528-.012-.828-.234-.9-.666a.906.906 0 0 1 .36-.882c.264-.216.558-.228.882-.036l1.548.882-.468-1.656c-.108-.36-.072-.645.108-.855.18-.21.456-.321.828-.333.36-.024.624.069.792.279.168.21.186.513.054.909l-.504 1.656 1.53-.882c.432-.264.774-.21 1.026.162.216.336.24.672.072 1.008-.144.264-.39.402-.738.414l-1.638.072 1.458 1.278c.36.3.39.63.09.99-.252.324-.558.456-.918.396-.3-.036-.516-.228-.648-.576l-.612-1.656-.684 1.692Z" fill={color}/>

    </svg>
);

export default Code;

Code.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
