import { useEffect, useState } from 'react'
import { trackError } from 'Utils/errorMonitoring'

const getMatches = (query) => {
  if (typeof window !== 'undefined') {
    return window.matchMedia(query).matches
  }
  return false
}

function useMediaQuery(query) {

  const [matches, setMatches] = useState(getMatches(query))

  useEffect(() => {
    if (typeof window === 'undefined') return

    const matchMedia = window.matchMedia(query)
    const handleChange = () => setMatches(getMatches(query))
    try {
      matchMedia.addEventListener('change', handleChange)
    } catch (err) {
      try {
        // Safari older than 14.1
        matchMedia.addListener(handleChange)
      } catch (error) {
        trackError(error)
      }
    }

    handleChange()

    return () => {
      try {
        matchMedia.removeEventListener('change', handleChange)
      } catch (error) {
        try {
          // Safari older than 14.1
          matchMedia.removeListener(handleChange)
        } catch (error) {
          trackError(error)
        }
      }
    }
  }, [query])

  return matches
}

export default useMediaQuery