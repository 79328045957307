import React from "react";
import PropTypes from "prop-types";
import styles from "./PriceTable.module.css";
import clsx from "clsx";
import Button from "Components/Button";
import { getSubcriptionLink } from "Services/user";
import { useQuery } from "@tanstack/react-query";
import { trackError } from "Utils/errorMonitoring";
import { PricePlanTableIcons } from "./PricePlanTableIcons";
import { useTranslation } from "react-i18next";
import { splitPrice } from "Utils/formatters";

const MIN_5 = 5 * 60 * 1000;

const getIconUrl = ({ iconName, disabled }) => {
    const icon = PricePlanTableIcons[iconName];

    if (!icon) {
        return "";
    }

    return disabled ? icon.disabled : icon.default;
}

export default function PricePlanTable(props) {
    const { title, description, classes, price, iconList, id, periodicity, currentPlan } = props;

    const { t } = useTranslation("pricing");

    const { data, isLoading } = useQuery(["subcription-link", id, periodicity], () => getSubcriptionLink({ periodicity, plan: id }), {
        enabled: !!id,
        staleTime: MIN_5,
        onError: error => trackError(error),
    });

    const periodicityPrice = price ? price[periodicity] : 0;
    const hasPrice = !!periodicityPrice;
    const [value, cents] = splitPrice(periodicityPrice);

    return (
        <div className={styles.planCard}>
            <div className={styles.planCardHeader}>
                <div className={styles.planCardContent}>
                    <div className={clsx(styles.planCardTitle, classes?.title)}>
                        {title}
                    </div>
                    <div className={clsx(styles.priceSubtitle, "text-muted")}>{description}</div>
                    {currentPlan && <div className={styles.current}>{t("annual-payment")}</div>}

                    {!hasPrice && <div className={`${styles.free} pt-4 text-muted`}>{t("free")}</div>}

                    {hasPrice && (
                        <div className="d-flex align-items-start gap-1 pt-4">
                            <strong>€</strong>
                            <strong className={styles.priceWhole}>{value}</strong>
                            <strong className="text-decoration-underline">{cents}</strong>
                            <span>/{t("month")}</span>
                        </div>
                    )}

                    {periodicity === "yearly" && !!price?.yearlyFullPrice && <div className={styles.priceDescription}>{price?.yearlyFullPrice}€ {t("charged-annually")}</div>}
                </div>
            </div>
            <div className={styles.line} />

            <div className={styles.planCardContent}>
                <ul className={styles.list}>
                    {iconList.map((item, index) => (
                        <li className="d-flex align-items-center gap-2" key={`icon-list-${index}`}>
                            <div className="flex-shrink-1">
                                <img src={getIconUrl(item)} alt="" className={styles.featureIcon} />
                            </div>
                            <div style={{ opacity: item.disabled ? .5 : 1 }}>
                                <div className={styles.itemTitle}>{item.title}</div>
                                <div className={styles.itemDescription}>{item.description}</div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            {id && (
                <div className={styles.planCardAction}>
                    <Button className="w-100" isBusy={isLoading} primary size="large" href={data?.link}>
                        {t("select")}
                    </Button>
                </div>
            )}
        </div>
    )
}

PricePlanTable.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    iconList: PropTypes.arrayOf(PropTypes.shape({
        iconName: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    })).isRequired,
    periodicity: PropTypes.oneOf(["monthly", "yearly"]),
    currentPlan: PropTypes.bool,
    price: PropTypes.shape({
        monthly: PropTypes.number,
        yearly: PropTypes.number,
        yearlyFullPrice: PropTypes.number,
    }),
    id: PropTypes.string,
    classes: PropTypes.shape({
        title: PropTypes.string,
    }),
}