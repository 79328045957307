import * as Sentry from "@sentry/react";

import { Replay } from '@sentry/replay';
import { IS_PRODUCTION } from 'config';
import { BrowserTracing } from '@sentry/browser'

export function init() {
    if (IS_PRODUCTION) {
        Sentry.init({
            dsn: "https://1e796a367e6643e997332d5be5d560ed@o1218012.ingest.sentry.io/6360021",
            replaysSessionSampleRate: 1.0,
            replaysOnErrorSampleRate: 1.0,
            integrations: [
                new BrowserTracing(),
                new Replay({
                    maskAllText: false,
                    blockAllMedia: false,
                    maskAllInputs: false
                }),
                // https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-thirdpartyerrorfilterintegration
                // require webpack plugin
                // Sentry.thirdPartyErrorFilterIntegration({
                // }),
            ],
            tracesSampleRate: 1.0,
            ignoreErrors: [
                // network errors
                "ChunkLoadError",
                "Loading CSS chunk",
                "Loading chunk",
                "Failed to fetch",
                "NetworkError",
                "Network error",
                "AbortError",
                "Request aborted",
                // promise rejections
                // "Non-Error promise rejection captured",
                // "UnhandledRejection: Non-Error promise rejection captured with value: undefined",
                // apexcharts errors
                "TypeError: null is not an object (evaluating 't.parentNode.getAttribute')",
                "TypeError: Cannot read properties of undefined (reading 'style')",
                // pdf.js errors
                "InvalidPDFException",
                "NetworkError when attempting to fetch resource",
                "PasswordException: No password given",
                "Error: Cannot use the same canvas during multiple render() operations. Use different canvas or ensure previous operations were cancelled or completed.",
                "InvalidPDFException: Invalid PDF structure.",
                // performance issues (LCP)
                "Large Render Blocking Asset",
                // clarity
                "ReferenceError: Can't find variable: process",
                // chrome extensions
                "chrome-extension://",
            ],

            // https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-before-send
            // return null to ignore the event
            // beforeSend(event, hint) {
            //     return event;
            // }
        });
    }
}

export function trackError(error, extra) {
    if (error?.response?.status === 401) {
        window.location.reload();
        return;
    }

    if (IS_PRODUCTION) {
        Sentry.captureException(error, { extra });
    } else {
        console.error({ error, extra });
    }
}

export function setUserEmail(email) {
    Sentry.setUser({ email });
}