import axiosInstance from "Utils/axiosInstance";
import { sortItems } from "Utils/mappers";

function mapDtoToForecast(dto) {
    return {
        ...dto,
        creation_date: new Date(dto.creation_date),
        last_update: new Date(dto.last_update),
        period_start: new Date(dto.period_start),
        period_end: new Date(dto.period_end),
    }
}

export async function getForecasts() {
    const response = await axiosInstance.get("/forecasts");

    if (!response?.data) return [];

    return response.data.map(mapDtoToForecast).sort(sortItems);
}

export async function sortForecasts(data) {
    const response = await axiosInstance.post("/forecasts/sort", data);

    if (!response?.data) return [];

    return response.data.map(mapDtoToForecast).sort(sortItems);
}

export async function createForecast(data) {
    const response = await axiosInstance.post("/forecasts", data);

    if (!response?.data) return null;

    return mapDtoToForecast(response.data);
}

export async function getForecast(id) {
    const response = await axiosInstance.get(`/forecasts/${id}`);

    if (!response?.data) return null;

    return mapDtoToForecast(response.data);
}

export async function deleteForecast(id) {
    await axiosInstance.delete(`/forecasts/${id}`);
}

export async function updateForecast(id, data) {
    const response = await axiosInstance.patch(`/forecasts/${id}`, data);

    if (!response?.data) return null;

    return mapDtoToForecast(response.data);
}

export async function renderForecast(id) {
    const response = await axiosInstance.get(`/forecasts/${id}/render`);
    return response?.data;
}

export async function createForecastValue(id, data) {
    const response = await axiosInstance.post(`/forecasts/${id}/values`, data);
    return response?.data;
}

export async function getForecastValue(forecastId, forecastValueId) {
    const response = await axiosInstance.get(`/forecasts/${forecastId}/values/${forecastValueId}`);
    return response?.data;
}

export async function deleteForecastValue(forecastId, forecastValueId) {
    await axiosInstance.delete(`/forecasts/${forecastId}/values/${forecastValueId}`);
}