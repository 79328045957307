import React, { createContext, useContext, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';

const GlobalFloatingContext = createContext();

function useConditionalChatDisplay(isHidden) {
    useLayoutEffect(() => {
        const gistApp = document.getElementById("gist-app");
        if (gistApp) {
            gistApp.style.display = isHidden ? "none" : "initial";
        }

        const hubspotChat = document.getElementById("hubspot-messages-iframe-container");
        if (hubspotChat) {
            hubspotChat.style.setProperty("display", isHidden ? "none" : "initial", "important");
        }

        if (isHidden) {
            document.documentElement.style.overflow = "hidden";
        }
        return () => {
            document.documentElement.style.removeProperty("overflow");
            if (gistApp) {
                gistApp.style.removeProperty("display");
            }

            if (hubspotChat) {
                hubspotChat.style.removeProperty("display");
            }
        }
    }, [isHidden]);
}

export default function GlobalFloatingProvider({ children }) {
    const [floatingKeys, setFloatingKeys] = useState([]);

    useConditionalChatDisplay(floatingKeys.length > 0);

    const addFloatingKey = (key) => {
        setFloatingKeys((prev) => [...prev, key]);
    }

    const removeFloatingKey = (key) => {
        setFloatingKeys((prev) => prev.filter((k) => k !== key));
    }

    const value = {
        addFloatingKey,
        removeFloatingKey
    }

    return (
        <GlobalFloatingContext.Provider value={value}>
            {children}
        </GlobalFloatingContext.Provider>
    )
}

GlobalFloatingProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export function useGlobalFloating() {
    const context = useContext(GlobalFloatingContext);

    if (!context) {
        throw new Error("useGlobalFloating must be used within a GlobalFloatingProvider");
    }

    return context;
}