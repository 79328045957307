import React from "react";
import PropTypes from 'prop-types';

export default function PinIcon({ color = "#979797" }) {
    return (
        <svg
            width="13"
            height="15"
            viewBox="0 0 13 15"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(-23 -33)">
                    <g transform="translate(24.217 34)">
                        <path
                            stroke={color}
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M8.783 0a1 1 0 011 1v1a1 1 0 01-1 1h-1v3c1.174.587 2.09 1.69 2.747 3.31a.5.5 0 01-.463.69H.5a.5.5 0 01-.463-.69C.694 7.69 1.61 6.588 2.783 6V3h-1a1 1 0 01-1-1V1a1 1 0 011-1h7z"
                        ></path>
                        <rect
                            width="1.5"
                            height="4"
                            x="4.533"
                            y="10"
                            fill={color}
                            rx="0.75"
                        ></rect>
                    </g>
                </g>
            </g>
        </svg>
    );
}

PinIcon.propTypes = {
    color: PropTypes.string
}