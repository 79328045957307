import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { SnackbarContent } from "notistack";
import classes from "./Snackbar.module.css";
import Spinner from "Components/Spinner";
import { useTranslation } from "react-i18next";

const SnackbarUpload = forwardRef(function SnackbarUpload(props, ref) {
    const { message } = props;

    const { t } = useTranslation("common");

    return (
        <SnackbarContent ref={ref} className={classes.root}>
            <div className={classes.card}>
                <div className={classes.layout}>
                    <div className={classes.content}>
                        <div className={classes.actionText}>{t("uploading")}</div>
                        <div className={classes.title}>{message}</div>
                    </div>
                    <div className={classes.action}>
                        <Spinner size={24} color="#5543C7" className={classes.spinner} />
                    </div>
                </div>
            </div>
        </SnackbarContent>
    );
});

export default SnackbarUpload;

SnackbarUpload.propTypes = {
    message: PropTypes.string.isRequired,
};