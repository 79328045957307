import React from "react";
import PropTypes from "prop-types";
import ProviderConnectionConnectWithRedirection from "./ProviderConnectionConnectWithRedirection";
import CreateManualProviderConnection from "Sections/CreateManualProviderConnection";
import Icon from "Components/Icon";
import classes from "./ProviderConnectionConnect.module.css";
import Button from "Components/Button";
import ProviderConnectionConnectWithCredentials from "./ProviderConnectionConnectWithCredentials";
import useProviderConnectionActions from "Hooks/useProviderConnectionActions";
import ProviderConnectionConnectWithSelector from "./ProviderConnectionConnectWithSelector";
import { useTranslation } from "react-i18next";

const SERVICES_WITH_CREDENTIALS = ["wealth_reader", "afterbanks_v3"];
const SERVICES_WITH_REDIRECTION = ["afterbanks_psd2", "nordigen", "indexa_capital"];
const SERVICES_WITH_SELECTOR = ["teller"];
const SERVICE_MANUAL = "manual";

const isBrokenConnector = (connector) => connector.health_status === "broken"

export default function ProviderConnectionConnect(props) {
    const { providerConnectionId, connector, onConnect, onError, onCancel, } = props;
    const { checkConnection } = useProviderConnectionActions();
    const service = connector?.service;

    const { t } = useTranslation("common");

    const handleConnect = (conexionId, credentials) => {
        checkConnection({
            id: conexionId,
            logo_url: connector.logo_url,
            name: connector.name,
            credentials,
        });
        onConnect();
    }

    if (isBrokenConnector(connector)) {
        return (
            <div className="text-center p-4">
                <div className="d-flex align-items-center justify-content-center">
                    <img src={connector.logo_url} alt={`Logo de ${connector.name}`} style={{ height: "4rem" }} />
                    <h4 className="fw-500 m-0 ms-3">{connector.name}</h4>
                </div>
                <div className={classes.statusInfo}>
                    <Icon name="circle-alert" color="currentColor" />
                    <span>
                        {t("provider-connections.bank-down-description")}
                    </span>
                </div>

                {onError && (
                    <Button primary size="large" className="mt-4" onClick={onError}>
                        {t("provider-connections.back")}
                    </Button>
                )}
            </div>
        )
    }

    if (SERVICES_WITH_CREDENTIALS.includes(service)) {
        return (
            <ProviderConnectionConnectWithCredentials
                connectorMeta={connector.service_provider_meta}
                providerConnectionId={providerConnectionId}
                connectorId={connector.id}
                providerLogoUrl={connector.logo_url}
                providerName={connector.name}
                onConnect={handleConnect}
                serviceId={connector.service}
                onCancel={onCancel}
            />
        )
    }

    if (SERVICES_WITH_SELECTOR.includes(service)) {
        return (
            <ProviderConnectionConnectWithSelector
                providerConnectionId={providerConnectionId}
                connectorId={connector.id}
                serviceProviderId={connector.service_provider_id}
                providerName={connector.name}
                onConnect={handleConnect}
                onCancel={onCancel}
            />
        )
    }

    if (SERVICES_WITH_REDIRECTION.includes(service)) {
        return (
            <ProviderConnectionConnectWithRedirection
                providerConnectionId={providerConnectionId}
                connectorId={connector.id}
                providerLogoUrl={connector.logo_url}
                providerName={connector.name}
                onCancel={onCancel}
                serviceId={connector.service}
            />
        )
    }

    if (service === SERVICE_MANUAL) {
        return (
            <CreateManualProviderConnection connectorId={connector.id} onConnect={onConnect} onCancel={onCancel} />
        )
    }

    return null
}

ProviderConnectionConnect.propTypes = {
    onConnect: PropTypes.func.isRequired,
    connector: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    providerConnectionId: PropTypes.string,
    onError: PropTypes.func,
};