export function formatDateISO(date) {
    if (!date) return null;

    let yearString = date.getFullYear();
    let monthString = (date.getMonth() + 1).toString();
    let dayString = date.getDate().toString();
    return yearString + '-' + (monthString.length === 1 ? '0' + monthString : monthString) + '-' + (dayString.length === 1 ? '0' + dayString : dayString);
}

export function getCurrencySymbol(locale, currency) {
    return (0).toLocaleString(locale, {
        style: 'currency',
        currency: currency ?? 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).replace(/\d/g, '').trim()
}

export function formatSearchParams({ query, expense, income, amountMin, amountMax, dateFrom, dateTo, categories, products, allProductIds, page = 1 }) {
    const type = [
        expense === "false" ? undefined : "expense",
        income === "false" ? undefined : "income",
    ].filter(Boolean);

    const dateMin = dateFrom ? new Date(dateFrom) : new Date(2000, 0, 1);
    const dateMax = dateTo ? new Date(dateTo) : new Date();

    return {
        ...(query && { q: encodeURIComponent(query) }),
        ...(type.length === 1 && { type: type[0] }),
        ...(categories && { categories }),
        amount_from: amountMin ?? 0,
        amount_to: amountMax ?? 999999,
        date_from: formatDateISO(dateMin),
        date_to: formatDateISO(dateMax),
        products_ids: products ? products : allProductIds.join(","),
        page,
    }
}

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function formatFileSize(bytes, { decimals = 2 } = {}) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function validatePassword(password) {
    if (password.length < 8) {
        return "La contraseña debe contener al menos 8 caracteres.";
    }

    if (!/[a-z]/.test(password)) {
        return "La contraseña debe contener al menos una letra minúscula.";
    }

    if (!/[A-Z]/.test(password)) {
        return "La contraseña debe contener al menos una letra mayúscula.";
    }

    if (!/\d/.test(password)) {
        return "La contraseña debe contener al menos un número.";
    }

    return null;
}

export function formatLongDate(date, { locale = "es-ES" } = {}) {
    if (!date) return "Nunca";

    if (locale === "es-ES") {
        return `${date.toLocaleDateString(locale)} a las ${date.toLocaleTimeString([], { hourCycle: 'h23', hour: '2-digit', minute: '2-digit' })}`;
    }

    return `${date.toLocaleDateString(locale)} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
}

export function formatList(list, { limit = 2, emptyText = "No hay elementos.", locale = "es", moreText = "más" } = {}) {
    if (list.length === 0) { return emptyText; }

    if (!Intl.ListFormat) {
        return list.slice(0, limit).join(', ');
    }

    const formatter = new Intl.ListFormat(locale, { style: 'long', type: 'conjunction' });
    const listToFomat = list.length <= limit
        ? list
        : list.slice(0, limit).concat(`${(list.length - limit)} ${moreText}`);

    return formatter.format(listToFomat);
}

export function formatCurrency(value, { currency = "EUR", locale = "es-ES" } = {}) {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
    });

    if (locale !== "es-ES") {
        return formatter.format(value);
    }

    if (value >= 1000 && value < 10000) {
        const formattedValue = formatter.format(value);
        const [thousands, ...rest] = formattedValue

        return `${thousands}.${rest.join('')}`
    }

    if (value <= -1000 && value > -10000) {
        const formattedValue = formatter.format(value);
        const [minus, thousands, ...rest] = formattedValue

        return `${minus}${thousands}.${rest.join('')}`
    }

    return formatter.format(value);
}

export function currencyToNumber(value) {
    const normalizedValueString = value
        .replace(/\./g, "")
        .replace(/,/g, '.')
        .replace(/[^0-9.-]+/g, "");

    return Number(normalizedValueString);
}

export function splitPrice(value) {
    const [whole, cents = "00"] = value.toString().split(".");
    const centsFormatted = cents.length > 1 ? cents : `${cents}0`;

    return [whole, centsFormatted]
}

export const formatLargeNumber = (value) => {
    return Intl.NumberFormat('en-US', {
        notation: 'compact',
        maximumFractionDigits: 1
    }).format(value)
}

export const formatNumber = (value) => {
    const formattedValue = new Intl.NumberFormat('es-ES').format(value)

    if (value >= 1000 && value < 10000) {
        const [thousands, ...rest] = formattedValue

        return `${thousands}.${rest.join('')}`
    }

    return formattedValue
}

export function htmlDecode(input) {
    const doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
}