
import PropTypes from "prop-types";
import React from "react";

const CopyClipboard = ({ color, size, ...props }) => (
    <svg viewBox="0 0 18 18" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M15 5.85H8.6A2.75 2.75 0 0 0 5.85 8.6V15a2.75 2.75 0 0 0 2.75 2.75H15A2.75 2.75 0 0 0 17.75 15V8.6A2.75 2.75 0 0 0 15 5.85Zm0 1.5c.69 0 1.25.56 1.25 1.25V15c0 .69-.56 1.25-1.25 1.25H8.6c-.69 0-1.25-.56-1.25-1.25V8.6c0-.69.56-1.25 1.25-1.25H15Z"/>
		<path d="M9.8.25a2.35 2.35 0 0 1 2.35 2.35v.8a.75.75 0 1 1-1.5 0v-.8a.85.85 0 0 0-.85-.85H2.6a.85.85 0 0 0-.85.85v7.2c0 .47.38.85.85.85h.8a.75.75 0 0 1 0 1.5h-.8A2.35 2.35 0 0 1 .25 9.8V2.6A2.35 2.35 0 0 1 2.6.25h7.2Z"/>
	</g>

    </svg>
);

export default CopyClipboard;

CopyClipboard.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
