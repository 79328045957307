import React, { useEffect, useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import MulticontractForm from "./MulticontractForm";
import MulticontractFormWithCredentials from "./MulticontractFormWithCredentials";
import useProviderConnector from "Hooks/useProviderConnector";
import useProviderConnectionActions from "Hooks/useProviderConnectionActions";
import { useErrors } from "Components/ErrorsProvider";
import { createProviderConnection } from "Services/providersConnections";
import Loading from "Components/Loading";
import useProviderConnectionsCredentials from "Hooks/useProviderConnectionsCredentials";

export default function Multicontract(props) {
    const { info, onClose, onEnableClose, onClearConnection, providerConnection } = props;
    const [canceling, setCanceling] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [credentials, setCredentials] = useState(null)
    const { get } = useProviderConnectionsCredentials()
    const { connector, isLoading } = useProviderConnector(providerConnection.provider_connector_id);

    const { checkConnection } = useProviderConnectionActions()
    const { showUnknownError } = useErrors();

    useLayoutEffect(() => {
        const storageCredentials = get(providerConnection.id)
        if (storageCredentials) {
            setCredentials(storageCredentials)
        }
    }, [])

    useEffect(() => {
        onEnableClose()
    }, [])

    if (isLoading) {
        return (
            <div>
                <Loading />
            </div>
        )
    }

    const handleCancel = async () => {
        setCanceling(true)
        await onClearConnection(false)

        setCanceling(false)
        onClose()
    }

    const handleSubmitFormWithCredentials = async (data) => {
        setSubmitting(true)
        try {
            onClearConnection()
            const conexionId = await createProviderConnection({
                connectorId: providerConnection.provider_connector_id,
                providerConnectionId: providerConnection.provider_connection_reference_id,
                name: providerConnection.name,
                counterId: info.counter_id,
                sessionId: info.session_id,
                otp: info.otp,
                ...data
            });

            checkConnection({
                id: conexionId,
                logo_url: connector.logo_url,
                name: connector.name,
            });
        } catch (error) {
            showUnknownError(error)
        } finally {
            setSubmitting(false)
            onClose()
        }
    }
    const handleSubmitForm = async (data) => {
        setSubmitting(true)
        try {
            onClearConnection()
            const conexionId = await createProviderConnection({
                connectorId: providerConnection.provider_connector_id,
                providerConnectionId: providerConnection.provider_connection_reference_id,
                name: providerConnection.name,
                counterId: info.counter_id,
                sessionId: info.session_id,
                documentType: info.document_type,
                otp: info.otp,
                credentials,
                ...data
            });

            checkConnection({
                id: conexionId,
                logo_url: connector.logo_url,
                name: connector.name,
                credentials
            });
        } catch (error) {
            showUnknownError(error)
        } finally {
            setSubmitting(false)
            onClose()
        }
    }

    const { fields_types, document_types, fields_descriptions, fields_names = [] } = connector.service_provider_meta
    const documentIndex = Number.isNaN(Number(info.document_type)) ? 0 : Number(info.document_type)
    const documentType = Array.isArray(document_types) ? document_types[documentIndex] : null

    const fieldsNamesWithContent = Array.isArray(info.credentials) ? fields_names.filter((_name, index) => info.credentials[index] != null) : []

    const disabledFields = ["document_type", ...fieldsNamesWithContent]

    return (
        <div>
            <h4>Elige tu Contrato</h4>
            <div className="text-muted mb-4">¿Qué contrato quieres asociar a tu cuenta de Banktrack?</div>

            {credentials && (
                <MulticontractForm
                    accounts={info.accounts}
                    onSubmit={handleSubmitForm}
                    onCancel={handleCancel}
                    canceling={canceling}
                    submitting={submitting}
                />
            )}
            {!credentials && (
                <MulticontractFormWithCredentials
                    accounts={info.accounts}
                    onSubmit={handleSubmitFormWithCredentials}
                    fieldsNames={fields_names}
                    fieldsDescriptions={fields_descriptions}
                    fieldsTypes={fields_types}
                    documentTypes={document_types}
                    documentType={documentType}
                    fieldValues={info.credentials}
                    disabledFields={disabledFields}
                    onCancel={handleCancel}
                    canceling={canceling}
                    submitting={submitting}
                />
            )}
        </div>
    )
}

Multicontract.propTypes = {
    providerConnection: PropTypes.object.isRequired,
    description: PropTypes.string.isRequired,
    info: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onEnableClose: PropTypes.func.isRequired,
    onClearConnection: PropTypes.func.isRequired,
};