
import PropTypes from "prop-types";
import React from "react";

const Edit = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M10.577 22.121a1 1 0 0 1 1-1h10.578a1 1 0 0 1 0 2H11.577a1 1 0 0 1-1-1ZM19.015 3.051a1.493 1.493 0 0 0-1.442.386L3.078 17.932l-.704 2.815 2.816-.704L19.684 5.55a1.493 1.493 0 0 0-.67-2.498Zm-2.857-1.028a3.493 3.493 0 0 1 4.94 4.94l-14.69 14.69a1 1 0 0 1-.465.263l-4.7 1.175A1 1 0 0 1 .03 21.88l1.175-4.701a1 1 0 0 1 .263-.465l14.69-14.69Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default Edit;

Edit.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
