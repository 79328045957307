import React from "react";
import PropTypes from "prop-types";
import classes from './MinimalLayout.module.css';
import { Link } from "react-router-dom";
import { ROUTES } from "Utils/constants";
import logoImage from "assets/favicon.png";

export default function MinimalLayout(props) {
    const { children, title, description, image = logoImage, disabledLinkLogo = false } = props;

    return (
        <div className={classes.wrapper}>
            <div className={classes.container}>
                <div className={classes.header}>
                    <div className={classes.imageContainer}>
                        {!disabledLinkLogo && (
                            <Link to={ROUTES.signIn}>
                                <img src={image} alt="" className={classes.image} />
                            </Link>
                        )}

                        {disabledLinkLogo && (
                            <img src={image} alt="" className={classes.image} />
                        )}
                    </div>
                    <h2 className={classes.title}>
                        {title}
                    </h2>
                    {description && <p className="text-muted">{description}</p>}
                </div>
                <div className={classes.content}>
                    {children}
                </div>
            </div>
        </div>
    );
}

MinimalLayout.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    image: PropTypes.string,
    disabledLinkLogo: PropTypes.bool,
}
