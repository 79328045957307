import React from "react";
import classes from "./AppLayout.module.css";
import useProjectSelected from "Hooks/useProjectSelected";
import Icon from "Components/Icon";
import warningImage from "assets/warning.svg";
import { getSubscriptionModifyLink } from "Services/user";
import { useErrors } from "Components/ErrorsProvider";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export default function AppLayoutPaymentErrorCTA() {
    const { showUnknownError } = useErrors();

    const { project } = useProjectSelected();
    const { data, isLoading } = useQuery(["subcription-modify-link", project?.subscription?.plan], () => getSubscriptionModifyLink(), {
        enabled: project?.subscription?.has_payment_error,
        onError: showUnknownError
    });
    const { t } = useTranslation("common");

    if (isLoading) {
        return null;
    }

    if (!project?.subscription?.has_payment_error) {
        return null;
    }

    return (
        <div className={classes.cardWarning}>
            <img src={warningImage} alt="" height="21" className={classes.cardWarningIcon} />

            <div className={classes.cardWarningLayout}>
                <div>
                    <div className={classes.cardWarningTitle}>
                        {t("payment-error")}
                    </div>
                    <div>
                        {t("payment-error-description")}
                    </div>
                </div>
                <a href={data.link} className={classes.cardWarningLink}>
                    <span>
                        {t("payment-error-cta")}
                    </span>
                    <Icon name="arrow-right-small" size={25} color="currentColor" />
                </a>
            </div>
        </div>
    )
}
