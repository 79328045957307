import React, { useCallback } from "react";
import PropTypes from 'prop-types';
import classes from "./PlansAndPricingProvider.module.css";
import FullScreenDialog from "Components/FullScreenDialog";
import PriceTable from "Sections/PriceTable";
import { PlansAndPricingContext } from "./PlansAndPricingContext";

export default function PlansAndPricingProvider({ children, open, setOpen }) {
    const openPlansAndPricing = useCallback(() => setOpen(true), []);

    return (
        <PlansAndPricingContext.Provider value={{ openPlansAndPricing }}>
            <FullScreenDialog
                className={classes.modalBackground}
                showCloseButton
                size="extraLarge"
                open={open}
                onClose={() => setOpen(false)}
                zIndex={999999}
            >
                <PriceTable />
            </FullScreenDialog>
            {children}
        </PlansAndPricingContext.Provider>
    );
}

PlansAndPricingProvider.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    children: PropTypes.node,
}