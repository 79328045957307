
import PropTypes from "prop-types";
import React from "react";

const ArrowLeftSmall = ({ color, size, ...props }) => (
    <svg viewBox="0 0 28 28" width={size} height={size} {...props}>
        
	<path d="m8.652 12.837 4.482-4.482c1.287-1.287-.644-3.218-1.931-1.93L7.12 10.512l-2.725 2.729a1.362 1.362 0 0 0 0 1.92l6.807 6.814c.258.267.615.416.986.413 1.226 0 1.828-1.494.944-2.344l-4.492-4.481h14.004c1.887-.094 1.747-2.82-.071-2.727H8.652Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default ArrowLeftSmall;

ArrowLeftSmall.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
