
import PropTypes from "prop-types";
import React from "react";

const NavFeedback = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="evenodd">
		<path d="M7.556 4A3.556 3.556 0 0 0 4 7.556v12.777a3.556 3.556 0 0 0 3.556 3.556l14.919-.001 4.818 4.82c.602.602 1.614.22 1.701-.594L29 28V7.556A3.556 3.556 0 0 0 25.444 4Zm0 2h17.888C26.304 6 27 6.696 27 7.556v18.029l-3.404-3.403a1 1 0 0 0-.576-.284l-.131-.01H7.556c-.86 0-1.556-.696-1.556-1.555V7.556C6 6.696 6.696 6 7.556 6Zm6.309 2C11.739 8 10 9.67 10 11.75c0 2.257 1.166 4.28 3.068 6.053a15.78 15.78 0 0 0 2.761 2.051l.202.112a1 1 0 0 0 .938 0c.2-.105.528-.299.944-.575a15.78 15.78 0 0 0 2.019-1.588C21.834 16.029 23 14.007 23 11.75 23 9.67 21.26 8 19.136 8c-.988 0-1.91.363-2.606.98l-.029.027-.212-.178a3.93 3.93 0 0 0-2.214-.823Zm0 2c.764 0 1.435.434 1.721 1.077l.059.112c.404.677 1.434.64 1.769-.112l.067-.135c.315-.568.945-.942 1.655-.942 1.038 0 1.864.793 1.864 1.75 0 1.603-.897 3.159-2.432 4.59-.558.52-1.16.985-1.762 1.385l-.305.197-.307-.197c-.601-.4-1.204-.865-1.762-1.385C12.897 14.909 12 13.353 12 11.75c0-.957.826-1.75 1.865-1.75Z" fill={color} fillRule="nonzero"/>
	</g>

    </svg>
);

export default NavFeedback;

NavFeedback.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
