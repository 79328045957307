import React from "react";
import PropTypes from "prop-types";
import dialogClasses from "./FullScreenDialog.module.css";
import clsx from "clsx";

const FullScreenDialogFooter = ({ className, children }) => (
    <div className={clsx(dialogClasses.dialogActions, className)}>
        {children}
    </div>
)

FullScreenDialogFooter.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
}

export default FullScreenDialogFooter;