
import PropTypes from "prop-types";
import React from "react";

const Maximize = ({ color, size, ...props }) => (
    <svg viewBox="0 0 20 20" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M19 0a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V2h-5a1 1 0 0 1-.993-.883L12 1a1 1 0 0 1 1-1h6ZM1 12a1 1 0 0 1 1 1v5h5a1 1 0 0 1 .993.883L8 19a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1Z"/>
		<path d="M18.293.293a1 1 0 0 1 1.414 1.414l-7 7a1 1 0 0 1-1.414-1.414l7-7ZM7.293 11.293a1 1 0 0 1 1.414 1.414l-7 7a1 1 0 1 1-1.414-1.414l7-7Z"/>
	</g>

    </svg>
);

export default Maximize;

Maximize.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
