
import PropTypes from "prop-types";
import React from "react";

const ModulePop = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M10.219 3.023 8.172 7.166 3.6 7.831a2.25 2.25 0 0 0-1.288.657l-.117.13a2.25 2.25 0 0 0 .158 3.051l3.31 3.225-.782 4.556a2.25 2.25 0 0 0 .226 1.427l.08.14a2.25 2.25 0 0 0 2.959.805l4.09-2.151 4.09 2.15c.439.231.94.31 1.428.227l.158-.033a2.25 2.25 0 0 0 1.68-2.565l-.783-4.556 3.31-3.225a2.25 2.25 0 0 0 .657-1.287l.017-.16a2.25 2.25 0 0 0-1.92-2.39l-4.574-.666-2.045-4.143a2.25 2.25 0 0 0-4.035 0Zm2.017 1.561 1.88 3.807.08.15a2.25 2.25 0 0 0 1.614 1.081l4.201.61-3.04 2.965-.126.133a2.25 2.25 0 0 0-.521 1.858l.717 4.185-3.758-1.976-.166-.079a2.25 2.25 0 0 0-1.928.08L7.43 19.372l.719-4.185.023-.183a2.25 2.25 0 0 0-.67-1.808L4.46 10.232l4.203-.61a2.25 2.25 0 0 0 1.694-1.23l1.88-3.808Z" fill={color} fillRule="nonzero"/>

    </svg>
);

export default ModulePop;

ModulePop.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
