
import PropTypes from "prop-types";
import React from "react";

const CircleCheck = ({ color, size, ...props }) => (
    <svg viewBox="0 0 19 19" width={size} height={size} {...props}>
        
	<path d="M9.5 0A9.5 9.5 0 1 0 19 9.5 9.518 9.518 0 0 0 9.5 0Zm4.522 7.838-5.353 5.115a.74.74 0 0 1-.512.2.702.702 0 0 1-.502-.2l-2.677-2.558a.731.731 0 1 1 1.005-1.06l2.174 2.074 4.86-4.631a.73.73 0 0 1 1.005 1.06Z" fill={color}/>

    </svg>
);

export default CircleCheck;

CircleCheck.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
