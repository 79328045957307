
import PropTypes from "prop-types";
import React from "react";

const Switch = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M15.914 11.47a.75.75 0 0 1 1.06 0l3.89 3.889a.75.75 0 0 1 0 1.06l-3.89 3.89a.75.75 0 1 1-1.06-1.062l3.358-3.358-3.358-3.359a.75.75 0 0 1-.073-.976l.073-.084Z"/>
		<path d="M20.333 15.139a.75.75 0 1 1 0 1.5H11a.75.75 0 0 1 0-1.5h9.333ZM8.42 4.47a.75.75 0 0 0-1.061 0l-3.89 3.889a.75.75 0 0 0 0 1.06l3.89 3.89a.75.75 0 1 0 1.06-1.062L5.061 8.89 8.419 5.53a.75.75 0 0 0 .073-.976l-.073-.084Z"/>
		<path d="M4 8.139a.75.75 0 0 0 0 1.5h9.333a.75.75 0 0 0 0-1.5H4Z"/>
	</g>

    </svg>
);

export default Switch;

Switch.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
