import React from "react";
import PropTypes from "prop-types";
import AutocompleteContacts from "Components/AutocompleteContacts";
import { useFormContext, Controller } from 'react-hook-form';
import useContacts from "Hooks/useContacts";
import Skeleton from "Components/Skeleton";

export default function RHFTSelectContact({ name, ...other }) {
    const { control } = useFormContext();
    const { contacts, isLoading } = useContacts();

    if (isLoading) {
        return <Skeleton variant="rounded" height={56} />
    }

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <AutocompleteContacts
                    {...field}
                    options={contacts}
                    error={error?.message}
                    {...other}
                />
            )}
        />
    );
}

RHFTSelectContact.propTypes = {
    name: PropTypes.string.isRequired,
};
