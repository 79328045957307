import reportHandlers from './reports';
import usersHandlers from './users';
import providershandlers from './providers';
import othersHandlers from './others';
import alertsHandlers from './alerts';
import channelsHandlers from './channels';
import automationsHandlers from './automations';
import dashboardsHandlers from './dashboards';
import invoicesHandlers from './invoices';
import suggestionsHandlers from './suggestions';

export const handlers = [
    ...usersHandlers,
    ...reportHandlers,
    ...providershandlers,
    ...channelsHandlers,
    ...alertsHandlers,
    ...othersHandlers,
    ...automationsHandlers,
    ...dashboardsHandlers,
    ...invoicesHandlers,
    ...suggestionsHandlers,
]