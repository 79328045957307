import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import Checkbox from 'Components/Checkbox';

export default function RHFCheckbox({ name, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <Checkbox
                    {...field}
                    checked={field.value}
                    {...other}
                />
            )}
        />
    );
}

RHFCheckbox.propTypes = {
    name: PropTypes.string,
};