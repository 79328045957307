
import PropTypes from "prop-types";
import React from "react";

const PlusBig = ({ color, size, ...props }) => (
    <svg viewBox="0 0 22 22" width={size} height={size} {...props}>
        
	<path d="M12 2a1 1 0 0 0-2 0v8H2a1 1 0 0 0 0 2h8v8a1 1 0 0 0 2 0v-8h8a1 1 0 0 0 0-2h-8V2Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default PlusBig;

PlusBig.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
