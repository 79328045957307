import React, { cloneElement, useRef, useState, useId } from "react";
import PropTypes from "prop-types";
import { useFloating } from "@floating-ui/react";
import { flip, offset, shift, arrow } from "@floating-ui/react-dom";
import classes from "./Tooltip.module.css";
import clsx from 'clsx';

const Tooltip = ({ children, label, placement = "top", className }) => {
    const [isShown, setIsShown] = useState(false);
    const arrowRef = useRef();
    const tooltipId = useId();

    const {
        refs, x, y, strategy,
        middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
        placement: computedPlacement
    } = useFloating({
        placement,
        middleware: [
            offset(10),
            flip(),
            shift({ padding: 8 }),
            arrow({ element: arrowRef }),
        ]
    });

    const arrowPositions = new Map([
        ["bottom", { top: -3, left: arrowX }],
        ["top", { bottom: -3, left: arrowX }],
        ["right", { top: arrowY, left: -3 }],
        ["left", { top: arrowY, right: -3 }],
    ]);

    const arrowPositionStyle = arrowPositions.get(computedPlacement);

    return (
        <>
            {cloneElement(children, {
                ref: refs.setReference,
                onMouseEnter: () => setIsShown(true),
                onMouseLeave: () => setIsShown(false),
                onFocus: () => setIsShown(true),
                onBlur: () => setIsShown(false),
                "aria-describedby": tooltipId
            })}
            {isShown && (
                <div ref={refs.setFloating} id={tooltipId} role="tooltip" className={clsx(classes.tooltip, className)} style={{ top: y, left: x, position: strategy }}>
                    <div ref={arrowRef} className={classes.arrow} style={arrowPositionStyle} />
                    <div className={classes.innerTooltip}>{label}</div>
                </div>
            )}
        </>
    )
}

Tooltip.propTypes = {
    children: PropTypes.node.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    placement: PropTypes.oneOf(["top", "bottom", "left", "right"]),
    className: PropTypes.string,
}

export default Tooltip;