
import PropTypes from "prop-types";
import React from "react";

const Building = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M10 2h12a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V10a1 1 0 0 1 .811-.982L9 7.635V3a1 1 0 0 1 1-1ZM9 9.672l-6 1.154V20h6V9.672ZM21 20V4H11v16h10Z" fill={color} fillRule="evenodd"/>
	<path d="M15 7h-2v2h2V7ZM7 12H5v2h2v-2ZM7 16H5v2h2v-2ZM19 7h-2v2h2V7ZM15 11h-2v2h2v-2ZM17 16h-2a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2Zm0 2v2h-2v-2h2ZM19 11h-2v2h2v-2Z" fill={color}/>

    </svg>
);

export default Building;

Building.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
