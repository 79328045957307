import { rest } from 'msw'
import { API_HOST } from 'config'
import channels from "./fixtures/channels.json"

const channelsHandlers = [
    rest.get(`${API_HOST}/integrations`, (req, res, ctx) => {
        return res(
            ctx.json(channels),
        )
    })
]

export default channelsHandlers