import React, { useState } from "react";
import Button from "Components/Button";
import PropTypes from "prop-types";
import Radio, { RadioGroup } from "Components/Radio";

export default function RequiresOtpForm(props) {
    const {
        onSubmit, onCancel,
        canceling, submitting, otpMethods
    } = props;

    const [otpMethod, setOtpMethod] = useState(() => {
        if (Array.isArray(otpMethods) && otpMethods.length > 0) {
            return `radio-${otpMethods[0].method_id}`;
        }
        return null;
    });

    const processing = canceling || submitting;

    const handleSubmit = (ev) => {
        ev.preventDefault();
        if (processing) return;

        const originalOtpMethod = otpMethods.find(a => `radio-${a.method_id}` === otpMethod);
        onSubmit({
            counterId: originalOtpMethod?.method_id ?? null,
        });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="d-grid gap-4 text-start">
                <RadioGroup name="otpmethod" label="Cuenta" value={otpMethod} onChange={ev => setOtpMethod(ev.target.value)}>
                    {otpMethods.map(method => (
                        <Radio id={`radio-${method.method_id}`} key={`radio-${method.method_id}`} value={`radio-${method.method_id}`}>
                            <div className="pt-1">{method.description}</div>
                        </Radio>
                    ))}
                </RadioGroup>
            </div>

            <div className="pt-4 d-flex gap-2 justify-content-center">
                <Button size="large" primary type="submit" isBusy={submitting}>
                    <img alt="" src="/img/icon-padlock.svg" />
                    <span className={"ms-2"}>Conectar</span>
                </Button>
                <Button size="large" variant="outlined" onClick={onCancel} isBusy={canceling}>
                    Cancelar
                </Button>
            </div>
        </form>
    )
}


RequiresOtpForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    fieldsNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    fieldsDescriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
    fieldsTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    documentTypes: PropTypes.arrayOf(PropTypes.string),
    onCancel: PropTypes.func.isRequired,
    documentType: PropTypes.string,
    fieldValues: PropTypes.arrayOf(PropTypes.string),
    canceling: PropTypes.bool,
    submitting: PropTypes.bool,
    disabledFields: PropTypes.arrayOf(PropTypes.string).isRequired,
    otpMethods: PropTypes.arrayOf(PropTypes.shape({
        method_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        description: PropTypes.string.isRequired,
    })).isRequired,
}