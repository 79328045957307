
import PropTypes from "prop-types";
import React from "react";

const At = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M21.9 10.638c-.75-5.47-5.793-9.294-11.262-8.544-5.47.75-9.294 5.792-8.544 11.261a10.066 10.066 0 0 0 8.58 8.608c.446.058.893.087 1.342.087a9.898 9.898 0 0 0 5.14-1.429.967.967 0 0 0-.999-1.657 8.059 8.059 0 1 1 3.464-4.268c-.208.556-.746.92-1.34.906a1.443 1.443 0 0 1-1.44-1.44V8.187a.967.967 0 0 0-1.935 0v.01a4.835 4.835 0 1 0 .349 7.428 3.368 3.368 0 0 0 3.026 1.91 3.331 3.331 0 0 0 3.168-2.207c.521-1.506.676-3.114.45-4.69Zm-9.895 4.32a2.901 2.901 0 1 1 0-5.803 2.901 2.901 0 0 1 0 5.803Z" fill={color}/>

    </svg>
);

export default At;

At.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
