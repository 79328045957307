import { QueryClient } from '@tanstack/react-query'
import { trackError } from './errorMonitoring';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: (failureCount, error) => {
                if (error.response?.status === 401) {
                    return false;
                }

                const defaultRetry = new QueryClient().getDefaultOptions().queries?.retry;

                return Number.isSafeInteger(defaultRetry)
                    ? failureCount < (defaultRetry ?? 0)
                    : false;
            }
        },
        onError: (error) => {
            if (error.response?.status === 401) {
                window.location.reload();
                return;
            }
            trackError(error);
        },
    }
});

export default queryClient;