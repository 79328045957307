
import PropTypes from "prop-types";
import React from "react";

const Coins = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M16.125 1C12.27 1 9.25 2.812 9.25 5.125v2.371a11.76 11.76 0 0 0-1.375-.08C4.019 7.417 1 9.23 1 11.543v7.333C1 21.188 4.02 23 7.875 23c3.131 0 5.712-1.195 6.57-2.884.547.08 1.106.134 1.68.134 3.855 0 6.875-1.812 6.875-4.125v-11C23 2.812 19.98 1 16.125 1Zm0 1.833c2.886 0 5.042 1.21 5.042 2.292 0 1.082-2.156 2.292-5.042 2.292-2.886 0-5.042-1.21-5.042-2.292 0-1.082 2.156-2.292 5.042-2.292ZM7.875 9.25c2.886 0 5.042 1.21 5.042 2.292 0 1.081-2.156 2.291-5.042 2.291-2.886 0-5.042-1.21-5.042-2.291 0-1.082 2.156-2.292 5.042-2.292Zm-5.042 5.117c1.247.802 3.028 1.3 5.042 1.3s3.795-.498 5.042-1.3v.841c0 1.082-2.156 2.292-5.042 2.292-2.886 0-5.042-1.21-5.042-2.292v-.841Zm5.042 6.8c-2.886 0-5.042-1.21-5.042-2.292v-.841c1.247.802 3.028 1.3 5.042 1.3s3.795-.498 5.042-1.3v.841c0 1.082-2.156 2.292-5.042 2.292Zm8.25-2.75c-.471 0-.928-.043-1.375-.107v-1.815c.451.053.908.088 1.375.088 2.014 0 3.795-.497 5.042-1.3v.842c0 1.082-2.156 2.292-5.042 2.292Zm0-3.667c-.471 0-.928-.043-1.375-.106v-1.815c.451.053.908.088 1.375.088 2.014 0 3.795-.498 5.042-1.3v.841c0 1.082-2.156 2.292-5.042 2.292Zm0-3.667c-.497 0-.977-.046-1.447-.116-.181-.813-.741-1.544-1.577-2.128.91.262 1.93.412 3.024.412 2.014 0 3.795-.498 5.042-1.3v.842c0 1.081-2.156 2.291-5.042 2.291Z" fill={color}/>

    </svg>
);

export default Coins;

Coins.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
