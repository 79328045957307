// Provider connections
export const ADD_PROVIDER_CONNECTION = 'ADD_PROVIDER_CONNECTION';
export const REMOVE_PROVIDER_CONNECTION = 'REMOVE_PROVIDER_CONNECTION';
export const UPDATE_PROVIDER_CONNECTION = 'UPDATE_PROVIDER_CONNECTION';

// Alert connections
export const ADD_ALERT_CONNECTION = 'ADD_ALERT_CONNECTION';
export const REMOVE_ALERT_CONNECTION = 'REMOVE_ALERT_CONNECTION';

// automations
export const ADD_AUTOMATION = 'ADD_AUTOMATION';
export const REMOVE_AUTOMATION = 'REMOVE_AUTOMATION';

// Channels connections
export const ADD_CHANNEL_CONNECTION = 'ADD_CHANNEL_CONNECTION';
export const REMOVE_CHANNEL_CONNECTION = 'REMOVE_CHANNEL_CONNECTION';

// Reports connections
export const ADD_REPORT_CONNECTION = 'ADD_REPORT_CONNECTION';
export const REMOVE_REPORT_CONNECTION = 'REMOVE_REPORT_CONNECTION';
export const UPDATE_REPORT_CONNECTION = 'UPDATE_REPORT_CONNECTION';

// Dashboards
export const ADD_DASHBOARD = 'ADD_DASHBOARD';
export const REMOVE_DASHBOARD = 'REMOVE_DASHBOARD';
export const UPDATE_DASHBOARD = 'UPDATE_DASHBOARD';

// transactions
export const EDIT_TRANSACTION = 'EDIT_TRANSACTION';

// Categories
export const ADD_CATEGORY = 'ADD_CATEGORY';

// Search transactions
export const SEARCH_TRANSACTIONS = 'SEARCH_TRANSACTIONS';

// Forecasts
export const ADD_FORECAST = 'ADD_FORECAST';
export const REMOVE_FORECAST = 'REMOVE_FORECAST';
export const UPDATE_FORECAST = 'UPDATE_FORECAST';