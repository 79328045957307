
import PropTypes from "prop-types";
import React from "react";

const ModuleLists = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="evenodd">
		<g transform="translate(0 4)">
			<circle cx="2" cy="2" r="2"/>
			<rect height="4" width="17" rx="2" x="6"/>
		</g>
		<g transform="translate(0 10)">
			<circle cx="2" cy="2" r="2"/>
			<rect height="4" width="17" rx="2" x="6"/>
		</g>
		<g transform="translate(0 16)">
			<circle cx="2" cy="2" r="2"/>
			<rect height="4" width="17" rx="2" x="6"/>
		</g>
	</g>

    </svg>
);

export default ModuleLists;

ModuleLists.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
