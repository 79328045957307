import axiosInstance from "Utils/axiosInstance";
import { mapDtoToCategory } from "Utils/mappers";

const sortByOrder = (a, b) => a.order - b.order;

const formatParams = (params) => ({
    ...params,
    color: params.color.replace("#", ""),
    name: params.name.trim(),
    parent_tag_id: params.parent_tag_id || null,
    categorization_keywords: params.categorization_keywords ?? [],
})

const sortCategoriesByParent = (categories) => {
    const parentCategories = categories.filter(category => !category.parent_tag_id).sort(sortByOrder)
    const childrenCategories = categories.filter(category => category.parent_tag_id)

    return parentCategories.flatMap(parent => {
        const children = childrenCategories.filter(category => category.parent_tag_id === parent.id).sort(sortByOrder)
        const formattedChildren = children.map(child => ({
            ...child,
            icon: parent.icon,
            color: parent.color,
            bgColor: parent.bgColor,
        }))

        return [parent, ...formattedChildren]
    })
}


export async function getCategories() {
    const response = await axiosInstance.get("/providers/transactions/tags");

    if (!response?.data) return [];

    return sortCategoriesByParent(response.data.map(mapDtoToCategory))
}

export async function sortCategories(ids) {
    await axiosInstance.post("/providers/transactions/tags/sort", ids);
}

export async function removeParentCategory(category, orderIds) {
    await editCategory(category.id, {
        ...category,
        parent_tag_id: null,
    });

    if (Array.isArray(orderIds)) {
        await sortCategories(orderIds);
    }
}

export async function addParentCategory(category, parentId, orderIds) {
    await editCategory(category.id, {
        ...category,
        parent_tag_id: parentId,
    });

    if (Array.isArray(orderIds)) {
        await sortCategories(orderIds);
    }
}

export async function editCategory(categoryId, params) {
    const normalizedParams = formatParams(params);

    const response = await axiosInstance.put("/providers/transactions/tags/" + categoryId, normalizedParams);

    if (!response?.data) return null;

    return mapDtoToCategory(response.data);
}

export async function createCategory(params) {
    const normalizedParams = formatParams(params);

    const response = await axiosInstance.post("/providers/transactions/tags", normalizedParams);

    if (!response?.data) return null;

    return mapDtoToCategory(response.data);
}

export async function deleteCategory(tagId) {
    await axiosInstance.delete("/providers/transactions/tags/" + tagId);
}

export async function applyCategorizeRetrospect(categoryId) {
    await axiosInstance.post(`/providers/transactions/tags/${categoryId}/retrospect`);
}