
import PropTypes from "prop-types";
import React from "react";

const NavContacts = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M12.818 14.068a4.023 4.023 0 0 1 4.023 4.023v1.636a.75.75 0 1 1-1.5 0v-1.636a2.523 2.523 0 0 0-2.523-2.523H6.273a2.523 2.523 0 0 0-2.523 2.523v1.636a.75.75 0 1 1-1.5 0v-1.636a4.023 4.023 0 0 1 4.023-4.023h6.545ZM9.545 4.25a4.023 4.023 0 1 0 0 8.045 4.023 4.023 0 0 0 0-8.045Zm0 1.5a2.523 2.523 0 1 1 0 5.045 2.523 2.523 0 0 1 0-5.045ZM17.82 14.737a.75.75 0 0 1 .913-.539 4.023 4.023 0 0 1 3.017 3.893v1.636a.75.75 0 1 1-1.5 0v-1.636c0-1.15-.779-2.153-1.892-2.44a.75.75 0 0 1-.539-.914ZM14.546 4.92a.75.75 0 0 1 .913-.54 4.023 4.023 0 0 1 0 7.794.75.75 0 0 1-.372-1.453 2.523 2.523 0 0 0 0-4.888.75.75 0 0 1-.54-.913Z"/>
	</g>

    </svg>
);

export default NavContacts;

NavContacts.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
