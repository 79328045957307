
import PropTypes from "prop-types";
import React from "react";

const InternetPhone = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="evenodd">
		<path d="M8.248 14.514a12.873 12.873 0 0 1 16.591 0 1 1 0 1 1-1.287 1.53 10.873 10.873 0 0 0-14.017 0 1 1 0 0 1-1.287-1.53Z" fill={color} fillRule="nonzero"/>
		<path d="M4.335 10.638a18.272 18.272 0 0 1 24.33 0 1 1 0 0 1-1.33 1.494 16.272 16.272 0 0 0-21.67 0 1 1 0 0 1-1.33-1.494ZM12.146 18.372a7.473 7.473 0 0 1 8.72 0 1 1 0 0 1-1.166 1.625 5.473 5.473 0 0 0-6.389 0 1 1 0 1 1-1.165-1.625Z" fill={color} fillRule="nonzero"/>
		<path d="M16.5 23.452h.011" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
	</g>

    </svg>
);

export default InternetPhone;

InternetPhone.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
