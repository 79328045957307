
import PropTypes from "prop-types";
import React from "react";

const Filter = ({ color, size, ...props }) => (
    <svg viewBox="0 0 18 18" width={size} height={size} {...props}>
        
	<path d="M.093 1.58A1 1 0 0 1 1 1h16a1 1 0 0 1 .764 1.646L11.6 9.934V16.4a1 1 0 0 1-1.447.894l-3.2-1.6A1 1 0 0 1 6.4 14.8V9.934L.236 2.646a1 1 0 0 1-.143-1.067ZM3.155 3l5.009 5.922a1 1 0 0 1 .236.646v4.614l1.2.6V9.568a1 1 0 0 1 .236-.646L14.845 3H3.155Z" fill={color}/>

    </svg>
);

export default Filter;

Filter.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
