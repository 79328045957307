import React, { useState } from "react";
import Button from "Components/Button";
import PropTypes from "prop-types";

export default function ServiceOut(props) {
    const { onClearConnection, onClose, info } = props;
    const [processing, setProcessing] = useState(false)

    const handleReject = async () => {
        setProcessing(true);
        await onClearConnection();
        setProcessing(false);
        onClose();
    }

    return (
        <div className="text-center">
            <img src="/img/icon-error.svg" alt="Icono de Error" />
            <h4 className="mt-4">Banco Caído</h4>
            <div className="text-muted">El banco al que estás intentando conectar no responde. Inténtalo de nuevo mañana.</div>

            {info.service_message && (
                <div className="mt-4">
                    <div className="text-very-small text-start text-muted mb-1">Mensaje del proveedor:</div>
                    <div className="alert alert-warning" role="alert">
                        {info.service_message}
                    </div>
                </div>
            )}

            <Button primary size="large" isBusy={processing} className="mt-4" onClick={handleReject}>Aceptar</Button>
        </div>
    )
}

ServiceOut.propTypes = {
    onClearConnection: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    info: PropTypes.object.isRequired,
};