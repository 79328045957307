import { useCallback, useMemo } from "react";
import { sessionStorage } from "Utils/storage";
import { trackError } from "Utils/errorMonitoring";

const KEY = "provider_connections_init";

export default function useProviderConnectionsCredentials() {
    const set = useCallback((conexionId, value) => {
        const storage = sessionStorage.getItem(KEY);
        const previous = storage ? JSON.parse(storage) : {};

        const next = {
            ...previous,
            [conexionId]: window.btoa(JSON.stringify(value)),
        }

        sessionStorage.setItem(KEY, JSON.stringify(next));
    }, []);

    const get = useCallback((conexionId) => {
        const s = sessionStorage.getItem(KEY);
        const previous = s ? JSON.parse(s) : {};

        const value = previous[conexionId];
        if (value !== undefined) {
            try {
                const decodeValue = window.atob(value);
                return JSON.parse(decodeValue);
            } catch (error) {
                trackError(`Error decoding credentials for conexionId: ${conexionId}`);

                delete previous[conexionId];
                sessionStorage.setItem(KEY, JSON.stringify(previous));
            }
        }
        return null
    }, []);

    const remove = useCallback((conexionId) => {
        const s = sessionStorage.getItem(KEY);
        const previous = s ? JSON.parse(s) : {};

        delete previous[conexionId];

        sessionStorage.setItem(KEY, JSON.stringify(previous));
    }, []);

    return useMemo(() => ({
        set,
        get,
        remove,
    }), [set, get, remove]);
}
