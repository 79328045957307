import React, { forwardRef, useId } from "react";
import PropTypes from "prop-types";
import classes from "./Switch.module.css";
import clsx from 'clsx';

const Switch = forwardRef(({ id, size, className, ...inputProps }, ref) => {
    const defaultId = useId();
    const inputId = id || defaultId;

    return (
        <div className={clsx(classes.switch, { [classes.small]: size === "small" }, className)}>
            <input ref={ref} className={classes.input} type="checkbox" id={inputId} {...inputProps} />
            <label className={classes.label} htmlFor={inputId}></label>
            <div className={classes.marker}></div>
        </div>
    )
});

export default Switch;

Switch.displayName = "Switch";

Switch.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(["small", "medium"]),
}