import React, { useRef, useState, useImperativeHandle, forwardRef } from "react";
import PropTypes from "prop-types";
import classes from "./Editable.module.css";
import clsx from "clsx";

const Editable = forwardRef(function Editable(props, ref) {
    const { defaultValue = "", onChange, size = "medium", className, error, ...otherProps } = props;
    const [content] = useState(defaultValue);
    const inputRef = useRef(null);

    useImperativeHandle(ref, () => ({
        setValue: (value) => {
            inputRef.current.innerText = value;
        },
    }));

    const onInput = e => {
        onChange(e.target.innerText.trim());
    };

    const onKeyDown = e => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            inputRef.current.blur();
        }
    }

    const onPaste = e => {
        e.preventDefault();
        const text = e.clipboardData.getData("text/plain");
        document.execCommand("insertHTML", false, text);
    }

    const handleClick = () => {
        inputRef.current.focus();

        const content = inputRef.current.innerText;
        if (!content) {
            // insert whitespace to make sure the cursor is visible and select
            document.execCommand("insertHTML", false, "&nbsp;");
            const selection = window.getSelection();
            const domRange = selection.getRangeAt(0);
            if (domRange) {
                domRange.setStart(inputRef.current, 0);
                domRange.setEnd(inputRef.current, 1);
            }
            return;
        }

        // move cursor to the end of the text
        const selection = window.getSelection();
        const domRange = selection.getRangeAt(0);
        if (domRange) {
            domRange.setStart(inputRef.current, inputRef.current.childNodes.length);
            domRange.setEnd(inputRef.current, inputRef.current.childNodes.length);
        }
    };

    return (
        <>
            <div className={clsx(classes.editable, className, { [classes.hasError]: error })}>
                <span
                    {...otherProps}
                    ref={inputRef}
                    contentEditable
                    className={clsx(classes.input, classes[size])}
                    onInput={onInput}
                    onKeyDown={onKeyDown}
                    onPaste={onPaste}
                    dangerouslySetInnerHTML={{ __html: content }}
                />

                <button type="button" onClick={handleClick} className={classes.editButton}>
                    <svg
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 16 16"
                    >
                        <g
                            stroke="#B7B7B7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        >
                            <path d="M10 14.121L15 14"></path>
                            <path
                                fillRule="evenodd"
                                d="M10.5 1.621a2.121 2.121 0 113 3l-8.5 8.5-4 1 1-4 8.5-8.5z"
                                clipRule="evenodd"
                            ></path>
                        </g>
                    </svg>
                </button>
            </div>
            {error && <span className={clsx(classes.errorMessage, "ms-3")}>{error}</span>}
        </>
    );
});

export default Editable;

Editable.propTypes = {
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.oneOf(["medium", "large"]),
    error: PropTypes.string,
};

