import { PlansAndPricingContext } from "Components/PlansAndPricingProvider";
import { useContext } from "react";

export default function usePlansAndPricingDialog() {
    const context = useContext(PlansAndPricingContext);

    if (!context) {
        throw new Error(
            `usePlansAndPricingDialog must be used within a PlansAndPricingProvider`
        );
    }

    return context;
}