import React from "react";
import SearchField from "Components/Search";
import UpgradeButton from "Components/UpgradeButton";
// import { ONBOARDING_TASKS } from "Utils/constants";
// import { useUser } from "Components/UserProvider/useUser";
import HeaderOnboarding from "Components/HeaderOnboarding";
import AppLayoutProfileMenu from "./AppLayoutProfileMenu";

export default function AppLayoutHeaderDesktop() {
    // const { user } = useUser();
    // const isCompletedOnboarding = useMemo(() => ONBOARDING_TASKS.every(({ id }) => user.activation_steps_completed.includes(id)), [user]);

    return (
        <div className="d-flex align-items-center gap-3">
            <SearchField className="flex-grow-1" />

            <div className="d-flex gap-3 align-items-center">
                <HeaderOnboarding />

                <AppLayoutProfileMenu className="flex-shrink-0" />

                <UpgradeButton />
            </div>
        </div>
    )
}