import React, { useId } from "react";
import {
    useFloating,
    useInteractions,
    useRole,
    useDismiss,
    FloatingPortal,
    FloatingOverlay,
    FloatingFocusManager,
} from "@floating-ui/react";

import PropTypes from "prop-types";
import dialogClasses from "./FullScreenDialog.module.css";
import clsx from "clsx";
import BackArrow from "./BackArrow";
import { FullScreenDialogProvider } from "./FullScreenDialogContext";
import Icon from "Components/Icon";
import useFloatingProps from "Hooks/useFloatingProps";

const FullScreenDialog = props => {
    const id = useId();
    const { open, onClose, children, onBack, backButtonProps, showCloseButton = false, size = "medium", className, zIndex = "var(--z-index-full-modal)", classes = {} } = useFloatingProps(props);

    const { refs, context } = useFloating({
        open,
        onOpenChange: (open) => {
            if (!open) onClose();
        },
    });

    const { getFloatingProps } = useInteractions([
        useRole(context, { role: "dialog" }),
        useDismiss(context),
    ]);

    const sizeContainers = {
        medium: false,
        small: dialogClasses.containerSmall,
        large: dialogClasses.containerLarge,
        extraLarge: dialogClasses.containerExtraLarge
    }

    return (
        <FloatingPortal>
            {open && (
                <FloatingOverlay lockScroll style={{ zIndex }}>
                    <FloatingFocusManager context={context}>
                        <div {...getFloatingProps({
                            ref: refs.setFloating,
                            className: clsx(dialogClasses.dialog, className),
                            "aria-labelledby": id,
                        })}
                        >
                            <FullScreenDialogProvider value={{ id }}>
                                <div className={dialogClasses.dialogContent}>
                                    <div className={dialogClasses.dialogHeader}>
                                        {onBack && (
                                            <button className={dialogClasses.backButton} type="button" onClick={onBack}>
                                                <BackArrow />
                                                {backButtonProps && (
                                                    <div className={dialogClasses.backInfo}>
                                                        <div className={dialogClasses.backLabel}>
                                                            {backButtonProps.label}
                                                        </div>
                                                        <div>
                                                            {backButtonProps.description}
                                                        </div>
                                                    </div>
                                                )}
                                            </button>
                                        )}
                                        {showCloseButton && (
                                            <div className={dialogClasses.dialogClose}>
                                                <button type="button" className={dialogClasses.dialogCloseButton} onClick={onClose}>
                                                    <Icon name="close-big" size={65} color="currentColor" />
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    <div className={clsx(dialogClasses.dialogContainer, sizeContainers[size], classes.container)}>
                                        {children}
                                    </div>
                                </div>
                            </FullScreenDialogProvider>
                        </div>
                    </FloatingFocusManager>
                </FloatingOverlay>
            )}
        </FloatingPortal>
    )
}

FullScreenDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    showCloseButton: PropTypes.bool,
    size: PropTypes.oneOf(["small", "medium", "large", "extraLarge"]),
    onBack: PropTypes.func,
    backButtonProps: PropTypes.shape({
        label: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }),
    className: PropTypes.string,
    zIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    classes: PropTypes.object,
}

export default FullScreenDialog;