import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
import styles from "./ItemSelector.module.css";
import Icon from "../Icon";
import SubscriptionBadgeRequired from "Components/SubscriptionBadgeRequired";
import Spinner from "Components/Spinner";

const ItemSelector = (props) => {
    const { onClick, image, title, description = "", className, requireSubscriptionType, classes = {}, icon, isBusy, customImage, ellipsis } = props;
    return (
        <button type="button" className={clsx(styles.handle, isBusy && styles.busy, className)} onClick={onClick}>
            <div className={clsx("d-flex align-items-center gap-4", styles.minWidth)}>
                {customImage ? customImage : (
                    image && <img src={image} width={50} height={50} alt="" className={classes.image} />
                )}
                <div className={clsx("d-flex flex-column", styles.minWidth)}>
                    <div className={clsx("d-flex align-items-center", styles.minWidth)}>
                        <h5 className={clsx("m-0", classes.title, ellipsis && styles.ellipsis)}>{title}</h5>
                        {requireSubscriptionType && <SubscriptionBadgeRequired type={requireSubscriptionType} />}
                    </div>
                    <div className={clsx("opacity-50 mt-1", classes.description)} style={{ lineHeight: "1.2" }}>{description}</div>
                </div>
            </div>
            {isBusy ? (
                <div className={classes.loading}><Spinner size={32} color="rgba(32,32,32, .5)" /></div>
            ) : (
                <div className="flex-shrink-0">
                    {icon ? icon : (
                        <div className={styles.arrow}>
                            <Icon name="arrow-right" size={40} gradient="secondary" />
                        </div>
                    )}
                </div>
            )}
        </button>
    );
};

ItemSelector.propTypes = {
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.string,
    description: PropTypes.string,
    className: PropTypes.string,
    requireSubscriptionType: PropTypes.string,
    classes: PropTypes.object,
    icon: PropTypes.node,
    isBusy: PropTypes.bool,
    customImage: PropTypes.node,
    ellipsis: PropTypes.bool,
};

export default ItemSelector;