import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classes from './IconButton.module.css';
import clsx from 'clsx';
import Tooltip from 'Components/Tooltip';


const IconButton = forwardRef((props, ref) => {
    const { className, children, href, target, disabled, type = "button", tooltip, ...htmlProps } = props
    const defaultRel = target === "_blank" ? "noopener" : undefined;

    const buttonClassName = clsx(
        classes.iconButton,
        className,
    );

    if (href) {
        if (tooltip != null) {
            return (
                <Tooltip label={tooltip}>
                    <a
                        ref={ref}
                        className={buttonClassName}
                        href={href}
                        target={target}
                        rel={defaultRel}
                        {...htmlProps}
                    >
                        {children}
                    </a>
                </Tooltip>
            )
        }
        return (
            <a
                ref={ref}
                className={buttonClassName}
                href={href}
                target={target}
                rel={defaultRel}
                {...htmlProps}
            >
                {children}
            </a>
        );
    }

    if (tooltip != null) {
        return (
            <Tooltip label={tooltip}>
                <button ref={ref} type={type} className={buttonClassName} disabled={disabled} {...htmlProps}>
                    {children}
                </button>
            </Tooltip>
        )
    }

    return (
        <button ref={ref} type={type} className={buttonClassName} disabled={disabled} {...htmlProps}>
            {children}
        </button>
    );
});

export default IconButton;

IconButton.displayName = 'IconButton';

IconButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    href: PropTypes.string,
    target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
    children: PropTypes.node,
    tooltip: PropTypes.string,
};