
import PropTypes from "prop-types";
import React from "react";

const Reverse = ({ color, size, ...props }) => (
    <svg viewBox="0 0 14 14" width={size} height={size} {...props}>
        
	<g clipPath="url(#a)">
		<path d="M5.53 1.53A.75.75 0 0 0 4.47.47l-4 4a.748.748 0 0 0 0 1.06l4 4a.75.75 0 0 0 1.06-1.06L2.81 5.75H9a3.25 3.25 0 0 1 0 6.5H6.6a.75.75 0 0 0 0 1.5H9a4.75 4.75 0 1 0 0-9.5H2.81l2.72-2.72Z" fill={color} fillRule="evenodd"/>
	</g>
	<defs>
		<clipPath id="a">
			<path d="M0 0h14v14H0z" fill={color}/>
		</clipPath>
	</defs>

    </svg>
);

export default Reverse;

Reverse.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
