
import PropTypes from "prop-types";
import React from "react";

const NavAutomations = ({ color, size, ...props }) => (
    <svg viewBox="0 0 28 28" width={size} height={size} {...props}>
        
	<path d="M20.98 1a1 1 0 0 1 .97.757l.313 1.248a3.068 3.068 0 0 0 2.232 2.232l1.248.312a1 1 0 0 1 0 1.94l-1.248.312a3.068 3.068 0 0 0-2.232 2.233l-.312 1.247a1 1 0 0 1-1.94 0l-.312-1.247A3.068 3.068 0 0 0 17.467 7.8L16.22 7.49a1 1 0 0 1 0-1.94l1.248-.312A3.068 3.068 0 0 0 19.7 3.005l.312-1.248A1 1 0 0 1 20.98 1Zm-1.51 5.52c.605.39 1.12.905 1.51 1.51.391-.605.907-1.12 1.512-1.51a5.073 5.073 0 0 1-1.511-1.511 5.072 5.072 0 0 1-1.51 1.51Zm-9.335-1.905a1 1 0 0 1 .961.726l.98 3.43a4.423 4.423 0 0 0 3.037 3.036l3.431.982a1 1 0 0 1 0 1.922l-3.43.98a4.423 4.423 0 0 0-3.049 3.076l-.969 3.392a1 1 0 0 1-1.923 0l-.98-3.43a4.423 4.423 0 0 0-3.037-3.036l-3.43-.982a1 1 0 0 1 0-1.922l3.429-.98a4.423 4.423 0 0 0 3.037-3.037l.981-3.432a1 1 0 0 1 .962-.725Zm0 4.64-.02.066a6.423 6.423 0 0 1-4.41 4.41l-.066.02.067.019a6.423 6.423 0 0 1 4.41 4.41l.019.064.018-.064.013-.042a6.423 6.423 0 0 1 4.399-4.37l.065-.018-.067-.02a6.423 6.423 0 0 1-4.41-4.41l-.019-.065Zm9.038 8.014a1 1 0 0 1 .949.684l.475 1.426c.17.51.571.912 1.082 1.082l1.425.475a1 1 0 0 1 0 1.897l-1.425.475c-.511.17-.912.572-1.082 1.083l-.475 1.425a1 1 0 0 1-1.898 0l-.474-1.425a1.712 1.712 0 0 0-1.083-1.083l-1.425-.475a1 1 0 0 1 0-1.897l1.425-.475c.511-.17.912-.571 1.083-1.082l.474-1.426a1 1 0 0 1 .95-.684Zm0 3.687a3.713 3.713 0 0 1-.928.929c.362.251.676.566.928.928.252-.362.566-.677.928-.928a3.711 3.711 0 0 1-.928-.929Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default NavAutomations;

NavAutomations.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
