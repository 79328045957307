
import PropTypes from "prop-types";
import React from "react";

const Close = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M18.951 6.414A1 1 0 1 0 17.537 5l-5.561 5.561-5.562-5.56A1 1 0 1 0 5 6.413l5.562 5.562L5 17.537a1 1 0 1 0 1.414 1.414l5.562-5.561 5.561 5.561a1 1 0 0 0 1.415-1.414l-5.562-5.561 5.561-5.562Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default Close;

Close.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
