
import PropTypes from "prop-types";
import React from "react";

const ModuleTable = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="evenodd">
		<path d="M21 2a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h19Zm0 2H2v16h19V4Z" fillRule="nonzero"/>
		<path d="M7 3h2v18H7zM14 3h2v18h-2z"/>
		<path d="M1 8h21v2H1zM1 14h21v2H1z"/>
	</g>

    </svg>
);

export default ModuleTable;

ModuleTable.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
