import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import classes from "./AvatarUser.module.css";
import stc from "string-to-color";

export default function AvatarUser(props) {
    const { className, name = "", size = 50 } = props;

    const backgroundColor = stc(name);
    const initials = typeof name === "string"
        ? name.split(" ")
            .map((word) => word[0])
            .slice(0, 2)
            .join("")
        : "-";

    return (
        <div className={clsx(classes.avatarUser, className)} style={{ backgroundColor, width: size }}>
            {initials}
        </div>
    );
}

AvatarUser.propTypes = {
    name: PropTypes.string,
    size: PropTypes.number,
    className: PropTypes.string,
};