
import PropTypes from "prop-types";
import React from "react";

const Send = ({ color, size, ...props }) => (
    <svg viewBox="0 0 18 18" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M16.47.47a.75.75 0 0 1 1.06 1.06l-8.8 8.8a.75.75 0 0 1-1.06-1.06l8.8-8.8Z"/>
		<path d="m16.752.292-16 5.6-.107.046C.11 6.22.111 7.026.695 7.285l6.936 3.083 3.084 6.937a.75.75 0 0 0 1.393-.057l5.6-16a.75.75 0 0 0-.956-.956Zm-.974 1.929-4.461 12.746-2.432-5.472-.052-.097a.75.75 0 0 0-.328-.283L3.032 6.682l12.746-4.461Z"/>
	</g>

    </svg>
);

export default Send;

Send.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
