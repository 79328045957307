import React from "react";
import classes from "./PriceTable.module.css";
import clsx from "clsx";
import Button from "Components/Button";
import { useTranslation } from "react-i18next";
import finacialAdvisorIcon from "./assets/financial-advisor.svg";

const CONTACT_URL = "https://share-eu1.hsforms.com/1BRtJRXJ9TWalGjajhtBOHQ2e98n3";

export default function CardEnterprise() {
    const { t } = useTranslation("pricing")

    return (
        <div className={clsx(classes.planCard, classes.selfStart)}>
            <div className={classes.planCardHeader}>
                <div className={classes.planCardContent}>
                    <div className={clsx(classes.planCardTitle, classes.gradientTextTertiary)}>
                        {t("enterprise.title")}
                    </div>

                    <div className={clsx(classes.priceSubtitle, "text-muted")}>{t("enterprise.description")}</div>

                    <div className="pt-4">
                        <div className={classes.prefixPrice}>
                            {t("enterprise.from")}
                        </div>

                        <div className="d-flex align-items-start gap-1">
                            <strong>€</strong>
                            <strong className={classes.priceWhole}>299</strong>
                            <span>/{t("month")}</span>
                        </div>
                    </div>
                </div>

                <div className={classes.line} />

                <div className={classes.planCardContent}>
                    <div className="text-muted" style={{ fontSize: 15 }}>
                        {t("enterprise.explanation")}
                    </div>

                    <div className="pt-5">
                        <div className={classes.caption}>
                            {t("enterprise.includes")}
                        </div>
                        <ul className={classes.list}>
                            <li className="d-flex align-items-center gap-2">
                                <div className="flex-shrink-1">
                                    <img src={finacialAdvisorIcon} alt="" className={classes.featureIcon} />
                                </div>
                                <div>
                                    <div className={classes.itemTitle}>
                                        {t("enterprise.financial-advisor")}
                                    </div>
                                    <div className={classes.itemDescription}>
                                        {t("enterprise.financial-advisor-description")}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={classes.planCardAction}>
                <Button variant="outlined" target="_blank" className="w-100" rel="nofollow" href={CONTACT_URL} size="large">
                    {t("enterprise.contact")}
                </Button>
            </div>
        </div>
    )
}
