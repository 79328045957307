import React, { useEffect } from "react";
import PropTypes from "prop-types";
import MaterialDrawer from "@mui/material/Drawer";
import clsx from "clsx";
import classes from "./Drawer.module.css";
import useFloatingProps from "Hooks/useFloatingProps";

export * from "./DrawerContent";
export * from "./DrawerHeader";
export * from "./DrawerFooter";

export default function Drawer(props) {
    const { open, className, children, onClose, size = "md", anchor = "right" } = useFloatingProps(props);

    useEffect(() => {
        if (!open) return;

        const handleEscape = (e) => {
            if (e.key === 'Escape') onClose();
        };

        document.addEventListener('keydown', handleEscape);
        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [open]);

    return (
        <MaterialDrawer
            disableEnforceFocus
            anchor={anchor}
            open={open}
            onClose={onClose}
            slotProps={{
                backdrop: {
                    sx: {
                        backgroundColor: "rgba(0, 0, 0, 0)"
                    }
                }
            }}
            PaperProps={{
                className: clsx(classes.paperDrawer, classes[size], className),
                sx: {
                    boxShadow: "var(--shadow-lg)",
                }
            }}
        >
            {children}
        </MaterialDrawer>
    )
}

Drawer.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    className: PropTypes.string,
    children: PropTypes.node,
    size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
    anchor: PropTypes.oneOf(["left", "right"]),
}