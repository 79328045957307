import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { useEffect, useLayoutEffect, useState } from "react";
import { getAutomations, sortAutomations } from "Services/automations";
import { getIntegrations } from "Services/integrations";
import { getProvidersConnections } from "Services/providersConnections";
import { trackError } from "Utils/errorMonitoring";

export default function useAutomation() {
    const { data, isLoading, isFetching } = useQuery(["automations"], getAutomations, {
        onError: error => trackError(error),
    })
    const queryClient = useQueryClient();

    // fix flicker when sorting
    const [automations, setAutomations] = useState([]);

    useLayoutEffect(() => {
        if (!isLoading) {
            setAutomations(data);
        }
    }, [data, isLoading]);

    useEffect(() => {
        queryClient.prefetchQuery(["integrations"], getIntegrations);
        queryClient.prefetchQuery(["providers-connections"], getProvidersConnections);
    }, [queryClient]);

    const { mutate: sort } = useMutation(sortAutomations, {
        onMutate: (ids) => {
            const newAutomations = ids
                .map(id => automations.find(item => item.id === id))
                .filter(Boolean)
                .map((item, index) => ({ ...item, order: index + 1 }));

            setAutomations(newAutomations);
            queryClient.setQueryData(["automations"], newAutomations);
        }
    });

    return {
        automations, isLoading, sort, isFetching
    }
}