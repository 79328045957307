
import PropTypes from "prop-types";
import React from "react";

const Restaurants = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M15 2a1 1 0 0 1 .993.883L16 3v5.887c0 2.527-1.325 4.385-3.337 5.263l-.084.034 1.33 12.319c.086 1.87-.998 3.396-3.186 3.492L10.5 30c-2.24 0-3.337-1.338-3.407-3.195l-.004-.194.004-.131.856-12.294-.077-.034c-1.767-.82-2.794-2.545-2.868-4.98L5 8.886V3a1 1 0 0 1 1.993-.117L7 3v5.887c0 2.101.8 3.254 2.26 3.647a1 1 0 0 1 .739.917l-.001.119-.907 13.002-.002.062c0 .93.333 1.366 1.411 1.366 1.01 0 1.407-.46 1.417-1.208l-.002-.135-1.41-13.05a1 1 0 0 1 .735-1.073c1.61-.434 2.672-1.613 2.755-3.418l.005-.23V3a1 1 0 0 1 1-1Z"/>
		<path d="M9 2a1 1 0 0 1 .993.883L10 3v6a1 1 0 0 1-1.993.117L8 9V3a1 1 0 0 1 1-1ZM12 2a1 1 0 0 1 .993.883L13 3v6a1 1 0 0 1-1.993.117L11 9V3a1 1 0 0 1 1-1ZM25.979 2l-.217.005A6.005 6.005 0 0 0 20 8v8l.007.117a1 1 0 0 0 .876.876l.019.001-.894 9.495-.007.17C19.979 28.546 21.169 30 23.455 30c2.34 0 3.455-1.414 3.455-3.333l-.003-.108.076-10.385.01-.057A1.01 1.01 0 0 0 27 16V3a1 1 0 0 0-1.021-1ZM25 4.126v11.705l-.007.046a1.01 1.01 0 0 0-.008.116l-.077 10.582.002.116c0 .88-.338 1.309-1.455 1.309-1.08 0-1.464-.47-1.455-1.299l.003-.089.993-10.518.004-.116a1 1 0 0 0-.884-.971L22 15V8a4.003 4.003 0 0 1 2.863-3.836L25 4.126Z"/>
	</g>

    </svg>
);

export default Restaurants;

Restaurants.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
