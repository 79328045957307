
import PropTypes from "prop-types";
import React from "react";

const Search2 = ({ color, size, ...props }) => (
    <svg viewBox="0 0 19 19" width={size} height={size} {...props}>
        
	<path d="M7.666 0a7.666 7.666 0 0 1 5.825 12.649l4.236 4.402a1 1 0 0 1-1.442 1.386l-4.28-4.45A7.666 7.666 0 1 1 7.666 0Zm0 2a5.666 5.666 0 1 0 0 11.332A5.666 5.666 0 0 0 7.666 2Z" fill={color} fillRule="nonzero"/>

    </svg>
);

export default Search2;

Search2.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
