import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import TextFieldBase from "../TextFieldBase";

const TextField = forwardRef(({ type, multiline,component,...htmlProps}, ref) => {
  if (multiline) {
    return (
      <TextFieldBase
        ref={ref}
        component={component ?? "textarea"}
        {...htmlProps}
      />
    );
  }

  return (
    <TextFieldBase
      ref={ref}
      type={type}
      component={component}
      {...htmlProps}
    />
  );
});

export default TextField;

TextField.displayName = "TextField";

TextField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  onChange: PropTypes.func,
  multiline: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  id: PropTypes.string,
  endAdornment: PropTypes.node,
  startAdornment: PropTypes.node,
  helperText: PropTypes.node,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(["medium", "large"]),
  disabled: PropTypes.bool,
};
