import React from "react";

const BackArrow = () => (
    <svg
      width="89"
      height="71"
      fill="none"
      viewBox="0 0 89 71"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.1 32.216L.27 35.032 3.1 37.86l32.258 32.27 2.828-2.828L7.828 36.944H89v-4H8.015L38.13 2.828 35.303 0 3.1 32.216z"
        clipRule="evenodd"
      ></path>
    </svg>
);


export default BackArrow;
