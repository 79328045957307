
import React from 'react';
import Loading from 'Components/Loading';
import PropTypes from 'prop-types';
import ProviderConnectionConnect from "Sections/ProviderConnectionConnect";
import useProviderConnector from 'Hooks/useProviderConnector';

export default function ProviderConnectionConnectDialogContent(props) {
    const { providerConnection, onConnect, onCancel } = props;
    const { connector, isLoading } = useProviderConnector(providerConnection?.provider_connector_id)

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center py-4">
                <Loading />
            </div>
        )
    }

    return (
        <ProviderConnectionConnect
            onConnect={onConnect}
            onError={onCancel}
            onCancel={onCancel}
            connector={connector}
            providerConnectionId={providerConnection?.id}
        />
    );
}

ProviderConnectionConnectDialogContent.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConnect: PropTypes.func.isRequired,
    providerConnection: PropTypes.object
}