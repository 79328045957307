import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classes from './Button.module.css';
import clsx from 'clsx';
import Spinner from 'Components/Spinner';
import Icon from 'Components/Icon';
import Tooltip from 'Components/Tooltip';

const Button = forwardRef((props, ref) => {
  const { className, primary, size, icon, children, href, target, isBusy = false, variant = "contained", disabled, rounded = "pill", tooltip, ...htmlProps } = props;
  const modeClassName = primary ? classes["bt-button--primary"] : classes['bt-button--secondary'];
  const variantClassName = variant === "contained" ? classes["bt-button--contained"] : classes["bt-button--outlined"];
  const defaultRel = target === "_blank" ? "noopener" : undefined;

  const isDisabled = isBusy || disabled;
  const iconSize = ['narrow', 'small'].includes(size) ? 16 : 18;

  const buttonClassName = clsx(
    classes['bt-button'],
    classes[`bt-button--${size}`],
    classes[`bt-button--rounded-${rounded}`],
    { [classes['bt-button--busy']]: isBusy },
    variantClassName,
    modeClassName,
    className,
  );

  const component = href ? (
    <a
      ref={ref}
      className={buttonClassName}
      href={href}
      target={target}
      rel={defaultRel}
      aria-busy={isBusy}
      {...htmlProps}
    >
      {isBusy && <span className={classes['bt-button__loading']}><Spinner size={24} /></span>}
      {icon && <Icon name={icon} size={iconSize} color="currentColor" className={classes['bt-button__icon']} />}
      <span className={clsx(classes["bt-button__content"], { "font-family-titles": size === "large" })}>{children}</span>
    </a>
  ) : (
    <button ref={ref} className={buttonClassName} disabled={isDisabled} {...htmlProps}>
      {isBusy && <span className={classes['bt-button__loading']}><Spinner size={24} /></span>}
      {icon && <Icon name={icon} size={iconSize} color="currentColor" className={classes['bt-button__icon']} />}
      <span className={clsx(classes["bt-button__content"], { "font-family-titles": size === "large" })}>{children}</span>
    </button>
  );

  if (tooltip) {
    return (
      <Tooltip label={tooltip}>
        {component}
      </Tooltip>
    );
  }

  return component;
});

export default Button;

Button.displayName = 'Button';

Button.propTypes = {
  primary: PropTypes.bool,
  size: PropTypes.oneOf(['narrow', 'small', 'medium', "large"]),
  icon: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  href: PropTypes.string,
  target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
  children: PropTypes.node,
  isBusy: PropTypes.bool,
  variant: PropTypes.oneOf(['contained', 'outlined']),
  rounded: PropTypes.oneOf(['small', 'pill']),
  iconSize: PropTypes.number,
  tooltip: PropTypes.string,
};

Button.defaultProps = {
  primary: false,
  size: 'medium',
  type: 'button',
};
