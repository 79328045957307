import React, { useState } from "react";
import classes from "./ChangeWorkspaceButton.module.css";
import useProjectSelected from "Hooks/useProjectSelected";
import Icon from "Components/Icon";
import ChangeWorkspaceSelectedModal from "./ChangeWorkspaceSelectedModal";
import clsx from "clsx";
import PropTypes from "prop-types";
import SelectProjectModal from "./SelectProjectModal";
import { useTranslation } from "react-i18next";

export default function ChangeWorkspaceButton(props) {
    const { className } = props;
    const { projectWorkspace } = useProjectSelected();
    const [showChangeWorkspaceSelectedModal, setShowChangeWorkspaceSelectedModal] = useState(false);
    const [showSelectProjectModal, setShowSelectProjectModal] = useState(false);
    const [projectSelectedId, setProjectSelectedId] = useState(null);
    const { t } = useTranslation("common");

    return (
        <>
            <SelectProjectModal
                open={showSelectProjectModal}
                onClose={() => setShowSelectProjectModal(false)}
                onSelect={(projectId) => {
                    setShowSelectProjectModal(false)
                    setShowChangeWorkspaceSelectedModal(true)
                    setProjectSelectedId(projectId)
                }}
            />

            <ChangeWorkspaceSelectedModal
                projectId={projectSelectedId}
                open={showChangeWorkspaceSelectedModal}
                onClose={() => setShowChangeWorkspaceSelectedModal(false)}
            />

            <button type="button" className={clsx(classes.buttonWorkspace, className)} onClick={() => setShowSelectProjectModal(true)}>
                <div>
                    <div className={classes.buttonWorkspaceLabel}>{t("workspace")}</div>
                    <div className={classes.buttonWorkspaceName}>{projectWorkspace?.name}</div>
                </div>
                <div className={classes.buttonWorkspaceIcon}>
                    <Icon name="switch" size={24} color="currentColor" />
                </div>
            </button>
        </>
    )
}

ChangeWorkspaceButton.propTypes = {
    className: PropTypes.string,
}