
import PropTypes from "prop-types";
import React from "react";

const Bank = ({ color, size, ...props }) => (
    <svg viewBox="0 0 16 16" width={size} height={size} {...props}>
        
	<path d="M3 7.25a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0V8A.75.75 0 0 1 3 7.25Zm5 0a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0V8A.75.75 0 0 1 8 7.25Zm5 0a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0V8a.75.75 0 0 1 .75-.75ZM7.628.349 1.28 3.975a.95.95 0 0 0 .472 1.775h12.493a.95.95 0 0 0 .472-1.775L8.372.35a.75.75 0 0 0-.744 0ZM8 1.864l4.176 2.386H3.823L8 1.864Zm6 11.386a.75.75 0 0 1 0 1.5H2a.75.75 0 0 1 0-1.5h12Z" fill={color}/>

    </svg>
);

export default Bank;

Bank.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
