import React from "react";
import PropTypes from "prop-types";
import { SnackbarProvider as NotistackProvider } from "notistack";
import SnackbarProviderConnectionSynchronize from "./SnackbarProviderConnectionSynchronize";
import SnackbarProviderConnectionSuccess from "./SnackbarProviderConnectionSuccess";
import SnackbarProviderConnectionError from "./SnackbarProviderConnectionError";
import SnackbarProviderConnectionVerify from "./SnackbarProviderConnectionVerify";
import SnackbarProviderConnectionRequestOtp from "./SnackbarProviderConnectionRequestOtp";
import SnackbarUpload from "./SnackbarUpload";
import SnackbarUploadSuccess from "./SnackbarUploadSuccess";
import SnackbarUploadError from "./SnackbarUploadError";
import SnackbarProviderConnectionCreationSuccess from "./SnackbarProviderConnectionCreationSuccess";
import SnackbarProviderConnectionCreating from "./SnackbarProviderConnectionCreating";

export default function SnackbarProvider({ children }) {
    return (
        <NotistackProvider
            preventDuplicate
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            Components={{
                providerConnectionSynchronize: SnackbarProviderConnectionSynchronize,
                providerConnectionSuccess: SnackbarProviderConnectionSuccess,
                providerConnectionError: SnackbarProviderConnectionError,
                providerConnectionVerify: SnackbarProviderConnectionVerify,
                providerConnectionRequestOtp: SnackbarProviderConnectionRequestOtp,
                upload: SnackbarUpload,
                uploadSuccess: SnackbarUploadSuccess,
                uploadError: SnackbarUploadError,
                providerConnectionCreationSuccess: SnackbarProviderConnectionCreationSuccess,
                providerConnectionCreating: SnackbarProviderConnectionCreating,
            }}
        >
            {children}
        </NotistackProvider>
    );
}

SnackbarProvider.propTypes = {
    children: PropTypes.node.isRequired,
};