
import PropTypes from "prop-types";
import React from "react";

const Uncategorized = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M16 4C9.373 4 4 9.373 4 16s5.373 12 12 12 12-5.373 12-12S22.627 4 16 4Zm0 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S6 21.523 6 16 10.477 6 16 6Z"/>
		<path d="M22.269 8.318a1 1 0 0 1 1.542 1.267l-.08.097-14 15a1 1 0 0 1-1.542-1.267l.08-.097 14-15Z"/>
	</g>

    </svg>
);

export default Uncategorized;

Uncategorized.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
