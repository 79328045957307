import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { setCookie, getCookie } from "Utils/cookies";
import { COOKIES } from "Utils/constants";

const ONE_MONTH = 30 * 24 * 60 * 60 * 1000;
const DEFAULT_UTM_PARAMS = {
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_content: null,
    utm_term: null
}

const normalizeUTMParams = (params = {}) => Object.assign({}, DEFAULT_UTM_PARAMS, params);

const useUTMParams = () => {
    const [searchParams] = useSearchParams();
    const [utmParams, setUTMParams] = useState(() => {
        const cookie = getCookie(COOKIES.UTM_PARAMS);
        return normalizeUTMParams(cookie);
    });

    const utm_source = searchParams.get("utm_source");
    const utm_medium = searchParams.get("utm_medium");
    const utm_campaign = searchParams.get("utm_campaign");
    const utm_content = searchParams.get("utm_content");
    const utm_term = searchParams.get("utm_term");

    useEffect(() => {
        const cookie = getCookie(COOKIES.UTM_PARAMS);
        const params = { utm_source, utm_medium, utm_campaign, utm_content, utm_term }
        const hasUTMValues = Object.values(params).some(Boolean);

        if (hasUTMValues && !cookie) {
            setCookie(COOKIES.UTM_PARAMS, params, ONE_MONTH);

            const normalizedParams = normalizeUTMParams(params);
            setUTMParams(normalizedParams);
        }

    }, [utm_source, utm_medium, utm_campaign, utm_content, utm_term]);

    return utmParams;
}

export default useUTMParams;