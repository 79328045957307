
import PropTypes from "prop-types";
import React from "react";

const Back = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M16.707 1.293a1 1 0 0 1 0 1.414L7.414 12l9.293 9.293a1 1 0 0 1-1.414 1.414l-10-10a1 1 0 0 1 0-1.414l10-10a1 1 0 0 1 1.414 0Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default Back;

Back.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
