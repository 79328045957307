import { rest } from 'msw'
import { API_HOST } from 'config'
import suggestions from "./fixtures/suggestions.json"

const suggestionsHandlers = [
    rest.get(`${API_HOST}/providers/transactions/categories_suggestions`, (req, res, ctx) => {
        return res(
            ctx.json(suggestions),
        )
    }),
]

export default suggestionsHandlers