
import PropTypes from "prop-types";
import React from "react";

const House = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="evenodd">
		<path d="M19 2a8 8 0 0 1 7.996 7.75L27 10v2h-2v-2a6 6 0 0 0-5.775-5.996L19 4h-6a6 6 0 0 0-5.996 5.775L7 10v2H5v-2a8 8 0 0 1 7.75-7.996L13 2h6ZM20 14h-8a4 4 0 0 0-4 4v2h16v-2a4 4 0 0 0-4-4Zm0 2 .15.005A2 2 0 0 1 22 18H10a2 2 0 0 1 2-2h8Z" fill={color} fillRule="nonzero"/>
		<path d="M9 25v2H8v-2h1ZM23 25v2h1v-2h-1Z" stroke={color} strokeWidth="2"/>
		<path d="M26 11a4 4 0 0 0-3.993 3.766L22 14.97V18H10v-3l-.006-.205A4.006 4.006 0 0 0 6 11a4 4 0 0 0-4 4l.006.212a4.001 4.001 0 0 0 1.913 3.205l.081.046V22l.006.186C4.123 23.785 5.426 25 7 25h18l.176-.005A3.001 3.001 0 0 0 28 22v-3.537l.082-.047A4 4 0 0 0 26 11Zm0 2a2 2 0 0 1 .667 3.886l-.666.236V22A1 1 0 0 1 25 23H7a1 1 0 0 1-.997-.924l-.004-.113.001-4.84-.667-.237a2.001 2.001 0 1 1 2.664-2.004l.003.147V20h16v-5l.005-.147C24.065 13.828 24.939 13 26 13Z" fill={color} fillRule="nonzero"/>
	</g>

    </svg>
);

export default House;

House.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
