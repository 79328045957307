import { useQuery } from "@tanstack/react-query";
import { getForecasts } from "Services/forecasts";
import { trackError } from "Utils/errorMonitoring";

export default function useForecasts() {
    const { data: forecasts = [], isLoading } = useQuery(["forecasts"], getForecasts, {
        onError: error => trackError(error)
    })

    return {
        forecasts, isLoading
    }
}