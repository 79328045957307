
import PropTypes from "prop-types";
import React from "react";

const NavDocs = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="m15.087 25.934.006.181c0 1.18 1.814 1.18 1.814 0V9.603C16.907 6.508 14.335 4 11.163 4H3.907c-.5 0-.907.396-.907.885v17.692c0 .488.406.885.907.885h8.465c1.44 0 2.619 1.09 2.715 2.472ZM4.814 21.692V5.77h6.349l.223.006c2.067.113 3.707 1.783 3.707 3.828v12.975l-.116-.084a4.593 4.593 0 0 0-2.605-.802H4.814Z"/>
		<path d="M15.093 9.603v16.512c0 1.18 1.814 1.18 1.814 0 0-1.465 1.218-2.653 2.72-2.653h8.466c.5 0 .907-.397.907-.885V4.885A.896.896 0 0 0 28.093 4h-7.256c-3.172 0-5.744 2.508-5.744 5.603Zm12.093-3.834v15.923h-7.558l-.24.006a4.585 4.585 0 0 0-2.365.796l-.116.083V9.603c0-2.118 1.76-3.834 3.93-3.834h6.349Z"/>
	</g>

    </svg>
);

export default NavDocs;

NavDocs.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
