
import PropTypes from "prop-types";
import React from "react";

const CircleInfo = ({ color, size, ...props }) => (
    <svg viewBox="0 0 28 28" width={size} height={size} {...props}>
        
	<path d="M13.995 2.33A11.665 11.665 0 1 0 25.66 13.995 11.676 11.676 0 0 0 13.995 2.33Zm-.224 5.384a1.346 1.346 0 1 1 0 2.691 1.346 1.346 0 0 1 0-2.691Zm1.121 12.562h-.897a.897.897 0 0 1-.897-.897v-5.384a.897.897 0 0 1 0-1.795h.897a.897.897 0 0 1 .897.898v5.383a.897.897 0 1 1 0 1.795Z" fill={color}/>

    </svg>
);

export default CircleInfo;

CircleInfo.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
