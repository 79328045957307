
import PropTypes from "prop-types";
import React from "react";

const Gifts = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M26 15a1 1 0 0 1 .993.883L27 16v11a1 1 0 0 1-.883.993L26 28H7a1 1 0 0 1-.993-.883L6 27V16a1 1 0 0 1 1.993-.117L8 16v10h17V16a1 1 0 0 1 .883-.993L26 15Z"/>
		<path d="M28 9a1 1 0 0 1 .993.883L29 10v5a1 1 0 0 1-.883.993L28 16H16.033a1 1 0 0 1-.117-1.993l.117-.007H27v-3H6v3h3.957a1 1 0 0 1 .993.883l.007.117a1 1 0 0 1-.884.993L9.957 16H5a1 1 0 0 1-.993-.883L4 15v-5a1 1 0 0 1 .883-.993L5 9h23Z"/>
		<path d="M16.5 8.75a1 1 0 0 1 .993.883l.007.117V26.5a1 1 0 0 1-1.993.117L15.5 26.5V9.75a1 1 0 0 1 1-1Z"/>
		<path d="M11.325 3a3.875 3.875 0 1 0 0 7.75H16.5a1 1 0 0 0 .997-1.08l-.026-.163-.023-.1a11.691 11.691 0 0 0-1.393-3.43C14.918 4.131 13.352 3 11.325 3Zm0 2c1.229 0 2.221.717 3.028 2.026l.182.311c.234.42.43.856.59 1.284l.045.128-3.845.001a1.875 1.875 0 1 1 0-3.75Z"/>
		<path d="M21.675 3c-2.027 0-3.593 1.132-4.73 2.976A11.68 11.68 0 0 0 15.553 9.4l-.034.154a1 1 0 0 0 .981 1.196h5.175a3.875 3.875 0 0 0 0-7.75Zm0 2c1.036 0 1.875.84 1.875 1.875l-.006.147a1.875 1.875 0 0 1-1.869 1.728l-3.846-.001.046-.13a9.2 9.2 0 0 1 .772-1.593C19.454 5.717 20.447 5 21.675 5Z"/>
		<path d="M16 9h-6a1 1 0 0 0-1 1v10l.006.112a1 1 0 0 0 1.549.72L13 19.202l2.445 1.63A1 1 0 0 0 17 20V10a1 1 0 0 0-1-1Zm-1 2v7.131l-1.445-.963-.116-.066a1 1 0 0 0-.994.066L11 18.13V11h4Z"/>
	</g>

    </svg>
);

export default Gifts;

Gifts.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
