
import PropTypes from "prop-types";
import React from "react";

const Undo = ({ color, size, ...props }) => (
    <svg viewBox="0 0 20 20" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M7.22 3.47a.75.75 0 0 1 1.06 1.06L5.061 7.75l3.22 3.22a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-1.06 0L3.47 8.28a.75.75 0 0 1 0-1.06l3.75-3.75Z"/>
		<path d="M13 7a3.75 3.75 0 0 1 3.75 3.75V16a.75.75 0 1 1-1.5 0v-5.25A2.25 2.25 0 0 0 13 8.5H4A.75.75 0 0 1 4 7h9Z"/>
	</g>

    </svg>
);

export default Undo;

Undo.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
