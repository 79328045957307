
import PropTypes from "prop-types";
import React from "react";

const ArrowRightSmall = ({ color, size, ...props }) => (
    <svg viewBox="0 0 28 28" width={size} height={size} {...props}>
        
	<path d="m19.348 15.551-4.481 4.482c-1.288 1.287.643 3.218 1.93 1.931l4.082-4.088 2.725-2.729a1.362 1.362 0 0 0 0-1.92l-6.806-6.815A1.362 1.362 0 0 0 15.81 6c-1.226 0-1.828 1.493-.944 2.343l4.492 4.482H5.355c-1.888.094-1.747 2.82.07 2.726h13.923Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default ArrowRightSmall;

ArrowRightSmall.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
