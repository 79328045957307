import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Dialog from "Components/Dialog";
import classes from "./CategorizerModal.module.css";
import Button from "Components/Button";
import CategorizerSlice from "./CategorizerSlice";
import { AnimatePresence } from "framer-motion";
import clsx from "clsx";
import useCategories from "Hooks/useCategories";
import Loading from "Components/Loading";
import useCategoriesSuggestions from "Hooks/useCategoriesSuggestions";
import Icon from "Components/Icon";
import IconButton from "Components/IconButton";
import { useTranslation } from "react-i18next";

export default function CategorizerModal(props) {
    const { open, onClose, categoriesSuggestions: initialCategoriesSuggestions } = props;
    const [index, setIndex] = useState(0);
    const { isLoading } = useCategories();
    const { applysuggestion } = useCategoriesSuggestions();
    const [categoriesSuggestions, setCategoriesSuggestions] = useState(initialCategoriesSuggestions);

    const { t } = useTranslation("common");

    useEffect(() => {
        if (!open) return;

        setIndex(0);
        setCategoriesSuggestions(initialCategoriesSuggestions);
    }, [open])

    const current = categoriesSuggestions[index];
    const next = categoriesSuggestions[index + 1] ?? null;
    const finish = categoriesSuggestions.length === index;

    const handleCategorize = (action) => {
        applysuggestion({ id: current.id, action, categoryId: current.transaction_category_id, transactionId: current.transaction.id })
        setCategoriesSuggestions(categoriesSuggestions.map((category) => ({
            ...category,
            response: category.id === current.id ? action : category.response
        })
        ));
        setIndex(index + 1);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            className={classes.dialog}
            title={(
                <div className="d-flex align-items-center">
                    <IconButton className={classes.paginationButton} onClick={() => setIndex(index - 1)} disabled={index === 0}><Icon name="arrow-left-small" size={24} color="currentColor" /></IconButton>
                    <span className={classes.paginationText}>
                        {`${Math.min(index + 1, categoriesSuggestions.length)} ${t("of")} ${categoriesSuggestions.length}`}
                    </span>
                    <IconButton className={classes.paginationButton} onClick={() => setIndex(index + 1)} disabled={index >= categoriesSuggestions.length - 1}><Icon name="arrow-right-small" size={24} color="currentColor" /></IconButton>
                </div>
            )}
        >
            {isLoading && (<div className="py-5"><Loading /></div>)}

            {!isLoading && finish && (
                <div className={classes.content}>
                    <div className="py-4 mb-4 d-flex flex-column align-items-center">
                        <h4 className="mb-2">{t("congratulations")}</h4>
                        <p className="text-muted mb-4">
                            {t("categorizer.finished")}
                        </p>
                        <Button primary size="large" onClick={onClose}>
                            {t("accept")}
                        </Button>
                    </div>
                </div>
            )}

            {!isLoading && !finish && (
                <div className={classes.content}>
                    <div className={clsx(classes.relative, classes.group)}>
                        <AnimatePresence>
                            {next && <CategorizerSlice className={classes.prevSlice} key={`next-${next.id}`} disabled onCategorize={handleCategorize} {...next} />}
                            <CategorizerSlice key={`current-${current.id}`} onCategorize={handleCategorize} {...current} />
                        </AnimatePresence>
                    </div>
                </div>
            )}
        </Dialog>
    )
}

CategorizerModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    categoriesSuggestions: PropTypes.array.isRequired,
}