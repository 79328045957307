
import PropTypes from "prop-types";
import React from "react";

const CloseBig = ({ color, size, ...props }) => (
    <svg viewBox="0 0 65 65" width={size} height={size} {...props}>
        
	<path d="M4.242 1.414a2 2 0 1 0-2.828 2.829l28.647 28.646L1.413 61.536a2 2 0 0 0 2.83 2.829L32.89 35.717l28.646 28.647a2 2 0 0 0 2.83-2.828L35.716 32.889 64.364 4.243a2 2 0 0 0-2.829-2.828L32.89 30.06 4.242 1.414Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default CloseBig;

CloseBig.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
