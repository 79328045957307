import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classes from "./Badge.module.css";
import clsx from "clsx";

const Badge = forwardRef(function Badge(props, ref) {
    const { className, children, color = "black", size = "medium", ...htmlProps } = props;

    return (
        <span ref={ref} className={clsx(classes.badge, classes[color], classes[size], className)} {...htmlProps}>
            {children}
        </span>
    )
})

export default Badge;

Badge.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    color: PropTypes.oneOf(["success", "danger", "black", "warning"]),
    size: PropTypes.oneOf(["small", "medium"]),
}