
import PropTypes from "prop-types";
import React from "react";

const Refresh = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M20.82 14.06c-.52-.18-1.09.09-1.28.61a7.938 7.938 0 0 1-5.78 5.13c-2.7.61-5.47-.19-7.45-2.17L3.51 15h3.48c.55 0 1-.45 1-1s-.45-1-1-1H.98c-.08 0-.16.03-.24.05-.05.01-.1.01-.14.03-.04.02-.07.05-.1.07-.08.05-.16.09-.22.15H.27c-.03.03-.04.08-.07.11-.05.07-.1.13-.13.2s-.03.15-.05.22c0 .05-.03.09-.03.14v6c0 .55.45 1 1 1s1-.45 1-1v-3.69l2.93 2.76c1.9 1.9 4.43 2.93 7.05 2.93.74 0 1.49-.08 2.23-.25a9.933 9.933 0 0 0 7.22-6.42c.18-.52-.09-1.09-.61-1.28l.01.04ZM23.97 10.15c0-.05.03-.1.03-.15V4c0-.55-.45-1-1-1s-1 .45-1 1v3.69l-2.93-2.76a9.958 9.958 0 0 0-9.28-2.68 9.933 9.933 0 0 0-7.22 6.42c-.18.52.09 1.09.61 1.28.52.19 1.09-.09 1.28-.61.92-2.6 3.08-4.52 5.78-5.13 2.69-.61 5.47.19 7.45 2.17l2.8 2.63h-3.48c-.55 0-1 .45-1 1s.45 1 1 1h6c.14 0 .27-.03.39-.08h.01c.12-.05.22-.13.31-.22h.01c.03-.03.05-.08.07-.12.05-.07.1-.13.12-.2.03-.07.03-.14.04-.22l.01-.02Z" fill={color}/>

    </svg>
);

export default Refresh;

Refresh.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
