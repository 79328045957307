
import PropTypes from "prop-types";
import React from "react";

const Supermarket = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M13 24a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM24 24a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM9 4a1 1 0 0 1 .946.676l.031.113.912 4.217L11 9h16a1 1 0 0 1 .996 1.095l-.019.117-1.596 7.373a2.997 2.997 0 0 1-2.803 2.413L23.4 20h-9.7a2.997 2.997 0 0 1-2.957-2.225l-.04-.174L8.193 6H5a1 1 0 0 1-.993-.883L4 5a1 1 0 0 1 .883-.993L5 4h4Zm16.76 7H11.32l1.34 6.195c.087.436.449.757.896.8l.124.005h9.74c.443.009.834-.276.972-.702l.03-.12L25.76 11Z"/>
		<path d="M23 18a1 1 0 0 1 .117 1.993L23 20H12.655c-.315 0-.655.41-.655 1 0 .544.29.936.582.993l.073.007h11.61a1 1 0 0 1 .116 1.993l-.116.007h-11.61C11.142 24 10 22.62 10 21c0-1.56 1.06-2.897 2.489-2.994l.166-.006H23Z"/>
	</g>

    </svg>
);

export default Supermarket;

Supermarket.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
