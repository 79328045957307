import React from "react";
import PropTypes from "prop-types";

export function Check({ color = "currentColor" }) {
    return (
        <svg
            width="22"
            height="17"
            viewBox="0 0 22 17"
            role="presentation"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fill={color} fillRule="nonzero" transform="translate(-522 -650)">
                    <g transform="translate(468 152)">
                        <g transform="translate(31 477)">
                            <path d="M30.124 37.26l-5.82-5.819a1.846 1.846 0 012.61-2.61l3.917 3.916 10.44-10.442a1.845 1.845 0 112.61 2.611L31.537 37.26a1 1 0 01-1.414 0z"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

Check.propTypes = {
    color: PropTypes.string,
};


export function Skip({ color = "currentColor" }) {
    return (
        <svg
            width="22"
            height="16"
            viewBox="0 0 22 16"
            role="presentation"
        >
            <g
                fill="none"
                fillRule="evenodd"
                stroke="none"
                strokeWidth="1"
            >
                <g fill={color} fillRule="nonzero" transform="translate(-898 -651)">
                    <g transform="translate(468 152)">
                        <g transform="translate(333 477)">
                            <g transform="translate(97 22)">
                                <path d="M20.654 7.087L13.91.376a1.29 1.29 0 00-1.82 1.829l4.526 4.505H1.29a1.29 1.29 0 100 2.58h15.326l-4.526 4.505a1.29 1.29 0 101.82 1.83l6.742-6.71a1.292 1.292 0 00.002-1.828z"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

Skip.propTypes = {
    color: PropTypes.string,
};

export function Cancel({ color = "currentColor" }) {
    return (
        <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            role="presentation"
        >
            <g
                fill="none"
                fillRule="evenodd"
                stroke="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            >
                <g stroke={color} strokeWidth="3" transform="translate(-673 -650)">
                    <g transform="translate(468 152)">
                        <g transform="translate(182 477)">
                            <g transform="translate(25.244 22.722)">
                                <path
                                    d="M0.22 13.068L13.768 0.233"
                                    transform="rotate(-2 6.994 6.65)"
                                ></path>
                                <path
                                    d="M0.22 13.068L13.768 0.233"
                                    transform="scale(-1 1) rotate(-2 0 407.345)"
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

Cancel.propTypes = {
    color: PropTypes.string,
};


