import axiosInstance from "Utils/axiosInstance";
import { sortItems } from "Utils/mappers";

const mapDtoToDashboard = (dto) => ({
    ...dto,
    creation_date: new Date(dto.creation_date),
    period_end: dto.period_end ? new Date(dto.period_end) : null,
    period_start: dto.period_start ? new Date(dto.period_start) : null
});

// services
export async function getDashboards() {
    const response = await axiosInstance.get("/analytics/dashboards");

    if (!response?.data) return [];

    return response.data.map(mapDtoToDashboard).sort(sortItems);
}

export async function sortDashboards(data) {
    const response = await axiosInstance.post(`/analytics/dashboards/sort`, data);

    if (!response?.data) return [];

    return response.data.map(mapDtoToDashboard).sort(sortItems);
}

export async function createDashboard(params = { template_name: "empty" }) {
    const response = await axiosInstance.post("/analytics/dashboards", params);

    if (!response?.data) return null;

    return mapDtoToDashboard(response.data);
}

export async function getDashboard(id) {
    const response = await axiosInstance.get(`/analytics/dashboards/${id}`);

    if (!response?.data) return null;

    return mapDtoToDashboard(response.data);
}

export async function updateDashboard(id, data) {
    const response = await axiosInstance.put(`/analytics/dashboards/${id}`, data);

    if (!response?.data) return null;

    return mapDtoToDashboard(response.data);
}

export async function duplicateDashboard(id, data) {
    const response = await axiosInstance.post(`/analytics/dashboards/${id}/duplicate`, data);

    if (!response?.data) return null;

    return mapDtoToDashboard(response.data);
}

export async function deleteDashboard(id) {
    await axiosInstance.delete(`/analytics/dashboards/${id}`);
}

export async function addModule(dashboardId, data) {
    const response = await axiosInstance.post(`/analytics/dashboards/${dashboardId}/modules`, data);

    if (!response?.data) return null;

    return response.data;
}

export async function renderModule(dashboardId, moduleId, options = {}) {
    const params = options.data_modified ? { data_modified: true } : null;
    const response = await axiosInstance.get(`/analytics/dashboards/${dashboardId}/modules/${moduleId}/render`, { params });

    if (!response?.data) return null;

    return response.data;
}

export async function deleteModule(dashboardId, moduleId) {
    await axiosInstance.delete(`/analytics/dashboards/${dashboardId}/modules/${moduleId}`);
}

export async function updateModule(dashboardId, moduleId, data) {
    const response = await axiosInstance.put(`/analytics/dashboards/${dashboardId}/modules/${moduleId}`, data);

    if (!response?.data) return null;

    return response.data;
}

export async function sortModules(dashboardId, ids) {
    const response = await axiosInstance.post(`/analytics/dashboards/${dashboardId}/modules/sort`, ids);

    if (!response?.data) return null;

    return response.data;
}

export async function updateModuleFilters(dashboardId, moduleId, data) {
    const response = await axiosInstance.put(`/analytics/dashboards/${dashboardId}/modules/${moduleId}/filters`, data);

    if (!response?.data) return null;

    return response.data;
}