
import PropTypes from "prop-types";
import React from "react";

const ModuleText = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M21 2a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h18Zm0 2H3v16h18V4Z"/>
		<path d="M14.883 16.538v-.363c0-.24-.184-.44-.423-.46a24.796 24.796 0 0 1-.549-.051c-.353-.037-.741-.076-.741-.517l-.002-6.816c1.39-.02 2.153.788 2.557 1.985a.47.47 0 0 0 .444.321h.37a.46.46 0 0 0 .461-.462V7.462A.46.46 0 0 0 16.54 7H7.46a.46.46 0 0 0-.46.462v2.713a.46.46 0 0 0 .46.462h.37a.47.47 0 0 0 .445-.321c.403-1.197 1.166-2.005 2.557-1.985l-.003 6.816c0 .44-.388.48-.741.517-.183.02-.366.036-.55.051a.461.461 0 0 0-.422.46v.363a.46.46 0 0 0 .46.462h4.846c.254 0 .46-.207.46-.462Z"/>
	</g>

    </svg>
);

export default ModuleText;

ModuleText.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
