
import PropTypes from "prop-types";
import React from "react";

const Tag = ({ color, size, ...props }) => (
    <svg viewBox="0 0 16 16" width={size} height={size} {...props}>
        
	<path d="M15.207 8.5 9 2.293A.991.991 0 0 0 8.293 2H2.5a.5.5 0 0 0-.5.5v5.793A.991.991 0 0 0 2.293 9L8.5 15.207a1 1 0 0 0 1.414 0l5.293-5.293a1 1 0 0 0 0-1.414Zm-6 6L3 8.293V3h5.293L14.5 9.207 9.207 14.5ZM6 5.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" fill={color}/>

    </svg>
);

export default Tag;

Tag.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
