import React, { useState } from "react";
import Button from "Components/Button";
import PropTypes from "prop-types";

export default function NoProducts(props) {
    const { onClearConnection, onClose } = props
    const [processing, setProcessing] = useState(false)

    const handleClick = async () => {
        setProcessing(true)
        await onClearConnection()
        onClose()
        setProcessing(false)
    }

    return (
        <div className={"text-center"}>
            <img src="/img/icon-error.svg" alt="Icono de Error" />
            <h3 className="fw-500 mt-4">Conexión sin productos</h3>
            <div className="mt-2 text-muted">El banco que has conectado no tiene ningún producto asociado.</div>
            <div className="mt-4">
                <Button primary size="large" onClick={handleClick} isBusy={processing}>Cerrar</Button>
            </div>
        </div>
    )
}

NoProducts.propTypes = {
    onClearConnection: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
}
