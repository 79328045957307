import emailIcon from '../assets/email.svg';
import telegramIcon from '../assets/telegram.svg';
import whatsappIcon from '../assets/whatsapp.svg';
import smsIcon from '../assets/imessage.svg';
import slackIcon from '../assets/slack.svg';

import accountIcon from '../assets/icon-account.svg';
import investmentIcon from '../assets/icon-investment.svg';
import cardIcon from '../assets/icon-card.svg';
import loanIcon from '../assets/icon-loan.svg';
import genericProductIcon from '../assets/icon-generic.svg';
import color from "color";

const CHANNEL_MAP = {
    email: {
        channel_name: 'Email',
        icon: emailIcon,
    },
    telegram: {
        channel_name: 'Telegram',
        icon: telegramIcon,
    },
    whatsapp: {
        channel_name: 'WhatsApp',
        icon: whatsappIcon,
    },
    sms: {
        channel_name: 'SMS',
        icon: smsIcon,
    },
    slack: {
        channel_name: 'Slack',
        icon: slackIcon,
    },
}

const getHelpDescription = (dto) => {
    switch (dto.channel) {
        case "telegram":
            return dto.meta.name ?? "-";
        case "slack":
            return dto.meta.channel_name ? `#${dto.meta.channel_name}` : "-";
        case "email":
        case "sms":
        case "whatsapp":
            return dto.address;
        default:
            return "";
    }
}

export function mapDtoToChannel(dto) {
    return {
        ...dto,
        creation_date: new Date(dto.creation_date),
        last_sent: dto.last_sent ? new Date(dto.last_sent) : null,
        channel_name: CHANNEL_MAP[dto.channel]?.channel_name || dto.channel,
        icon: CHANNEL_MAP[dto.channel]?.icon || emailIcon,
        help_description: getHelpDescription(dto),
    };
}

const getProductIcon = (product) => {
    switch (product.type) {
        case "checking":
            return accountIcon;
        case "investment":
        case "pensionplan":
        case "stocks":
        case "securities":
        case "saving":
            return investmentIcon;
        case "card":
        case "debit":
            return cardIcon;
        case "loan":
        case "lineofcredit":
            return loanIcon;
        default:
            return genericProductIcon;
    }
}

export function mapDtoToProduct(dto) {
    return {
        ...dto,
        icon: getProductIcon(dto),
        creation_date: new Date(dto.creation_date),
    };
}

export function mapDtoToAutomation(dto) {
    return {
        ...dto,
        creation_date: new Date(dto.creation_date),
        last_scheduled: new Date(dto.last_scheduled),
        last_sent: dto.last_sent ? new Date(dto.last_sent) : null
    };
}

export function mapDtoToAlert(dto) {
    return {
        ...dto,
        creation_date: new Date(dto.creation_date),
        last_scheduled: new Date(dto.last_scheduled),
        last_sent: dto.last_sent ? new Date(dto.last_sent) : null,
    };
}

export function sortItems(a, b) {
    if (a.is_premium_blocked && !b.is_premium_blocked) return 1;
    if (!a.is_premium_blocked && b.is_premium_blocked) return -1;

    return a.order - b.order
}

export function mapDtoToCategory(dto) {
    return {
        ...dto,
        color: `#${dto.color}`,
        bgColor: color(`#${dto.color}`).alpha(0.15).rgb().string(),
        creation_date: new Date(dto.creation_date),
        categorization_keywords: dto.categorization_keywords ?? [],
    }
}