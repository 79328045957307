
import PropTypes from "prop-types";
import React from "react";

const CircleClose = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M12 1.5a10.5 10.5 0 1 1 0 21 10.5 10.5 0 0 1 0-21Zm0 9.226L9.561 8.287a.9.9 0 1 0-1.274 1.274L10.727 12l-2.44 2.439a.9.9 0 1 0 1.274 1.274L12 13.273l2.439 2.44a.902.902 0 0 0 1.274-1.274L13.273 12l2.44-2.439a.902.902 0 0 0-1.274-1.274L12 10.727Z" fill={color}/>

    </svg>
);

export default CircleClose;

CircleClose.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
