
import PropTypes from "prop-types";
import React from "react";

const ArrowUpSmall = ({ color, size, ...props }) => (
    <svg viewBox="0 0 28 28" width={size} height={size} {...props}>
        
	<path d="m15.358 8.846 4.482 4.482c1.287 1.287 3.218-.644 1.93-1.931l-4.087-4.082-2.73-2.725a1.362 1.362 0 0 0-1.92 0L6.22 11.397a1.362 1.362 0 0 0-.412.987c0 1.226 1.493 1.827 2.343.944l4.482-4.493V22.84c.093 1.888 2.82 1.747 2.726-.07V8.845Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default ArrowUpSmall;

ArrowUpSmall.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
