
import { useContext } from "react";
import ProviderConnectionsContext from "Components/ProviderConnectionsProvider/ProviderConnectionsContext";

export default function useProviderConnectionActions() {
    const context = useContext(ProviderConnectionsContext);

    if (!context) {
        throw new Error("useProviderConnectionActions must be used within a ProviderConnectionsProvider");
    }

    return context
}
