
import PropTypes from "prop-types";
import React from "react";

const InfoOutline = ({ color, size, ...props }) => (
    <svg viewBox="0 0 18 18" width={size} height={size} {...props}>
        
	<path d="M9 3a6 6 0 1 0 0 12A6 6 0 0 0 9 3ZM1 9a8 8 0 1 1 16 0A8 8 0 0 1 1 9Zm7-2.8a1 1 0 0 1 1-1h.007a1 1 0 0 1 0 2H9a1 1 0 0 1-1-1ZM9 8a1 1 0 0 1 1 1v2.8a1 1 0 1 1-2 0V9a1 1 0 0 1 1-1Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default InfoOutline;

InfoOutline.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
