const MAX_AGE = 400 * 24 * 60 * 60 * 1000; // 400 days
const DOMAIN = ".banktrack.com";

export function isCookieEnabled() {
  return navigator.cookieEnabled;
}

export function setCookie(name, value, duration = MAX_AGE) {
  if (!isCookieEnabled()) return;

  const date = new Date();
  date.setTime(date.getTime() + duration);
  const expires = "expires=" + date.toUTCString();

  const valueString = typeof value === 'string' ? value : JSON.stringify(value);

  document.cookie = `${name}=${valueString};${expires};path=/;domain=${DOMAIN}`;
}

export function getCookie(name) {
  if (!isCookieEnabled()) return null;

  const cookie = document.cookie.split(';').find(row => row.trim().startsWith(`${name}=`));
  if (cookie) {
    const cookieValue = cookie.split('=')[1];
    try {
      return JSON.parse(cookieValue);
    } catch (error) {
      return cookieValue;
    }
  }

  return null;
}

export function deleteCookie(name) {
  if (!isCookieEnabled()) return;

  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=${DOMAIN}`;
}