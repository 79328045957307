import React from "react";
import PropTypes from "prop-types";
import { useFormContext, Controller } from 'react-hook-form';
import useCategories from "Hooks/useCategories";
import InputSelectSimple from 'Components/InputSelectSimple';

export default function RHFCategorySelect({ name, helperText, ...other }) {
    const { control } = useFormContext();
    const { categories, isLoading } = useCategories();

    if (isLoading) return <div style={{ height: 56 }} />

    const categoryOptions = categories.map(category => ({
        id: category.id,
        label: `${category.icon} ${category.name}`
    }))

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <InputSelectSimple
                    {...field}
                    error={!!error}
                    helperText={error ? error?.message : helperText}
                    options={categoryOptions}
                    {...other}
                />
            )}
        />
    );
}

RHFCategorySelect.propTypes = {
    name: PropTypes.string.isRequired,
    helperText: PropTypes.node
};
