import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from "prop-types";
import React from "react";
import classes from "./Search.module.css";
import clsx from 'clsx';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useSearch } from "../SearchProvider";
import { SEARCH_TRANSACTIONS } from 'Utils/eventTypes';
import { useEventDispatcher } from 'Components/EventDispatcherContext';
import { useTranslation } from 'react-i18next';

const Search = ({ className, autoFocus = false }) => {
    const { search, setSearch, refreshResults } = useSearch();
    const eventDispatcher = useEventDispatcher();
    const { t } = useTranslation("common");

    const submitHandler = (e) => {
        e.preventDefault();

        if (search) {
            eventDispatcher.dispatch(SEARCH_TRANSACTIONS, search);
        }

        refreshResults({ forceNavigate: true })
    };

    return (
        <form onSubmit={submitHandler} className={clsx("justify-content-between", "align-items-center", "ps-2", "pe-2", "d-flex", classes.searchbox, className)}>
            <FontAwesomeIcon icon={faSearch} className={"ms-3 me-1 text-muted small opacity-50"} />
            <input
                autoFocus={autoFocus}
                type="text"
                className="flex-grow-1 pe-4 ps-3 py-2 fw-light"
                placeholder={t("search-placeholder-transactions")}
                value={search}
                onChange={e => setSearch(e.target.value)}
            />
        </form>
    )
}

export default Search;

Search.propTypes = {
    className: PropTypes.string,
    autoFocus: PropTypes.bool
}