
import PropTypes from "prop-types";
import React from "react";

const ChevronUp = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M7.05 14.834a1 1 0 0 0 1.41 0l3.59-3.54 3.54 3.54a1.001 1.001 0 1 0 1.41-1.42l-4.24-4.24a1.003 1.003 0 0 0-1.42 0l-4.29 4.24a1.003 1.003 0 0 0 0 1.42Z" fill={color}/>

    </svg>
);

export default ChevronUp;

ChevronUp.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
