import { useQuery } from "@tanstack/react-query";
import { getCountries } from "Services/others";
import { trackError } from "Utils/errorMonitoring";

export default function useCountries() {
    const { data: countries = [], isLoading } = useQuery(["countries"], getCountries, {
        onError: error => trackError(error),
    });
    return {
        isLoading,
        countries,
    }
}