import {
    FloatingFocusManager,
    autoUpdate,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useRole
} from "@floating-ui/react";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { flip, size, offset } from "@floating-ui/react-dom";
import classes from "./Dropdown.module.css";
import { DropdownContext } from "./DropdownContext";
import PropTypes from "prop-types";

const Dropdown = forwardRef(function Dropdown({ children, toggle }, ref) {
    const [isOpen, setIsOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        open() {
            setIsOpen(true);
        },
        close() {
            setIsOpen(false);
        }
    }));

    const { x, y, refs, strategy, context } = useFloating({
        whileElementsMounted: autoUpdate,
        open: isOpen,
        placement: "bottom-end",
        middleware: [
            flip(),
            size({
                apply({ rects, elements }) {
                    Object.assign(elements.floating.style, {
                        width: `${rects.reference.width}px`,
                    })
                }
            }),
            offset(4)
        ],
        onOpenChange: setIsOpen
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        useClick(context),
        useRole(context, { role: "menu" }),
        useDismiss(context)
    ]);

    return (
        <DropdownContext.Provider value={{ isOpen, setIsOpen, reference: refs.setReference, getReferenceProps }}>
            {toggle}

            {isOpen && (
                <FloatingFocusManager context={context}>
                    <div {...getFloatingProps({
                        ref: refs.setFloating,
                        style: {
                            position: strategy,
                            top: y ?? 0,
                            left: x ?? 0,
                            zIndex: "var(--z-index-dropdown)",
                        }
                    })}>
                        <div className={classes.list}>
                            {children}
                        </div>
                    </div>
                </FloatingFocusManager>
            )}
        </DropdownContext.Provider>
    );
});

export default Dropdown;

Dropdown.propTypes = {
    children: PropTypes.node.isRequired,
    toggle: PropTypes.node.isRequired,
};


