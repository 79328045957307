
import PropTypes from "prop-types";
import React from "react";

const NavForecast = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<path d="M5.5 15.697a.429.429 0 0 0-.42.436v6.334c0 .251.198.436.42.436H8c.222 0 .42-.185.42-.436v-6.334a.429.429 0 0 0-.42-.436H5.5Zm-2.08.436c0-1.147.921-2.096 2.08-2.096H8c1.16 0 2.08.949 2.08 2.096v6.334c0 1.147-.92 2.096-2.08 2.096H5.5c-1.159 0-2.08-.949-2.08-2.096v-6.334ZM14.25 10.63a.429.429 0 0 0-.42.437v11.4c0 .251.198.436.42.436h2.5c.222 0 .42-.185.42-.436v-11.4a.428.428 0 0 0-.42-.437h-2.5Zm-2.08.437c0-1.148.921-2.097 2.08-2.097h2.5c1.16 0 2.08.95 2.08 2.097v11.4c0 1.147-.92 2.096-2.08 2.096h-2.5c-1.159 0-2.08-.949-2.08-2.096v-11.4ZM23 8.097a.429.429 0 0 0-.42.436v13.934c0 .251.198.436.42.436h2.5c.222 0 .42-.185.42-.436V8.533a.429.429 0 0 0-.42-.436H23Zm-2.08.436c0-1.147.921-2.096 2.08-2.096h2.5c1.16 0 2.08.949 2.08 2.096v13.934c0 1.147-.92 2.096-2.08 2.096H23c-1.159 0-2.08-.949-2.08-2.096V8.533Z" fill={color} fillRule="evenodd"/>
	<path d="m26.697 19.813-5 2.533-.453-.892 5-2.533.453.892ZM26.697 17.28l-5 2.533-.453-.892 5-2.534.453.892ZM26.697 14.746l-5 2.534-.453-.893 5-2.533.453.892ZM26.697 12.213l-5 2.533-.453-.892 5-2.533.453.892ZM26.697 9.68l-5 2.533-.453-.892 5-2.534.453.893Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default NavForecast;

NavForecast.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
