
import PropTypes from "prop-types";
import React from "react";

const Drag = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M10 5H8v2h2V5ZM10 11H8v2h2v-2ZM8 17h2v2H8v-2ZM16 5h-2v2h2V5ZM14 11h2v2h-2v-2ZM16 17h-2v2h2v-2Z" fill={color}/>

    </svg>
);

export default Drag;

Drag.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
