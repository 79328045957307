
import PropTypes from "prop-types";
import React from "react";

const Verified = ({ color, size, ...props }) => (
    <svg viewBox="0 0 20 20" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M3.349 4.315A8.75 8.75 0 0 1 13.56 2.008l-.61 1.37a7.25 7.25 0 1 0 4.3 6.627v-.736h1.5v.736a8.75 8.75 0 1 1-15.402-5.69Z"/>
		<path d="m17.47 3.075 1.06 1.06L10 12.674l-2.93-2.93 1.06-1.061L10 10.55z"/>
	</g>

    </svg>
);

export default Verified;

Verified.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
