import { useQuery } from "@tanstack/react-query";
import { getContacts } from "Services/invoices";
import { trackError } from "Utils/errorMonitoring";

const CONTACTS_PER_PAGE = 1000;

export default function useContacts() {
    const { data, isLoading } = useQuery(["contacts"], () => getContacts({ count: CONTACTS_PER_PAGE }), {
        onError: error => trackError(error),
    });

    return {
        isLoading,
        contacts: data?.items ?? [],
    }
}
