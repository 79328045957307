import React, { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { fetchAuthSession, signOut } from "aws-amplify/auth"
import { UserContext } from "./UserContext";
import { getProjectSelectedUsers, getUser } from 'Services/user';
import storage from 'Utils/storage';
import queryClient from 'Utils/queryClient';
import axiosInstance from 'Utils/axiosInstance';
import { API_HOST } from 'config';
import { useErrors } from "Components/ErrorsProvider";
import Loading from "Components/Loading";
import { useQuery } from "@tanstack/react-query";
import { trackError } from "Utils/errorMonitoring";
import useRegisterUserLocale from "./useRegisterUserLocale";

export default function UserProvider({ children }) {
    const [userWithoutOwner, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const interceptor = useRef(null);
    const { showUnknownError } = useErrors();

    const { data: projectUsers = [] } = useQuery(["project-selected", "users"], getProjectSelectedUsers, {
        onError: trackError,
        enabled: !!userWithoutOwner?.set_project_workspace_id
    });

    const user = useMemo(() => {
        if (!userWithoutOwner) return userWithoutOwner;

        const me = projectUsers.find(projectUser => projectUser.user_id === userWithoutOwner.id)
        const isOwner = me?.role === "owner"

        return {
            ...userWithoutOwner,
            isOwner
        }
    }, [projectUsers, userWithoutOwner]);

    useRegisterUserLocale(user);

    useEffect(() => {
        let mounted = true;

        const checkUser = async () => {
            try {
                const session = await fetchAuthSession();
                const token = session.tokens?.idToken?.toString();
                if (!token) {
                    throw new Error('No token');
                }
                if (mounted) {
                    login(token);
                }
            } catch (error) {
                const token = storage.getItem('token');
                if (!mounted) return;

                if (token !== null) {
                    login(token);
                } else {
                    logout();
                }
            }
        }

        checkUser();

        return () => {
            mounted = false;
        }
    }, []);

    const logout = async () => {
        queryClient.clear();
        await signOut();
        storage.removeItem('token');
        setUser(null);
        setLoading(false);

        interceptor.current && axiosInstance.interceptors.response.eject(interceptor.current);
    }

    const login = (token, newUser = null) => {
        storage.setItem('token', token);

        if (newUser === null) {
            getUser().then(userResponse => {
                setUser(userResponse);
                setLoading(false);
                interceptor.current = axiosInstance.interceptors.response.use(undefined, handleErrors);

            }).catch(() => {
                logout();
            });
        } else {
            setUser(newUser);
            setLoading(false);
        }
    }

    const handleErrors = (error) => {
        if (error.response?.status === 401) {
            logout();
            return;
        }

        if (error.response?.status >= 500) {
            const { data, method, url } = error.config;
            const request = API_HOST + url;
            showUnknownError(error, { request, method, data });
            return;
        }

        return Promise.reject(error);
    }


    const refreshUser = (user = null) => {
        if (user === null) {
            setLoading(true);

            getUser().then(userResponse => {
                setUser(userResponse);
            }).catch(() => {
                logout();
            }).finally(() => {
                setLoading(false);
            });
        } else {
            setUser(user);
        }
    }
    if (loading) {
        return <div className="mt-5"><Loading /></div>
    }

    return (
        <UserContext.Provider value={{ user, refreshUser, login, loading, logout }}>
            {children}
        </UserContext.Provider>
    )
}

UserProvider.propTypes = {
    children: PropTypes.node.isRequired,
};