import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import { SnackbarContent, useSnackbar } from "notistack";
import classes from "./Snackbar.module.css";
import Button from "Components/Button";
import TextField from "Components/TextField";
import IconButton from "Components/IconButton";
import Icon from "Components/Icon";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const SnackbarProviderConnectionRequestOtp = forwardRef(function SnackbarProviderConnectionRequestOtp(props, ref) {
    const { icon, message, action, id } = props;
    const [otp, setOtp] = useState("");
    const { closeSnackbar } = useSnackbar();

    const { t } = useTranslation("providers");

    const handleSubmit = (ev) => {
        ev.preventDefault();
        if (!otp) return;

        closeSnackbar(id);
        action?.({
            otp,
        });
    }

    return (
        <SnackbarContent ref={ref} className={classes.root}>
            <div className={classes.card}>
                <div className={classes.close}>
                    <IconButton onClick={() => closeSnackbar(id)}>
                        <Icon name="close" color="#CDCDCD" size={16} />
                    </IconButton>
                </div>
                <div className={classes.layout}>
                    <div className={classes.icon}>
                        <img src={icon} alt="" />
                    </div>
                    <form onSubmit={handleSubmit} className={classes.content}>
                        <div className={clsx(classes.title, "mb-1")}>
                            {t("verify-access")}
                        </div>
                        <div className={classes.group}>
                            <TextField
                                className={classes.groupInput}
                                value={otp}
                                onChange={(event) => setOtp(event.target.value)}
                            />
                            <Button size="small" primary type="submit" className={classes.groupButton}>
                                {t("accept")}
                            </Button>
                        </div>
                        <div className="text-muted pt-2 text-small">{message}</div>
                    </form>
                </div>
            </div>
        </SnackbarContent>
    );
});

export default SnackbarProviderConnectionRequestOtp;

SnackbarProviderConnectionRequestOtp.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    icon: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    action: PropTypes.func,
};