
import PropTypes from "prop-types";
import React from "react";

const Commissions = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M26 16h-3.964L22 6c0-1.48-1.52-3-3-3H7C5.52 3 4 4.52 4 6v18c0 1.314.563 2.79 1.466 3.652C6.37 28.514 7.816 29 9 29h15.5c2.466 0 4.5-2.534 4.5-5v-5c0-1.48-1.52-3-3-3ZM6 6c0-.493.507-1 1-1h12c.493 0 1 .507 1 1l.071 18.036c-.005.966.273 2.194.858 2.964H9c-1.48 0-3-1.52-3-3V6Zm21 18c0 1.48-1.02 3-2.5 3S22 25.48 22 24l.036-6H26c.493 0 1 .507 1 1v5Z"/>
		<path d="M14.538 7.75c.824 0 1.628.23 2.326.665.403.252.51.768.23 1.142-.27.363-.798.454-1.19.211a2.542 2.542 0 0 0-1.366-.393c-.816 0-1.573.394-2.1 1.056l-.1.131h2.2c.437 0 .805.306.859.71l.007.103c0 .454-.393.813-.866.813l-2.807-.001-.008.074-.009.122-.003.117c0 .077.004.154.012.24l.008.072h2.192c.437 0 .804.306.858.71l.007.103c0 .454-.392.813-.865.813l-1.585-.001.1.132c.486.61 1.169.994 1.913 1.05l.187.006c.49 0 .949-.132 1.367-.393.39-.242.919-.152 1.188.21.28.375.174.891-.23 1.144a4.393 4.393 0 0 1-2.325.664c-1.87 0-3.527-1.193-4.22-2.972l.065.158-.768.002c-.4 0-.742-.257-.838-.611l-.02-.1-.007-.102c0-.454.392-.813.865-.813h.386l-.015-.157-.005-.155c0-.08.003-.15.012-.238l.008-.075h-.386c-.436 0-.804-.305-.858-.71l-.007-.102c0-.454.392-.813.865-.813h.768l.019-.043c.707-1.6 2.212-2.68 3.922-2.764ZM17 20a1 1 0 0 1 .117 1.993L17 22H9a1 1 0 0 1-.117-1.993L9 20h8ZM17 23a1 1 0 0 1 .117 1.993L17 25h-6a1 1 0 0 1-.117-1.993L11 23h6Z"/>
	</g>

    </svg>
);

export default Commissions;

Commissions.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
