import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classes from "./InputAdornment.module.css";
import clsx from 'clsx';

const InputAdornment = forwardRef((props, ref ) => {
  const { position, className, isInteractive, ...htmlProps } = props;

  const rootClassName = clsx(classes.base, className, {
    [classes.start]: position === "start",
    [classes.end]: position === "end",
    [classes.pointerEventNone]: !isInteractive
  });

  return <div ref={ref} className={rootClassName} {...htmlProps} />;
});

export default InputAdornment;

InputAdornment.displayName = "InputAdornment";

InputAdornment.propTypes = {
  className: PropTypes.string,
  position: PropTypes.string,
  isInteractive: PropTypes.bool
};

InputAdornment.defaultProps = {
  position: "start",
  isInteractive: true
};
