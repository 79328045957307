
import PropTypes from "prop-types";
import React from "react";

const TriangleUp = ({ color, size, ...props }) => (
    <svg viewBox="0 0 10 10" width={size} height={size} {...props}>
        
	<path d="M8.92 6.007 5.193 2.085A.274.274 0 0 0 5 2a.262.262 0 0 0-.194.085L1.08 6.007a.303.303 0 0 0-.06.315c.02.053.056.098.101.13a.265.265 0 0 0 .153.048h7.45a.265.265 0 0 0 .153-.048.286.286 0 0 0 .101-.13.303.303 0 0 0-.06-.315Z" fill={color}/>

    </svg>
);

export default TriangleUp;

TriangleUp.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
