import React from "react";
import PropTypes from "prop-types";
import classes from "./ComparativeTable.module.css";
import { useTranslation } from "react-i18next";

export default function ComparativeTable(props) {
    const { columns, rows, title, description, periodicity } = props

    const { t } = useTranslation("pricing")

    return (
        <div className={classes.scrollable}>
            <table className={classes.table}>
                <thead>
                    <tr>
                        <th>
                            <div className={classes.title}>
                                {title}
                            </div>
                            <div className="text-small">
                                {description}
                            </div>
                        </th>
                        {columns.map((column, index) => {
                            const periodicityPrice = column.price ? column.price[periodicity] : 0;
                            const hasPrice = !!periodicityPrice;

                            return (
                                <th key={index}>
                                    <div className={classes.title}>
                                        {column.title}
                                    </div>
                                    {hasPrice && (
                                        <div className="text-small">
                                            {periodicityPrice}€ / {t("month")} {periodicity === "monthly" ? "" : `(${t("annual-payment")})`}
                                        </div>
                                    )}
                                </th>
                            )
                        }
                        )}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, index) => (
                        <tr key={index}>
                            {row.isDivider && (
                                <td colSpan={columns.length + 1} className={classes.divider}>
                                    {row.title}
                                </td>
                            )}
                            {!row.isDivider && (
                                <>
                                    <td>
                                        {row.title}
                                    </td>
                                    {row.cells.map((cell, index) => (
                                        <td key={index}>
                                            {cell}
                                        </td>
                                    ))}
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

ComparativeTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.node.isRequired,
        description: PropTypes.string,
    })).isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        isDivider: PropTypes.bool,
        cells: PropTypes.arrayOf(PropTypes.node),
    })).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    periodicity: PropTypes.oneOf(["monthly", "yearly"]),
};
