import React from "react";
import PropTypes from "prop-types";
import CreatingPartialMatch from "./CreatingPartialMatch";
import CreatingTotalMatch from "./CreatingTotalMatch";
import InvalidCredentials from "./InvalidCredentials";
import ServiceOut from "./ServiceOut";
import Multicontract from "./Multicontract";
import RequiresOtp from "./RequiresOtp";
import RequiresSignature from "./RequiresSignature";
import ReconnectingEmptyMatch from "./ReconnectingEmptyMatch";
import NoProducts from "./NoProducts";
import ReconnectingPartialMatch from "./ReconnectingPartialMatch";
import UnknownErrorMessage from "./UnknownErrorMessage";
import MultiOTP from "./MultiOTP";

const messageComponents = {
    creating_partial_match: CreatingPartialMatch,
    creating_total_match: CreatingTotalMatch,
    invalid_credentials: InvalidCredentials,
    service_out: ServiceOut,
    multicontract: Multicontract,
    multiple_otp_methods: MultiOTP,
    requires_otp: RequiresOtp,
    requires_signature: RequiresSignature,
    reconnecting_empty_match: ReconnectingEmptyMatch,
    reconnecting_partial_match: ReconnectingPartialMatch,
    no_products: NoProducts,
    default: UnknownErrorMessage
}

export default function ProviderConnectionsErrorManager(props) {
    const { description } = props;
    const MessageComponent = messageComponents[description] || messageComponents.default;

    return (
        <MessageComponent {...props} />
    )
}

ProviderConnectionsErrorManager.propTypes = {
    providerConnection: PropTypes.object.isRequired,
    description: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onEnableClose: PropTypes.func.isRequired,
    onClearConnection: PropTypes.func.isRequired,
    info: PropTypes.object,
};
