
import PropTypes from "prop-types";
import React from "react";

const Subscriptions = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<path d="M20.045 9.125a.835.835 0 0 0-.6.253.866.866 0 0 0-.249.595v.02l-.613 11.177-2.946-1.498a1.69 1.69 0 0 0-.76-.183.76.76 0 0 0-.545.23.788.788 0 0 0-.227.556.78.78 0 0 0 .227.56l.002.002 5.485 5.582a5.619 5.619 0 0 0 4.043 1.706 4.61 4.61 0 0 0 3.299-1.393 4.775 4.775 0 0 0 1.369-3.357v-7.46c-.001-.08-.006-.099-.007-.101l-.002-.005a.24.24 0 0 0-.024-.039c-.066-.09-.25-.277-.733-.57a.381.381 0 0 0-.09.04l-1.344 1.003-.272-1.448c-.13-.072-.418-.185-1.002-.29l-.013-.001-1.23 1.23-.43-1.864a.74.74 0 0 0-.096-.214l-.01-.015-.03-.048c-.182.03-.469.107-.904.275l-.005.002-1.446.55.001-4.431a.868.868 0 0 0-.249-.61.835.835 0 0 0-.6-.254Zm4.873 3.338c.154.019.317.043.49.074.703.126 1.26.297 1.679.543.086.051.168.106.245.165.123-.03.252-.05.385-.063l.023-.002.485-.021.248.14c.77.43 1.304.837 1.634 1.286.38.515.42.987.422 1.317v7.473c0 1.855-.743 3.54-1.942 4.759a6.61 6.61 0 0 1-4.725 1.991 7.616 7.616 0 0 1-5.47-2.305l-5.483-5.58a2.78 2.78 0 0 1-.804-1.965c0-.763.307-1.456.8-1.958a2.76 2.76 0 0 1 1.973-.828c.576 0 1.148.137 1.664.399l.212.108.443-8.078c.018-.756.33-1.441.821-1.941a2.835 2.835 0 0 1 4.053 0c.507.515.822 1.228.822 2.012v1.636l.03-.006c.36-.06.723-.066 1.063.043.525.168.83.61.932.8Z" fill={color} fillRule="evenodd"/>
	<path d="M5 2h19a3 3 0 0 1 3 3v8c0 .385-.073.753-.205 1.092-.194-.266-.65-.514-1.669-.696a8.864 8.864 0 0 0-.194-.032A.998.998 0 0 0 25 13V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12.863l-.11 2H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3Z" fill={color} fillRule="evenodd"/>
	<path d="m17.376 9.416-4.599 3.066a.5.5 0 0 1-.777-.416V5.934a.5.5 0 0 1 .777-.416l4.599 3.066a.5.5 0 0 1 0 .832Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default Subscriptions;

Subscriptions.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
