import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { SnackbarContent } from "notistack";
import classes from "./Snackbar.module.css";
import Spinner from "Components/Spinner";
import { useTranslation } from "react-i18next";

const SnackbarProviderConnectionCreating = forwardRef(function SnackbarProviderConnectionCreating(props, ref) {
    const { icon, message } = props;

    const { t } = useTranslation("providers");

    return (
        <SnackbarContent ref={ref} className={classes.root}>
            <div className={classes.card}>
                <div className={classes.layout}>
                    <div className={classes.icon}>
                        <img src={icon} alt="" />
                    </div>
                    <div className={classes.content}>
                        <div className={classes.actionText}>
                            {t("connecting")}
                        </div>
                        <div className={classes.title}>{message}</div>
                    </div>
                    <div className={classes.action}>
                        <Spinner size={24} color="#5543C7" className={classes.spinner} />
                    </div>
                </div>
            </div>
        </SnackbarContent>
    );
});

export default SnackbarProviderConnectionCreating;

SnackbarProviderConnectionCreating.propTypes = {
    icon: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
};