import React, { useEffect } from "react";
import Proptype from "prop-types";
import eventDispatcher from "Utils/eventDispatcher";
import { EventDispatcherContext } from "./EventDispatcherContext";
import { useQueryClient } from "@tanstack/react-query";
import {
    ADD_AUTOMATION, REMOVE_AUTOMATION, REMOVE_ALERT_CONNECTION, ADD_ALERT_CONNECTION, ADD_PROVIDER_CONNECTION, REMOVE_PROVIDER_CONNECTION,
    ADD_CHANNEL_CONNECTION, REMOVE_CHANNEL_CONNECTION, UPDATE_PROVIDER_CONNECTION, REMOVE_REPORT_CONNECTION, ADD_REPORT_CONNECTION, UPDATE_REPORT_CONNECTION,
    ADD_DASHBOARD, REMOVE_DASHBOARD, UPDATE_DASHBOARD, ADD_FORECAST, REMOVE_FORECAST, UPDATE_FORECAST
} from "Utils/eventTypes";

export default function EventDispatcherProvider({ children }) {
    const queryClient = useQueryClient();

    useEffect(() => {
        const unsubscribeAddAutomation = eventDispatcher.subscribe(ADD_AUTOMATION, (automationCreated) => {
            queryClient.setQueryData(['automations'], oldAutomations => {
                if (!Array.isArray(oldAutomations)) return [automationCreated];

                return [...oldAutomations, automationCreated].sort((a, b) => a.order - b.order);
            });
        });

        const unsubcribeRemoveAutomation = eventDispatcher.subscribe(REMOVE_AUTOMATION, (automationId) => {
            queryClient.setQueryData(['automations'], oldAutomations => {
                if (!Array.isArray(oldAutomations)) return [];

                return oldAutomations.filter(automation => automation.id !== automationId);
            });
        });

        const unsubsucribeAddAlertConnection = eventDispatcher.subscribe(ADD_ALERT_CONNECTION, (alertConnectionCreated) => {
            queryClient.setQueryData(['alerts-connections'], oldAlertConnections => {
                if (!Array.isArray(oldAlertConnections)) return [alertConnectionCreated];

                return [...oldAlertConnections, alertConnectionCreated].sort((a, b) => a.order - b.order);
            });
        });

        const unsubscribeRemoveAlertConnection = eventDispatcher.subscribe(REMOVE_ALERT_CONNECTION, (alertConnectionId) => {
            queryClient.setQueryData(['alerts-connections'], oldAlertConnections => {
                if (!Array.isArray(oldAlertConnections)) return [];

                return oldAlertConnections.filter(alertConnection => alertConnection.id !== alertConnectionId);
            });
        });

        const unsubscribeRemoveReportConnection = eventDispatcher.subscribe(REMOVE_REPORT_CONNECTION, (reportConnectionId) => {
            queryClient.setQueryData(['reports-connections'], oldReportConnections => {
                if (!Array.isArray(oldReportConnections)) return [];

                return oldReportConnections.filter(reportConnection => reportConnection.id !== reportConnectionId);
            });
        })

        const unsubscribeAddReportConnection = eventDispatcher.subscribe(ADD_REPORT_CONNECTION, (reportConnectionCreated) => {
            queryClient.setQueryData(['reports-connections'], oldReportConnections => {
                if (!Array.isArray(oldReportConnections)) return [reportConnectionCreated];

                return [...oldReportConnections, reportConnectionCreated].sort((a, b) => a.order - b.order);
            });
        });

        const unsubscribeUpdateReportConnection = eventDispatcher.subscribe(UPDATE_REPORT_CONNECTION, (reportConnectionUpdated) => {
            queryClient.setQueryData(['reports-connections'], oldReportConnections => {
                if (!Array.isArray(oldReportConnections)) return [];

                return oldReportConnections.map(oldReportConnection => {
                    if (oldReportConnection.id === reportConnectionUpdated.id) {
                        return reportConnectionUpdated;
                    }

                    return oldReportConnection;
                });
            });
        });

        const unsubscribeAddProvider = eventDispatcher.subscribe(ADD_PROVIDER_CONNECTION, async () => {
            await queryClient.invalidateQueries(['providers-connections']);
        });

        const unsubscribeRemoveProvider = eventDispatcher.subscribe(REMOVE_PROVIDER_CONNECTION, async () => {
            await queryClient.invalidateQueries(['providers-connections']);
        });

        const unsubscribeAddChannelConnection = eventDispatcher.subscribe(ADD_CHANNEL_CONNECTION, async () => {
            await queryClient.invalidateQueries(['integrations']);
        });

        const unsubscribeRemoveChannelConnection = eventDispatcher.subscribe(REMOVE_CHANNEL_CONNECTION, async () => {
            await queryClient.invalidateQueries(['integrations']);
        });

        const unsubscribeUpdateProviderConnection = eventDispatcher.subscribe(UPDATE_PROVIDER_CONNECTION, async (providerConnection) => {
            queryClient.setQueryData(['providers-connections'], oldProviderConnections => {
                if (!Array.isArray(oldProviderConnections)) return [];

                return oldProviderConnections.map(oldProviderConnection => {
                    if (oldProviderConnection.id === providerConnection.id) {
                        return providerConnection;
                    }

                    return oldProviderConnection;
                });
            });

            // invalidate render of dashboard modules
            await queryClient.invalidateQueries(['dashboard']);
            await queryClient.invalidateQueries(['transactions']);
        });

        const unsubscribeAddDashboard = eventDispatcher.subscribe(ADD_DASHBOARD, (dashboardCreated) => {
            queryClient.setQueryData(['dashboards'], oldDashboards => {
                if (!Array.isArray(oldDashboards)) return [dashboardCreated];

                return [...oldDashboards, dashboardCreated].sort((a, b) => a.order - b.order);
            });
        });

        const unsubscribeRemoveDashboard = eventDispatcher.subscribe(REMOVE_DASHBOARD, (dashboardId) => {
            queryClient.setQueryData(['dashboards'], oldDashboards => {
                if (!Array.isArray(oldDashboards)) return [];

                return oldDashboards.filter(dashboard => dashboard.id !== dashboardId);
            });
        });

        const unsubscribeUpdateDashboard = eventDispatcher.subscribe(UPDATE_DASHBOARD, (dashboardUpdated) => {
            if (!dashboardUpdated) return;

            queryClient.setQueryData(['dashboards'], oldDashboards => {
                if (!Array.isArray(oldDashboards)) return [];

                return oldDashboards.map(oldDashboard => {
                    if (oldDashboard.id === dashboardUpdated.id) {
                        return dashboardUpdated;
                    }

                    return oldDashboard;
                });
            });

            queryClient.setQueryData(['dashboard', dashboardUpdated.id], oldDashboard => {
                if (!oldDashboard) return null;

                return dashboardUpdated;
            });
        });

        const unsubscribeAddForecast = eventDispatcher.subscribe(ADD_FORECAST, (forecastCreated) => {
            queryClient.setQueryData(['forecasts'], oldForecasts => {
                if (!Array.isArray(oldForecasts)) return [forecastCreated];

                return [...oldForecasts, forecastCreated]
            });
        });

        const unsubscribeRemoveForecast = eventDispatcher.subscribe(REMOVE_FORECAST, (forecastId) => {
            queryClient.setQueryData(['forecasts'], oldForecasts => {
                if (!Array.isArray(oldForecasts)) return [];

                return oldForecasts.filter(forecast => forecast.id !== forecastId);
            });
        });

        const unsubscribeUpdateForecast = eventDispatcher.subscribe(UPDATE_FORECAST, (forecastUpdated) => {
            queryClient.setQueryData(['forecasts'], oldForecasts => {
                if (!Array.isArray(oldForecasts)) return [];

                return oldForecasts.map(oldForecast => {
                    if (oldForecast.id === forecastUpdated.id) {
                        return forecastUpdated;
                    }

                    return oldForecast;
                });
            });
        });

        return () => {
            unsubscribeAddAutomation();
            unsubcribeRemoveAutomation();
            unsubsucribeAddAlertConnection();
            unsubscribeRemoveAlertConnection();
            unsubscribeAddProvider();
            unsubscribeRemoveProvider();
            unsubscribeAddChannelConnection();
            unsubscribeRemoveChannelConnection();
            unsubscribeUpdateProviderConnection();
            unsubscribeRemoveReportConnection();
            unsubscribeAddReportConnection();
            unsubscribeUpdateReportConnection();
            unsubscribeAddDashboard();
            unsubscribeRemoveDashboard();
            unsubscribeUpdateDashboard();
            unsubscribeAddForecast();
            unsubscribeRemoveForecast();
            unsubscribeUpdateForecast();
        }
    }, [queryClient]);

    return (
        <EventDispatcherContext.Provider value={eventDispatcher}>
            {children}
        </EventDispatcherContext.Provider>
    )
}

EventDispatcherProvider.propTypes = {
    children: Proptype.node.isRequired
}