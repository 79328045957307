
import PropTypes from "prop-types";
import React from "react";

const NavReports = ({ color, size, ...props }) => (
    <svg viewBox="0 0 28 28" width={size} height={size} {...props}>
        
	<path d="M20.194 6.392 6.711 11.11l6.067 2.696 7.416-7.415Zm-6.001 8.83 2.696 6.067 4.72-13.483-7.416 7.416ZM24.937 4.35l-6.993 19.98a1 1 0 0 1-1.858.075l-3.844-8.648-8.648-3.844a1 1 0 0 1 .076-1.858l19.978-6.992a1 1 0 0 1 1.289 1.287Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default NavReports;

NavReports.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
