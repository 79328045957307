
import PropTypes from "prop-types";
import React from "react";

const TriangleDown = ({ color, size, ...props }) => (
    <svg viewBox="0 0 10 10" width={size} height={size} {...props}>
        
	<path d="m1.08 2.493 3.726 3.922A.274.274 0 0 0 5 6.5a.262.262 0 0 0 .194-.085L8.92 2.493a.303.303 0 0 0 .06-.315.286.286 0 0 0-.101-.13A.265.265 0 0 0 8.725 2h-7.45a.265.265 0 0 0-.153.048.286.286 0 0 0-.101.13.303.303 0 0 0 .06.315Z" fill={color}/>

    </svg>
);

export default TriangleDown;

TriangleDown.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
