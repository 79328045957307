export default function analyticsLogger() {
    return {
        name: 'analytics-logger',
        config: {},
        initialize: () => { },
        page: ({ payload }) => {
            console.log('page', payload)
        },
        track: ({ payload }) => {
            console.log('track', payload)
        },
        identify: ({ payload }) => {
            console.log('identify', payload)
        },
        loaded: () => true
    }
}