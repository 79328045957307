import axiosInstance from "Utils/axiosInstance";
import { sortItems } from "Utils/mappers";

const mapDtoToReportConnection = (dto) => {
    return {
        ...dto,
        creation_date: new Date(dto.creation_date),
        last_scheduled: dto.last_scheduled ? new Date(dto.last_scheduled) : null,
        last_sent: dto.last_sent ? new Date(dto.last_sent) : null,
    };
}

const mapDtoToModule = (dto) => {
    return {
        ...dto,
        date_start: new Date(dto.date_start),
        date_end: new Date(dto.date_end),
    }
}

export async function getReportsConnections() {
    const response = await axiosInstance.get("/analytics/reports");

    if (!response?.data) return [];

    return response.data.map(mapDtoToReportConnection).sort(sortItems);
}

export async function sortReportsConnections(reportsConnectionsIds) {
    await axiosInstance.post("/analytics/reports/sort", { type: "reports", items: reportsConnectionsIds });
}

export async function deleteReportConnection(id) {
    await axiosInstance.delete(`/analytics/reports/${id}`);
}

export async function renderReportConnectionGlobal(reportConnection) {
    const reportConnectionDto = {
        name: reportConnection.name,
        modules: reportConnection.modules,
        meta: reportConnection.meta,
        providers_connections_products_ids: reportConnection.providers_connections_products_ids,
    }

    const response = await axiosInstance.post("/analytics/reports/render", reportConnectionDto);

    if (!response?.data) return null;

    return mapDtoToModule(response.data);
}

export async function createReportConnection(reportConnection) {
    const reportConnectionDto = {
        name: reportConnection.name,
        modules: reportConnection.modules,
        meta: reportConnection.meta,
        providers_connections_products_ids: reportConnection.providers_connections_products_ids,
        is_enabled: reportConnection.is_enabled,
        channels_connections: reportConnection.channels_connections,
    }

    const response = await axiosInstance.post("/analytics/reports", reportConnectionDto);

    if (!response?.data) return null;

    return mapDtoToReportConnection(response.data);
}

export async function editReportConnection(reportConnection) {
    const reportConnectionDto = {
        name: reportConnection.name,
        modules: reportConnection.modules,
        meta: reportConnection.meta,
        providers_connections_products_ids: reportConnection.providers_connections_products_ids,
        is_enabled: reportConnection.is_enabled,
        channels_connections: reportConnection.channels_connections
    }

    const response = await axiosInstance.put(`/analytics/reports/${reportConnection.id}`, reportConnectionDto);

    if (!response?.data) return null;

    return mapDtoToReportConnection(response.data);
}

export async function renderModule(module) {
    const response = await axiosInstance.post("/analytics/reports/module_render", module);

    if (!response?.data) return null;

    return mapDtoToModule(response.data);
}

export async function renderReportConnectionDelivery(reportConnection, deliveryId) {
    const response = await axiosInstance.post(`/analytics/reports/${reportConnection.id}/deliveries/${deliveryId}`);

    if (!response?.data) return null;

    return mapDtoToModule(response.data);
}

export async function renderReportConnection(reportConnection, date) {
    const response = await axiosInstance.post(`/analytics/reports/${reportConnection.id}/render`, { date: date.toISOString().slice(0, 10) });

    if (!response?.data) return null;

    return mapDtoToModule(response.data);
}

export async function getReportConnection(reportConnectionId) {
    const response = await axiosInstance.get(`/analytics/reports/${reportConnectionId}`);

    if (!response?.data) return null;

    return mapDtoToReportConnection(response.data);
}

export async function getReportConnectionDeliveries(reportConnectionId) {
    const response = await axiosInstance.get(`/analytics/reports/${reportConnectionId}/deliveries`);

    if (!response?.data) return {};

    return response.data.items;
}