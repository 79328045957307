
import PropTypes from "prop-types";
import React from "react";

const Reset = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="m13 11.6 2.5 2.5a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275l-2.8-2.8a1 1 0 0 1-.3-.725V8c0-.283.096-.521.288-.713A.964.964 0 0 1 12 7c.283 0 .521.096.713.288.192.192.288.43.287.712v3.6ZM12 21c-2.017 0-3.825-.596-5.425-1.788-1.6-1.192-2.675-2.746-3.225-4.662-.083-.3-.054-.583.088-.85a.95.95 0 0 1 .662-.5.826.826 0 0 1 .763.188c.225.192.38.43.462.712a6.571 6.571 0 0 0 2.513 3.55C9.08 18.55 10.468 19 12 19c1.95 0 3.604-.68 4.963-2.038C18.322 15.603 19.001 13.949 19 12c0-1.95-.68-3.604-2.038-4.963C15.603 5.678 13.949 4.999 12 5a6.75 6.75 0 0 0-3.225.8A7.432 7.432 0 0 0 6.25 8H8c.283 0 .521.096.713.288.192.192.288.43.287.712a.968.968 0 0 1-.288.713A.964.964 0 0 1 8 10H4a.968.968 0 0 1-.713-.288A.964.964 0 0 1 3 9V5c0-.283.096-.521.288-.713A.964.964 0 0 1 4 4c.283 0 .521.096.713.288.192.192.288.43.287.712v1.35a8.73 8.73 0 0 1 3.113-2.475A8.932 8.932 0 0 1 12 3c1.25 0 2.421.238 3.513.713a9.16 9.16 0 0 1 2.85 1.924 9.167 9.167 0 0 1 1.925 2.85A8.689 8.689 0 0 1 21 12c0 1.25-.238 2.421-.713 3.513a9.16 9.16 0 0 1-1.924 2.85 9.167 9.167 0 0 1-2.85 1.925A8.689 8.689 0 0 1 12 21Z" fill={color}/>

    </svg>
);

export default Reset;

Reset.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
