import React, { useState } from "react";
import classes from './BannerAddProviderOnboarding.module.css';
import addAccountImage from './add-account.svg';
import Button from "Components/Button";
import ProviderConnectionCreate from "Sections/ProviderConnectionCreate";
import propTypes from "prop-types";
import clsx from "clsx";
import useMediaQuery from "Hooks/useMediaQuery";
import Dialog from "Components/Dialog";
import { useTranslation } from "react-i18next";
import useProvidersConnections from "../../Hooks/useProvidersConnections";

export default function BannerAddProviderOnboarding({ className }) {
    const [open, setOpen] = useState(false);
    const isDesktop = useMediaQuery("(min-width: 768px)");
    const buttonSize = isDesktop ? "large" : "small";
    const { fakeProviders } = useProvidersConnections();

    const { t } = useTranslation("onboarding");

    if (fakeProviders.length === 0) return null;

    const closeModal = () => setOpen(false)

    return (
        <>
            <Dialog
                open={open}
                onClose={closeModal}
                size="sm"
            >
                <ProviderConnectionCreate
                    onConnect={closeModal}
                    onCancel={closeModal}
                />
            </Dialog>

            <div className={clsx(classes.banner, className)}>
                <div className={classes.layout}>
                    <div className={classes.image}>
                        <img src={addAccountImage} alt="Add account" />
                    </div>
                    <div className={classes.content}>
                        <div className={classes.textSmall}>
                            {t("this-is-fictional-data")}
                        </div>
                        <div className={classes.title}>
                            {t("add-your-first-bank-to-see-real-data")}
                        </div>
                    </div>
                    <div className={classes.action}>
                        <Button size={buttonSize} className={classes.button} onClick={() => setOpen(true)}>
                            {t("add-bank")}
                        </Button>
                    </div>
                </div>
            </div>

        </>
    )
}

BannerAddProviderOnboarding.propTypes = {
    className: propTypes.string,
};