
import PropTypes from "prop-types";
import React from "react";

const NavTransactions = ({ color, size, ...props }) => (
    <svg viewBox="0 0 28 28" width={size} height={size} {...props}>
        
	<path d="M6 11.497C6 8.284 8.49 5.75 11.477 5.75s5.477 2.534 5.477 5.747-2.49 5.747-5.477 5.747S6 14.71 6 11.497Zm5.477-7.747C7.309 3.75 4 7.257 4 11.497s3.31 7.747 7.477 7.747a7.265 7.265 0 0 0 4.53-1.583l5.111 5.323a1 1 0 1 0 1.443-1.385l-5.16-5.375a7.909 7.909 0 0 0 1.553-4.727c0-4.24-3.31-7.747-7.477-7.747Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default NavTransactions;

NavTransactions.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
