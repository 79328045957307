import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useUser } from "Components/UserProvider/useUser";
import { ROUTES } from "Utils/constants";

export default function GuardOnboarding() {
    const { user } = useUser();

    if (!user) {
        return <Navigate to={ROUTES.signIn} />;
    }

    if (user.is_onboarded) {
        return <Navigate to={ROUTES.dashboards} />;
    }

    return <Outlet />;
}