import { createContext, useContext } from "react";

const FullScreenDialogContext = createContext(null);

export const FullScreenDialogProvider = FullScreenDialogContext.Provider;

export const useFullScreenDialog = () => {
    const context = useContext(FullScreenDialogContext);
    if (!context) {
        throw new Error(`useFullScreenDialog must be used within a FullScreenDialogProvider`);
    }
    return context;
}