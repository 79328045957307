
import PropTypes from "prop-types";
import React from "react";

const ModuleOther = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M9 2H4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h5a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2Zm0 2v5H4V4h5ZM17.5 3c-.685 0-1.312.387-1.618 1l-2.053 4.106A2 2 0 0 0 15.618 11h3.764a2 2 0 0 0 1.789-2.894L19.118 4c-.306-.613-.933-1-1.618-1Zm-.001 2.235L19.382 9h-3.764l1.881-3.765ZM6.5 13a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9Zm0 2a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM20 13h-5a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h5a2 2 0 0 0 2-2v-5a2 2 0 0 0-2-2Zm0 2v5h-5v-5h5Z"/>
	</g>

    </svg>
);

export default ModuleOther;

ModuleOther.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
