import React from "react";
import PropTypes from "prop-types";
import classes from "./Dropdown.module.css";
import clsx from "clsx";
import { useDropdown } from './DropdownContext';
import Icon from "Components/Icon";

export default function DropdownToggle({ children, className }) {
    const { isOpen, reference, getReferenceProps } = useDropdown();

    return (
        <button type="button" ref={reference} className={clsx(classes.toggle, className, { [classes.open]: isOpen })} {...getReferenceProps()}>
            {children}
            <Icon name="chevron-down" color="currentColor" />
        </button>
    );
}

DropdownToggle.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};