import { rest } from 'msw'
import { API_HOST } from 'config'
import categories from "./fixtures/categories.json"
import tags from "./fixtures/tags.json"
import transactions from "./fixtures/transactions.json"
import providers from "./fixtures/providers.json"
import allProviders from "./fixtures/allProviders.json"

import { v4 as uuid } from 'uuid';

const providershandlers = [
    rest.get(`${API_HOST}/providers/connections`, (req, res, ctx) => {
        return res(
            ctx.json(providers),
        )
    }),
    rest.post(`${API_HOST}/providers/connections/sort`, async (req, res, ctx) => {
        const ids = await req.json();
        const newProviders = providers.sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));

        return res(
            ctx.status(201),
            ctx.json(newProviders),
        )
    }),
    rest.delete(`${API_HOST}/providers/connections/:id`, async (req, res, ctx) => {
        const { id } = req.params;
        const index = providers.findIndex((provider) => provider.id === id)
        providers.splice(index, 1);

        return res(
            ctx.status(204),
        )
    }),
    rest.get(`${API_HOST}/providers/transactions/categories`, (req, res, ctx) => {
        return res(
            ctx.json(categories),
        )
    }),
    rest.get(`${API_HOST}/providers/transactions/tags`, (req, res, ctx) => {
        return res(
            ctx.json(tags),
        )
    }),

    rest.post(`${API_HOST}/providers/transactions/tags`, async (req, res, ctx) => {
        const { name } = await req.json();
        const tag = {
            id: uuid(),
            name,
            creation_date: new Date().toISOString()
        }

        tags.push(tag);

        return res(
            ctx.status(201),
            ctx.json(tag),

        )
    }),
    rest.get(`${API_HOST}/providers/transactions`, (req, res, ctx) => {

        const page = req.url.searchParams.get('page') || 1;
        const query = req.url.searchParams.get('q');
        const categories = req.url.searchParams.get('categories')?.split(",");

        const results = transactions.filter((transaction) => {
            if (query && !transaction.description.toLowerCase().includes(query.toLowerCase())) {
                return false;
            }
            if (categories && !categories.includes(transaction.category)) {
                return false;
            }
            return true;
        })

        return res(
            ctx.delay(600),
            ctx.json({
                count: results.length,
                items: results.slice((page - 1) * 10, page * 10)
            }),
        )
    }),
    rest.get(`${API_HOST}/providers/transactions/:id`, (req, res, ctx) => {
        const { id } = req.params;
        const transaction = transactions.find((transaction) => transaction.id === id);
        return res(
            ctx.json(transaction),
        )
    }),
    rest.put(`${API_HOST}/providers/transactions/:id`, async (req, res, ctx) => {
        const { id } = req.params;
        const transactionBody = await req.json();
        const index = transactions.findIndex((transaction) => transaction.id === id)
        const transaction = {
            ...transactions[index],
            ...transactionBody
        }
        transactions[index] = transaction;

        return res(
            ctx.json(transaction),
        )
    }),

    rest.get(`${API_HOST}/providers`, (req, res, ctx) => {
        return res(
            ctx.json(allProviders),
        )
    })
]

export default providershandlers