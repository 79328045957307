import { motion } from "framer-motion";
import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
import classes from "./Tabs.module.css";
import clsx from "clsx";

const TabContext = createContext({
    activeTab: "",
    setActiveTab: () => { },
});

export default function Tabs(props) {
    const { value, onChange, children, className } = props;

    return (
        <TabContext.Provider value={{ activeTab: value, setActiveTab: onChange }}>
            <div className={clsx(classes.tabs, className)}>
                {children}
            </div>
        </TabContext.Provider>
    );
}

Tabs.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
}

export function Tab(props) {
    const { children, value, className } = props
    const { activeTab, setActiveTab } = useContext(TabContext);

    return (
        <button
            type="button"
            onClick={() => setActiveTab(value)}
            className={clsx(classes.tab, activeTab === value && classes.active)}
        >
            {activeTab === value && (
                <motion.span
                    layoutId="bubble"
                    className={classes.bubble}
                    transition={{ type: "spring", bounce: 0.2, duration: 0.4 }}
                />
            )}
            <div className={clsx(classes.tabContent, className)}>{children}</div>
        </button>
    )
}

Tab.propTypes = {
    value: PropTypes.string.isRequired,
    className: PropTypes.string,
    children: PropTypes.node,
}