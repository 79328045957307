import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { useSnackbar, SnackbarContent } from "notistack";
import classes from "./Snackbar.module.css";
import Button from "Components/Button";
import { useTranslation } from "react-i18next";

const SnackbarProviderConnectionSuccess = forwardRef(function SnackbarProviderConnectionSuccess(props, ref) {
    const { id, message } = props;
    const { closeSnackbar } = useSnackbar();

    const { t } = useTranslation("providers");

    return (
        <SnackbarContent ref={ref} className={classes.root}>
            <div className={classes.card}>
                <div className={classes.layout}>
                    <div className={classes.icon}>
                        <img src="/img/icon-done.svg" alt="" />
                    </div>
                    <div className={classes.content}>
                        <div className={classes.actionText}>{t("synchronized-bank")}</div>
                        <div className={classes.title}>{message}</div>
                    </div>
                    <div className={classes.action}>
                        <Button size="small" primary onClick={() => closeSnackbar(id)}>
                            {t("accept")}
                        </Button>
                    </div>
                </div>
            </div>
        </SnackbarContent>
    );
});

export default SnackbarProviderConnectionSuccess;

SnackbarProviderConnectionSuccess.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    message: PropTypes.string.isRequired,
};