
import PropTypes from "prop-types";
import React from "react";

const ModuleTop = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<path d="M27.3 6.046a2.572 2.572 0 0 0-1.864-.804h-2.235c.009-.402.015-.816.015-1.242H8.799c0 .426.006.84.015 1.242h-2.25c-.702 0-1.383.294-1.863.804a2.585 2.585 0 0 0-.696 1.926c.402 6.702 3.861 10.908 9.346 11.532L12.288 23.2a2.285 2.285 0 0 0-2.286 2.286V28h12.014v-2.514A2.285 2.285 0 0 0 19.73 23.2l-1.063-3.696c5.473-.63 8.93-4.833 9.328-11.532a2.594 2.594 0 0 0-.696-1.926ZM6.401 7.828a.177.177 0 0 1 .048-.135c.045-.048.099-.048.114-.048h2.364c.357 4.72 1.398 7.395 2.41 8.907-3.85-1.614-4.768-5.925-4.936-8.724Zm14.276 8.715c1.01-1.515 2.049-4.188 2.406-8.9h2.355c.018 0 .07 0 .114.047a.177.177 0 0 1 .048.135c-.17 2.8-1.086 7.098-4.923 8.718Z" fill={color}/>

    </svg>
);

export default ModuleTop;

ModuleTop.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
