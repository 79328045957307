import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import classes from "./SubscriptionBadgeRequired.module.css";
import { BUSINESS_SUBSCRIPTIONS } from "Utils/constants";
import useProjectSelected from "Hooks/useProjectSelected";
import { useTranslation } from "react-i18next";

export default function SubscriptionBadgeRequired(props) {
    const { type, className } = props;
    const { project } = useProjectSelected();

    const { t } = useTranslation("common");

    const badgeClassName = clsx(classes.badge, classes[type], className);

    if (type === "personal" && !project?.subscription) {
        return (
            <span className={badgeClassName}>
                {t("personal")}
            </span>
        )
    }

    if (type === "business" && !BUSINESS_SUBSCRIPTIONS.includes(project?.subscription?.plan)) {
        return (
            <span className={badgeClassName}>
                {t("business")}
            </span>
        )

    }
    return null
}

SubscriptionBadgeRequired.propTypes = {
    type: PropTypes.oneOf(["personal", "business"]).isRequired,
    className: PropTypes.string,
}