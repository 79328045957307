import React, { useState, useEffect } from "react";
import classes from "./CategorizerSlice.module.css";
import Chip from "Components/Chip";
import TransactionCard from "./TransactionCard";
import ButtonAction from "./ButtonAction";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
    motion,
    useAnimation,
    useTransform,
    useMotionValue
} from "framer-motion";
import useCategories from "Hooks/useCategories";
import { useTranslation } from "react-i18next";

const MAX_DISTANCE = 100;
const RESPONSE = {
    YES: "YES",
    NO: "NO"
};

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
const ACTIONS = {
    ACCEPT: "accept",
    REJECT: "reject",
}

export default function CategorizerSlice(props) {
    const { onCategorize, disabled = false, transaction, transaction_category_id, response } = props;

    const [isDragging, setIsDragging] = useState(false);
    const [currentResponse, setCurrentResponse] = useState(null);
    const animationControls = useAnimation();
    const { categories } = useCategories();

    const [constraints, setConstraints] = useState(() => ({
        minDistance: Math.min(window.innerWidth * 0.1, MAX_DISTANCE),
        fullDistance: window.innerWidth * 0.2
    }));

    const { t } = useTranslation("common");

    const x = useMotionValue(0);
    const rotate = useTransform(x, [-300, 300], [-12, 12]);

    const category = categories.find((category) => category.id === transaction_category_id);

    useEffect(() => {
        const handleResize = () => {
            setConstraints({
                minDistance: Math.min(window.innerWidth * 0.1, MAX_DISTANCE),
                fullDistance: window.innerWidth * 0.2
            });
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (disabled) return;

            if (event.key === "ArrowRight") {
                handleClickYes()
            } else if (event.key === "ArrowLeft") {
                handleClickNo()
            }
        }

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        }
    }, [disabled]);

    useEffect(() => {
        const handleChange = (value) => {
            if (Math.abs(value) > constraints.minDistance) {
                if (value < 0) {
                    setCurrentResponse(RESPONSE.NO);
                } else {
                    setCurrentResponse(RESPONSE.YES);
                }
            } else {
                setCurrentResponse(null);
            }
        };

        const unsubscribe = x.on("change", handleChange);

        return () => {
            unsubscribe();
        };
    }, [x, constraints.minDistance]);


    const handleDragEnd = async (_event, info) => {
        setIsDragging(false);

        if (Math.abs(info.offset.x) < constraints.minDistance) {
            animationControls.start({ x: 0 });
            return;
        }

        if (info.offset.x < 0) {
            await animationControls.start({
                x: -constraints.fullDistance,
                y: constraints.fullDistance * 0.1,
                opacity: 0,
                transition: { duration: 0.3 }
            });
            onCategorize(ACTIONS.REJECT);
        } else {
            await animationControls.start({
                x: constraints.fullDistance,
                y: constraints.fullDistance * 0.1,
                opacity: 0,
                transition: { duration: 0.3 }
            });
            onCategorize(ACTIONS.ACCEPT);
        }
    };

    const handleClickYes = async () => {
        await animationControls.start({
            x: constraints.fullDistance,
            y: constraints.fullDistance * 0.1,
            opacity: 0,
            transition: { duration: 0.3 }
        });
        onCategorize(ACTIONS.ACCEPT);
    };

    const handleClickNo = async () => {
        await animationControls.start({
            x: -constraints.fullDistance,
            y: constraints.fullDistance * 0.1,
            opacity: 0,
            transition: { duration: 0.3 }
        });
        onCategorize(ACTIONS.REJECT);
    };

    return (
        <div className={clsx(classes.slice, { [classes.disabled]: disabled, [classes.disableEvents]: disabled || !!response })}>
            <div className={clsx("mb-4", disabled && classes.cardDisabled)}>
                <motion.div
                    drag="x"
                    animate={animationControls}
                    style={{
                        x,
                        rotate: rotate
                    }}
                    dragElastic={0.2}
                    dragConstraints={{
                        left: -constraints.minDistance,
                        right: constraints.minDistance
                    }}
                    onDragEnd={handleDragEnd}
                    className={clsx(classes.cardDraggable, isDragging && classes.dragging, iOS && classes.disableSelection)}
                    onDragStart={() => setIsDragging(true)}
                >
                    <TransactionCard {...transaction} />
                </motion.div>
            </div>

            <div className={clsx("py-1 mb-4 d-flex flex-column align-items-center", disabled && classes.visibleHidden)}>
                <div className={classes.description}>
                    {t("categorizer.question")}
                </div>
                <div>
                    {category && (
                        <Chip className={classes.chip} color={category.color} icon={category.icon}>{category.name}</Chip>
                    )}
                </div>
            </div>

            <div className={clsx(classes.footer, disabled && classes.visibleHidden)}>
                <ButtonAction disabled={disabled} variant="red" actived={currentResponse === RESPONSE.NO || response === ACTIONS.REJECT} onClick={handleClickNo}>
                    {t("categorizer.no")}
                </ButtonAction>
                <ButtonAction disabled={disabled} variant="green" actived={currentResponse === RESPONSE.YES || response === ACTIONS.ACCEPT} onClick={handleClickYes}>
                    {t("categorizer.yes")}
                </ButtonAction>
            </div>
        </div>
    )
}

CategorizerSlice.propTypes = {
    onCategorize: PropTypes.func.isRequired,
    transaction: PropTypes.object.isRequired,
    transaction_category_id: PropTypes.string.isRequired,
    amount: PropTypes.number,
    currency: PropTypes.string,
    disabled: PropTypes.bool,
    response: PropTypes.oneOf([ACTIONS.ACCEPT, ACTIONS.REJECT])
}