import React, { forwardRef } from "react";
import Icon from "../Icon";
import InputAdornment from "../InputAdornment";
import PropTypes from "prop-types";
import TextFieldBase from "../TextFieldBase";

const Select = forwardRef((props, ref) => {
  const {
    multiple,
    component,
    endAdornment,
    ...htmlProps
  } = props;

    const defaultSelectAdornment = !multiple && (
      <InputAdornment position="end" isInteractive={false}>
        <Icon name="chevron-down" color="#222222" />
      </InputAdornment>
    );

    return (
      <TextFieldBase
        ref={ref}
        component={component ?? "select"}
        endAdornment={endAdornment || defaultSelectAdornment}
        multiple={multiple}
        {...htmlProps}
      />
    );
});

export default Select;

Select.displayName = "Select";

Select.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  id: PropTypes.string,
  endAdornment: PropTypes.node,
  startAdornment: PropTypes.node,
  helperText: PropTypes.node,
  size: PropTypes.oneOf(["medium", "large"]),
};

