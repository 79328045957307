
import PropTypes from "prop-types";
import React from "react";

const Split = ({ color, size, ...props }) => (
    <svg viewBox="0 0 20 20" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M16.03 1.97a.75.75 0 0 0-1.06 1.06L16.939 5l-1.97 1.97a.75.75 0 0 0-.072.976l.073.084a.75.75 0 0 0 1.06 0l2.5-2.5a.75.75 0 0 0 0-1.06l-2.5-2.5ZM16.03 11.97a.75.75 0 0 0-1.06 1.06L16.939 15l-1.97 1.97a.75.75 0 0 0-.072.976l.073.084a.75.75 0 0 0 1.06 0l2.5-2.5a.75.75 0 0 0 0-1.06l-2.5-2.5Z"/>
		<path d="M13 4.25a5.75 5.75 0 0 0 0 11.5h5a.75.75 0 1 0 0-1.5h-5a4.25 4.25 0 1 1 0-8.5h5a.75.75 0 0 0 0-1.5h-5Z"/>
		<path d="M8 9.25a.75.75 0 0 1 0 1.5H2a.75.75 0 0 1 0-1.5h6Z"/>
	</g>

    </svg>
);

export default Split;

Split.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
