import { useMemo } from "react";
import useProviders from "./useProviders";

export default function useProviderConnector(connectorId) {
    const { providers, isLoading } = useProviders();

    const connector = useMemo(() => {
        if (!connectorId) return null;

        const connectors = providers.flatMap(provider => provider.connectors);
        return connectors.find(connector => connector.id === connectorId);
    }, [connectorId, providers]);

    return {
        connector,
        isLoading
    };
}