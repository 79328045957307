
import PropTypes from "prop-types";
import React from "react";

const Ignore = ({ color, size, ...props }) => (
    <svg viewBox="0 0 16 16" width={size} height={size} {...props}>
        
	<path d="M7.998 3.522c-.36 0-.718.04-1.068.123a.75.75 0 1 1-.342-1.461 6.14 6.14 0 0 1 1.413-.162c2.367 0 4.177 1.352 5.347 2.601a12.383 12.383 0 0 1 1.696 2.306 7.58 7.58 0 0 1 .116.214l.007.014.002.005.001.001-.67.337.67-.336a.75.75 0 0 1-.009.69 11.68 11.68 0 0 1-1.364 2.014.75.75 0 1 1-1.147-.966c.37-.44.7-.909.991-1.403a10.878 10.878 0 0 0-1.387-1.85C11.209 4.534 9.768 3.522 8 3.522h-.002Zm-2.912.013a.75.75 0 0 1-.14 1.05 10.152 10.152 0 0 0-2.587 2.916A10.874 10.874 0 0 0 3.746 9.35c1.044 1.114 2.482 2.125 4.248 2.127a5.2 5.2 0 0 0 3.061-1.064.75.75 0 0 1 .91 1.193 6.7 6.7 0 0 1-3.953 1.37L8 12.978c-2.368 0-4.177-1.352-5.348-2.6A12.381 12.381 0 0 1 .956 8.07a7.449 7.449 0 0 1-.116-.215l-.007-.014-.002-.004-.001-.002.67-.336-.67.335a.75.75 0 0 1 .01-.69 11.652 11.652 0 0 1 3.195-3.751.75.75 0 0 1 1.051.14Zm2.21 2.2a.75.75 0 0 1-.037 1.06 1.023 1.023 0 1 0 1.445 1.446.75.75 0 1 1 1.097 1.023 2.523 2.523 0 1 1-3.565-3.566.75.75 0 0 1 1.06.038Z" fill={color} fillRule="evenodd"/>
	<path d="M.97.47a.75.75 0 0 1 1.06 0l13 13a.75.75 0 1 1-1.06 1.06l-13-13a.75.75 0 0 1 0-1.06Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default Ignore;

Ignore.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
