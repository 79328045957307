
import PropTypes from "prop-types";
import React from "react";

const Business = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M27 8H6a3 3 0 0 0-3 3v13a3 3 0 0 0 3 3h21a3 3 0 0 0 3-3V11a3 3 0 0 0-3-3ZM6 10h21a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1Z" fill={color}/>
		<path d="M18.15 4c2.055 0 3.738 1.235 3.845 3.14l.005.193V9a1 1 0 0 1-1.993.117L20 9V7.333c0-.731-.652-1.27-1.667-1.328L18.15 6h-3.5c-.994 0-1.579.444-1.644 1.19L13 7.333V9a1 1 0 0 1-1.993.117L11 9V7.333c0-1.95 1.455-3.245 3.442-3.329L14.65 4h3.5ZM5 11v1a3 3 0 0 0 2.824 2.995L8 15h17a3 3 0 0 0 2.995-2.824L28 12v-1h2v1a5 5 0 0 1-4.783 4.995L25 17H8a5 5 0 0 1-4.995-4.783L3 12v-1h2Z" fill={color}/>
		<path d="M19 13a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h5Z" fill={color}/>
		<path d="M18 15h-3v2h3z" fill={color}/>
	</g>

    </svg>
);

export default Business;

Business.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
