import { InputAdornment, TextField } from "@mui/material";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { styled } from '@mui/material/styles';

const CssTextField = styled(TextField)({
    '& *': {
        fontFamily: 'var(--f-family-primary)!important',
    },
    '& div input': {
        background: 'white'
    },
    '& div.Mui-focused input': {
        color: '#2E16BA'
    },
    '& label.Mui-focused': {
        color: '#2E16BA'
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#2E16BA',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#DDDDDD',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#2E16BA',
        },
    }
});

const InputField = forwardRef((props, ref) => {
    const { type = "text", fullWidth = true, error, helperText, prefix, suffix, ...otherProps } = props;
    return (
        <CssTextField
            ref={ref}
            variant="outlined"
            type={type}
            error={!!error}
            helperText={error ? error : helperText}
            fullWidth={fullWidth}
            InputProps={{
                startAdornment: prefix ? <InputAdornment position="start">{prefix}</InputAdornment> : null,
                endAdornment: suffix ? <InputAdornment position="end">{suffix}</InputAdornment> : null
            }}
            {...otherProps}
        />
    )
})

InputField.displayName = "InputField";

InputField.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    type: PropTypes.string,
    error: PropTypes.string,
    helperText: PropTypes.string,
    fullWidth: PropTypes.bool,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    disabled: PropTypes.bool
};

export default InputField;