
import PropTypes from "prop-types";
import React from "react";

const Freelance = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M20 6H4a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3Zm0 2a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h16Z" fill={color}/>
	<path d="M14 2a3 3 0 0 1 3 3v16a1 1 0 1 1-2 0V5a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v16a1 1 0 1 1-2 0V5a3 3 0 0 1 3-3h4Z" fill={color}/>

    </svg>
);

export default Freelance;

Freelance.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
