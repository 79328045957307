import { rest } from 'msw'
import { API_HOST } from 'config'
import alerts from "./fixtures/alerts.json"
import placeholderAlertCreated from "./fixtures/alertCreated.json"

const alertsHandlers = [
    rest.get(`${API_HOST}/automations/alerts`, (req, res, ctx) => {
        return res(
            ctx.json(alerts),
        )
    }),
    rest.post(`${API_HOST}/automations/sort`, async (req, res, ctx) => {
        const alersIds = await req.json();

        return res(
            ctx.json(alerts.sort((a, b) => alersIds.indexOf(a.id) - alersIds.indexOf(b.id))),
        )
    }),
    rest.delete(`${API_HOST}/automations/:id`, async (req, res, ctx) => {
        const { id } = req.params;
        const alertIndex = alerts.findIndex(alert => alert.id === id);

        alerts.splice(alertIndex, 1);

        return res(
            ctx.delay(1000),
            ctx.json(alerts),
        )
    }),
    rest.post(`${API_HOST}/automations`, async (req, res, ctx) => {
        const alert = await req.json();

        const alertCreated = {
            ...placeholderAlertCreated,
            name: alert.name,
            rules: alert.rules,
        }

        alerts.push(alertCreated);

        return res(
            ctx.status(201),
            ctx.json(alertCreated),
        )
    }),
    rest.put(`${API_HOST}/automations/:id`, async (req, res, ctx) => {
        const { id } = req.params;
        const alert = await req.json();
        const alertIndex = alerts.findIndex(alert => alert.id === id);

        const updatedAlert = { ...alerts[alertIndex], name: alert.name, rules: alert.rules };
        alerts[alertIndex] = updatedAlert;

        return res(
            ctx.status(200),
            ctx.json(updatedAlert),
        )
    })
]

export default alertsHandlers