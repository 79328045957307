
import PropTypes from "prop-types";
import React from "react";

const History = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="m1.587 3.653 1.67 1.67a11 11 0 1 1-2.2 7.815 1.031 1.031 0 1 1 2.053-.212 8.935 8.935 0 1 0 1.622-6.128L6.6 8.665a.344.344 0 0 1-.243.587H1.344A.344.344 0 0 1 1 8.908V3.896a.344.344 0 0 1 .587-.243Zm10.067 2.85a1.031 1.031 0 0 1 1.031 1.03v4.113l2.788 1.116a1.031 1.031 0 0 1-.766 1.914L11.271 13.3a1.031 1.031 0 0 1-.648-.957v-4.81a1.031 1.031 0 0 1 1.031-1.032Z" fill={color}/>

    </svg>
);

export default History;

History.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
