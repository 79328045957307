
import PropTypes from "prop-types";
import React from "react";

const ModuleVarious = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<path d="M25.6 6a6.4 6.4 0 0 1 6.4 6.4v8.533a6.4 6.4 0 0 1-6.4 6.4H6.4a6.4 6.4 0 0 1-6.4-6.4V12.4A6.4 6.4 0 0 1 6.4 6h19.2Zm0 4.267H6.4A2.133 2.133 0 0 0 4.267 12.4v8.533c0 1.178.955 2.134 2.133 2.134h19.2a2.133 2.133 0 0 0 2.133-2.134V12.4a2.133 2.133 0 0 0-2.133-2.133Zm-16 4.266a2.133 2.133 0 1 1 0 4.267 2.133 2.133 0 0 1 0-4.267Zm6.4 0a2.133 2.133 0 1 1 0 4.267 2.133 2.133 0 0 1 0-4.267Zm6.4 0a2.133 2.133 0 1 1 0 4.267 2.133 2.133 0 0 1 0-4.267Z" fill={color}/>

    </svg>
);

export default ModuleVarious;

ModuleVarious.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
