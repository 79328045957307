
import PropTypes from "prop-types";
import React from "react";

const Education = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="evenodd">
		<path d="M25 21a1 1 0 0 1 .117 1.993L25 23H9.5a1.5 1.5 0 0 0-1.493 1.356L8 24.5a1 1 0 0 1-2 0 3.5 3.5 0 0 1 3.308-3.495L9.5 21H25Z" fill={color} fillRule="nonzero"/>
		<path d="M25 4H9.5A3.5 3.5 0 0 0 6 7.5v17A3.5 3.5 0 0 0 9.5 28H25a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1Zm-1 2v20H9.5A1.5 1.5 0 0 1 8 24.5v-17l.007-.144A1.5 1.5 0 0 1 9.5 6H24Z" fill={color} fillRule="nonzero"/>
		<path d="M12 12h8-8ZM12 15h5.714H12Z" fill={color} stroke={color} strokeLinejoin="round" strokeWidth="2"/>
	</g>

    </svg>
);

export default Education;

Education.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
