import React from "react";
import PropTypes from "prop-types";
import classes from "./Dropdown.module.css";
import clsx from "clsx";

export default function DropdownDivider({ className }) {
    return (
        <div
            className={clsx(classes.divider, className)}
        />
    );
}

DropdownDivider.propTypes = {
    className: PropTypes.string,
};