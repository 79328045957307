
import PropTypes from "prop-types";
import React from "react";

const SavingsInvestments = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="evenodd" transform="translate(2 4)">
		<path d="M19.69.003c-2.25.051-4.035.665-5.205 1.9l-.11.121-.339-.016A16.947 16.947 0 0 0 13.5 2C6.137 2 0 6.803 0 13l.005.314c.097 2.821 1.487 5.416 3.794 7.352l.201.165v1.168A3 3 0 0 0 7 25h1l.176-.005a2.997 2.997 0 0 0 2.265-1.25l.016-.026.415.074c.859.137 1.738.207 2.628.207l.533-.008a16.591 16.591 0 0 0 2.096-.2l.413-.073.017.026A2.996 2.996 0 0 0 19 25h1l.176-.005A3 3 0 0 0 23 22l.002-1.17c1.316-1.06 2.358-2.335 3.047-3.753l.036-.08L26 17a3 3 0 0 0 3-3v-3l-.005-.176A3 3 0 0 0 26 8h-.463l-.07-.112c-.81-1.264-1.912-2.38-3.236-3.292L22 4.44V1.999A2 2 0 0 0 20 0l-.31.003ZM20 2l.001 3.575c2.055 1.104 3.63 2.718 4.424 4.608A.983.983 0 0 1 25 10h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1a1 1 0 0 1-.275-.038c-.545 1.924-1.88 3.618-3.725 4.86V22a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-.715c-1.382.46-2.903.715-4.5.715s-3.117-.255-4.5-.715V22a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2.177C3.552 18.173 2 15.728 2 13c0-4.97 5.149-9 11.5-9 .637 0 1.263.04 1.872.119C16.031 2.706 17.574 2 20 2Z" fillRule="nonzero"/>
		<path d="M13.5 6a1 1 0 0 1 0 2c-1.476 0-2.908.233-4.228.679a1 1 0 0 1-.64-1.895A15.245 15.245 0 0 1 13.5 6Z" fillRule="nonzero"/>
		<circle cx="20" cy="9" r="1"/>
	</g>

    </svg>
);

export default SavingsInvestments;

SavingsInvestments.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
