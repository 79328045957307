
import PropTypes from "prop-types";
import React from "react";

const OwnTransfer = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M30.707 10.293a1 1 0 0 1 .083 1.32l-.083.094-4 4a1 1 0 0 1-1.32.083l-.094-.083-4-4a1 1 0 0 1 1.32-1.497l.094.083L26 13.585l3.293-3.292a1 1 0 0 1 1.32-.083l.094.083Z"/>
		<path d="M23 5a4 4 0 0 1 3.995 3.8L27 9v5a1 1 0 0 1-1.993.117L25 14V9a2 2 0 0 0-1.85-1.995L23 7H10a2 2 0 0 0-1.995 1.85L8 9v5a1 1 0 0 1-1.993.117L6 14V9a4 4 0 0 1 3.8-3.995L10 5h13ZM7 17a4 4 0 1 1 0 8 4 4 0 0 1 0-8Zm0 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM26 17a4 4 0 1 1 0 8 4 4 0 0 1 0-8Zm0 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"/>
	</g>

    </svg>
);

export default OwnTransfer;

OwnTransfer.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
