import React from "react";
import PropTypes from "prop-types";
import classes from './Spinner.module.css';
import clsx from 'clsx';

const Spinner = ({ className, size = 32, color = "currentColor" }) => (
    <svg className={clsx(classes.spinner, className)} width={size} height={size} viewBox="0 0 66 66">
        <circle className={classes.path} fill="none" stroke={color} strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
);

Spinner.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string
};

export default Spinner;