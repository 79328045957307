
import PropTypes from "prop-types";
import React from "react";

const Globe = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1Zm0 2a9 9 0 1 1 0 18 9 9 0 0 1 0-18Z" fill={color}/>
	<path d="M22 11a1 1 0 1 1 0 2H2a1 1 0 1 1 0-2h20Z" fill={color}/>
	<path d="M11.261 1.326A16.3 16.3 0 0 0 7 11.979a16.322 16.322 0 0 0 4.261 10.695 1 1 0 0 0 1.477 0A16.3 16.3 0 0 0 17 12.021a16.322 16.322 0 0 0-4.262-10.695 1 1 0 0 0-1.477 0ZM12 3.55a14.308 14.308 0 0 1 3 8.47l-.013.38a14.27 14.27 0 0 1-2.988 8.049 14.308 14.308 0 0 1-3-8.47 14.272 14.272 0 0 1 3-8.429Z" fill={color}/>

    </svg>
);

export default Globe;

Globe.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
