
import PropTypes from "prop-types";
import React from "react";

const Salary = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M16 7a1 1 0 0 1 .993.883L17 8v10a1 1 0 0 1-1.993.117L15 18V8a1 1 0 0 1 1-1Z"/>
		<path d="M10.293 13.293a1 1 0 0 1 1.32-.083l.094.083L16 17.585l4.293-4.292a1 1 0 0 1 1.32-.083l.094.083a1 1 0 0 1 .083 1.32l-.083.094-5 5a1 1 0 0 1-1.32.083l-.094-.083-5-5a1 1 0 0 1 0-1.414Z"/>
		<path d="M27 17a1 1 0 0 1 .993.883L28 18v4c0 1.552-1.012 2.897-2.409 2.994L25.43 25H6.57c-1.425 0-2.49-1.288-2.567-2.822L4 22v-4a1 1 0 0 1 1.993-.117L6 18v4c0 .552.27.937.512.993l.06.007h18.857c.235 0 .523-.343.566-.866L26 22v-4a1 1 0 0 1 1-1Z"/>
	</g>

    </svg>
);

export default Salary;

Salary.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
