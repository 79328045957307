import axiosInstance from "Utils/axiosInstance";
import { trackError } from "Utils/errorMonitoring";
import { IS_PRODUCTION, COMMIT_HASH } from "config";

export async function getCountries() {
    const response = await axiosInstance.get("/auth/countries");
    return response?.data;
}

export async function getCurrencies() {
    const response = await axiosInstance.get("/auth/currencies");
    return response?.data;
}

export async function getLanguages() {
    // const response = await axiosInstance.get("/auth/languages");

    return [
        { code: 'ES', name: 'Spanish', name_es: 'Castellano' },
        { code: 'EN', name: 'English', name_es: 'Inglés' },
    ]
}

export async function getTimeZones() {
    const response = await axiosInstance.get("/auth/timezones");
    return response?.data;
}

export async function getIsLatestAppVersion() {
    if (!IS_PRODUCTION || !COMMIT_HASH) {
        return true;
    }

    try {
        const response = await axiosInstance.get("/auth/version");
        return response?.data?.version === COMMIT_HASH;
    } catch (error) {
        trackError(error);
        return true
    }
}

export async function getChatToken() {
    const response = await axiosInstance.get("/auth/users/me/chat_init");

    if (!response?.data) {
        return null;
    }

    return response.data.token;
}