import React, { useEffect, useMemo, useState } from 'react';
import classes from "./HeaderOnboarding.module.css";
import PropTypes from 'prop-types';
import { useUser } from "Components/UserProvider/useUser";
import {
    FloatingFocusManager,
    autoUpdate,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useRole,
    FloatingPortal,
    offset,
} from "@floating-ui/react";
import { ONBOARDING_TASKS } from 'Utils/constants';
import { Link } from 'react-router-dom';
import imageStar from "./assets/icon-star.svg";
import imageCheck from "./assets/icon-check.svg";
import imageArrow from "./assets/icon-arrow.svg";
import clsx from 'clsx';
import { useEventDispatcher } from 'Components/EventDispatcherContext';
import {
    ADD_PROVIDER_CONNECTION,
    ADD_DASHBOARD,
    ADD_REPORT_CONNECTION,
    ADD_ALERT_CONNECTION,
    ADD_AUTOMATION,
    ADD_CATEGORY
} from "Utils/eventTypes";
import { getUser } from 'Services/user';
import Icon from 'Components/Icon';
import IconButton from 'Components/IconButton';
import { useTranslation } from 'react-i18next';

const RADIO = 19;

export default function HeaderOnboarding() {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation("common");

    const { x, y, refs, strategy, context } = useFloating({
        whileElementsMounted: autoUpdate,
        placement: "bottom-end",
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [offset({ crossAxis: 24 })],
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        useClick(context),
        useRole(context, { role: "menu" }),
        useDismiss(context),
    ]);

    const lenghtCircle = useMemo(() => 2 * Math.PI * RADIO, []);
    const { user, refreshUser } = useUser();
    const percentage = useMemo(() => Math.round((user.activation_steps_completed.length / ONBOARDING_TASKS.length) * 100), [user]);
    const percentageCircle = useMemo(() => lenghtCircle * ((100 - percentage) / 100), [lenghtCircle, percentage]);
    const isCompletedOnboarding = useMemo(() => ONBOARDING_TASKS.every(({ id }) => user.activation_steps_completed.includes(id)), [user]);
    const tasks = useMemo(() => ONBOARDING_TASKS.map((task) => ({
        ...task,
        completed: user.activation_steps_completed.includes(task.id)
    })), [user]);

    const eventDispatcher = useEventDispatcher();

    useEffect(() => {
        if (isCompletedOnboarding) return;

        const requestRefreshUser = async () => {
            const userUpdated = await getUser();
            refreshUser(userUpdated);
        }

        const unsubscribeAddProvider = eventDispatcher.subscribe(ADD_PROVIDER_CONNECTION, requestRefreshUser);
        const unsubscribeAddDashboard = eventDispatcher.subscribe(ADD_DASHBOARD, requestRefreshUser);
        const unsubscribeAddReport = eventDispatcher.subscribe(ADD_REPORT_CONNECTION, requestRefreshUser);
        const unsubscribeAddAlert = eventDispatcher.subscribe(ADD_ALERT_CONNECTION, requestRefreshUser);
        const unsubscribeAddAutomation = eventDispatcher.subscribe(ADD_AUTOMATION, requestRefreshUser);
        const unsubscribeAddCategory = eventDispatcher.subscribe(ADD_CATEGORY, requestRefreshUser);
        // const unsubscribeSearchTransactions = eventDispatcher.subscribe(SEARCH_TRANSACTIONS, async () => {
        //     const userUpdated = await getUser();
        //
        //     if (!userUpdated) return;
        //
        //     refreshUser({
        //         ...user,
        //         activation_steps_completed: [...userUpdated.activation_steps_completed, "search_transaction"]
        //     });
        // });

        return () => {
            unsubscribeAddProvider();
            unsubscribeAddDashboard();
            unsubscribeAddReport();
            unsubscribeAddAlert();
            unsubscribeAddAutomation();
            unsubscribeAddCategory();
            // unsubscribeSearchTransactions();
        }
    }, [isCompletedOnboarding])

    if (isCompletedOnboarding) {
        return null
    }

    const closeMenu = () => setIsOpen(!isOpen);

    return (
        <>
            <button type='button' className={classes.button} ref={refs.setReference} {...getReferenceProps()}>
                <svg className={classes.svg} width="44" height="44" viewBox="0 0 44 44" fill="none">
                    <circle cx="22" cy="22" r={RADIO} strokeDashoffset={percentageCircle} strokeDasharray={lenghtCircle} strokeMiterlimit="10" strokeWidth="2px" />
                </svg>
                <div className={classes.bgButton}>
                    <img src={imageStar} alt="" />
                </div>
            </button>
            {isOpen && (
                <FloatingPortal>
                    <FloatingFocusManager context={context}>
                        <div {...getFloatingProps({
                            ref: refs.setFloating,
                            style: {
                                position: strategy,
                                top: y ?? 0,
                                left: x ?? 0,
                                zIndex: "var(--z-index-dropdown)",
                            }
                        })}>
                            <div className={classes.menu}>
                                <div className={classes.close}>
                                    <IconButton onClick={closeMenu}>
                                        <Icon name="close" color="#CDCDCD" size={16} />
                                    </IconButton>
                                </div>

                                <div className='mb-2'>
                                    <div className={classes.percentage}>{percentage}%</div>
                                    <div className={classes.colorLabel}>
                                        {t("onboarding.complete")}
                                    </div>
                                </div>

                                <div className={classes.progressBar}>
                                    <div className={classes.progress} style={{ width: `${percentage}%` }} />
                                </div>

                                <ul className={classes.taskList}>
                                    {tasks.map(({ id, i18n, completed, link }) => (
                                        <li key={id}>
                                            <Link to={link} className={clsx(classes.taskLink, { [classes.isCompleted]: completed })} onClick={closeMenu}>
                                                <div className={classes.circle}>
                                                    {completed && <img className={classes.check} src={imageCheck} alt="" />}
                                                </div>
                                                <div className={classes.relative}>
                                                    {t(i18n)}
                                                    <div className={classes.arrow}>
                                                        <img src={imageArrow} alt="" />
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </FloatingFocusManager>
                </FloatingPortal>
            )}
        </>
    )
}

HeaderOnboarding.propTypes = {
    percentage: PropTypes.number
}