import React from "react";
import PropTypes from "prop-types";
import dialogClasses from "./FullScreenDialog.module.css";
import clsx from "clsx";
import { useFullScreenDialog } from "./FullScreenDialogContext";

const FullScreenDialogTitle = ({ className, children }) => {
    const { id } = useFullScreenDialog();

    return (
        <h2 className={clsx(dialogClasses.title, className)} id={id}>
            {children}
        </h2>
    );
}

FullScreenDialogTitle.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
}

export default FullScreenDialogTitle;