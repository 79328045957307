import React from "react";
import clsx from "clsx";
import classes from "./Drawer.module.css";
import PropTypes from "prop-types";

export function DrawerContent(props) {
    const { children, className } = props;

    return (
        <div className={clsx(classes.content, className)}>
            {children}
        </div>
    )
}

DrawerContent.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
}
