import "./Loading.css";
import PropTypes from "prop-types";
import React from "react";

export default function Loading({ message = "Cargando...", size = 65}) {
    return (
        <div className="text-center" role="alert" aria-busy="true" aria-label={message}>
            <svg className="spinner" width={size} height={size} viewBox="0 0 66 66">
                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
            </svg>
        </div>
    )
}

Loading.propTypes = {
    message: PropTypes.string,
    size: PropTypes.number,
};
