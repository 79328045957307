
import PropTypes from "prop-types";
import React from "react";

const Entertainment = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M29 6H3a3 3 0 0 0-3 3v4a1 1 0 0 0 1 1 2 2 0 0 1 .15 3.995L1 18a1 1 0 0 0-1 1v4a3 3 0 0 0 3 3h26a3 3 0 0 0 3-3v-4a1 1 0 0 0-.883-.993L31 18a2 2 0 1 1 0-4 1 1 0 0 0 1-1V9a3 3 0 0 0-3-3Zm0 2 .117.007A1 1 0 0 1 30 9v3.126l-.155.043A4.002 4.002 0 0 0 27 16l.005.2a4.002 4.002 0 0 0 2.84 3.63l.155.043V23a1 1 0 0 1-1 1H3l-.117-.007A1 1 0 0 1 2 23v-3.127l.155-.042A4.002 4.002 0 0 0 5 16l-.005-.2a4.002 4.002 0 0 0-2.84-3.63L2 12.125V9a1 1 0 0 1 1-1h26Z"/>
		<path d="M25 8v17h-2V8zM11.219 13.361l-3.094.45-.113.023a1 1 0 0 0-.44 1.682l2.237 2.182-.527 3.083-.013.11a1 1 0 0 0 1.464.944L13.5 20.38l2.767 1.455.101.046a1 1 0 0 0 1.35-1.1l-.528-3.083 2.239-2.182.078-.085a1 1 0 0 0-.632-1.62l-3.095-.45-1.383-2.804a1 1 0 0 0-1.794 0l-1.384 2.804Zm2.281-.102.72 1.459.06.106a1 1 0 0 0 .693.44l1.608.234-1.164 1.136-.082.09a1 1 0 0 0-.205.795l.274 1.602-1.439-.756-.111-.05a1 1 0 0 0-.82.05l-1.439.756.275-1.602.013-.122a1 1 0 0 0-.3-.763l-1.165-1.136 1.61-.233a1 1 0 0 0 .752-.547l.72-1.459Z"/>
	</g>

    </svg>
);

export default Entertainment;

Entertainment.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
