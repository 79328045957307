
import PropTypes from "prop-types";
import React from "react";

const MortgageLoans = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M25 6h-4a1 1 0 0 0-1 1v2.783c0 .337.17.553.453.737l4 3.06A1 1 0 0 0 26 12.742V7a1 1 0 0 0-1-1Zm-1 2v2.894l-2-1.652V8h2Z"/>
		<path d="m15.864 5.228-8.5 7A1 1 0 0 0 7 13v10c0 1.644 1.28 3 2.889 3H23.11C24.72 26 26 24.644 26 23V13a1 1 0 0 0-.364-.772l-8.5-7a1 1 0 0 0-1.272 0Zm.636 2.066 7.5 6.177V23c0 .565-.411 1-.889 1H9.89l-.101-.007C9.355 23.937 9 23.524 9 23v-9.53l7.5-6.176Z"/>
		<path d="M16.5 14a4.5 4.5 0 0 1 4.495 4.288L21 18.5V24a1 1 0 0 1-1.993.117L19 24v-5.5a2.5 2.5 0 0 0-4.995-.164L14 18.5V24a1 1 0 0 1-1.993.117L12 24v-5.5a4.5 4.5 0 0 1 4.5-4.5Z"/>
	</g>

    </svg>
);

export default MortgageLoans;

MortgageLoans.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
