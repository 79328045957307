import React from "react";
import PropTypes from "prop-types";
import Button from "Components/Button";
import usePlansAndPricingDialog from "Hooks/usePlansAndPricingDialog";
import classes from "./UpgradeButton.module.css";
import clsx from "clsx";
import UpIcon from "./UpIcon";
import useProjectSelected from "Hooks/useProjectSelected";
import { useTranslation } from "react-i18next";

export default function UpgradeButton({ className, narrow }) {
    const { openPlansAndPricing } = usePlansAndPricingDialog();
    const { project, isLoading } = useProjectSelected();
    const { t } = useTranslation("common");

    if (isLoading) {
        return null
    }


    if (project.subscription?.is_trial || !project.subscription) {
        return (
            <Button primary size="small" onClick={openPlansAndPricing} className={clsx(classes.gradientButton, narrow && classes.narrow, className)}>
                <div className={classes.content}>
                    <UpIcon />
                    <div className={classes.text}>
                        {t("upgrade")}
                    </div>
                </div>
            </Button>
        )
    }

    return null
}

UpgradeButton.propTypes = {
    className: PropTypes.string,
    narrow: PropTypes.bool,
};