import React, { useState } from "react";
import Button from "Components/Button";
import PropTypes from "prop-types";

export default function CreatingTotalMatch(props) {
    const { onClose, onClearConnection } = props;
    const [processing, setProcessing] = useState(false)

    const handleReject = async () => {
        setProcessing(true);
        await onClearConnection();
        onClose();
    }

    return (
        <div className="text-center">
            <img src="/img/icon-error.svg" alt="Icono de Error" />
            <h3 className="fw-500 mt-4">Banco Repetido</h3>
            <div className="mt-2 text-muted">El banco que has intentado añadir ya existe en tu cuenta de Banktrack.</div>
            <div className="mt-4">
                <Button primary size="large" isBusy={processing} onClick={handleReject}>Cerrar</Button>
            </div>
        </div>
    )
}

CreatingTotalMatch.propTypes = {
    onClose: PropTypes.func.isRequired,
    onClearConnection: PropTypes.func.isRequired,
};