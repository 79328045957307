import { useContext } from "react";
import { ErrorsContext } from "./ErrorsContext";

export function useErrors() {
    const context = useContext(ErrorsContext);

    if (!context) {
        throw new Error("useErrors must be used within a ErrorsProvider");
    }

    return context;
}