
import PropTypes from "prop-types";
import React from "react";

const Others = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<g fill={color} fillRule="nonzero">
		<path d="M29 2h-8a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2Zm-8 2h8v8h-8V4ZM14.926 8.68H5.46a2 2 0 0 0-1.564 3.248l4.733 5.93a2 2 0 0 0 3.126 0l4.733-5.93a2 2 0 0 0-1.563-3.247Zm-9.465 2h9.465l-4.733 5.931-4.732-5.93ZM19.5 16a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13Zm0 2a4.5 4.5 0 1 1 0 9 4.5 4.5 0 0 1 0-9Z"/>
	</g>

    </svg>
);

export default Others;

Others.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
