import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "Components/Button";
import MinimalLayout from "Components/MinimalLayout";
import { reactivateUsersMe } from "Services/user";
import { useErrors } from "Components/ErrorsProvider";

const Reactivate = ({ onReactivate }) => {
    const { showUnknownError } = useErrors();
    const [isProcessing, setIsProcessing] = useState(false);

    const reactivateUser = () => {
        if (isProcessing) return;

        setIsProcessing(true);

        reactivateUsersMe()
            .then(response => onReactivate(response))
            .catch(errorResponse => showUnknownError(errorResponse))
            .finally(() => setIsProcessing(false));
    }

    return (
        <MinimalLayout
            title="Reactivar cuenta de Banktrack"
            description="Tu cuenta fue eliminada anteriormente. Si quieres volver a activarla, pulsa en el botón inferior."
        >
            <div className="text-center">
                <div>
                    <Button primary size="large" isBusy={isProcessing} onClick={reactivateUser}>Reactivar Cuenta</Button>
                </div>
            </div>
        </MinimalLayout>
    );

}

export default Reactivate;

Reactivate.propTypes = {
    onReactivate: PropTypes.func.isRequired
}