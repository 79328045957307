import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { useEffect, useLayoutEffect, useState } from "react";
import { getAlertsConnections, sortAlertsConnections } from "Services/alerts";
import { getIntegrations } from "Services/integrations";
import { getProvidersConnections } from "Services/providersConnections";

export default function useAlertsConnections() {
    const { data, isLoading, isFetching } = useQuery(["alerts-connections"], getAlertsConnections)
    const queryClient = useQueryClient();

    const [alertsConnections, setAlertsConnections] = useState([]);

    useLayoutEffect(() => {
        if (!isLoading) {
            setAlertsConnections(data);
        }
    }, [data, isLoading]);

    useEffect(() => {
        queryClient.prefetchQuery(["integrations"], getIntegrations);
        queryClient.prefetchQuery(["providers-connections"], getProvidersConnections);
    }, [queryClient]);

    const { mutate: sort } = useMutation(sortAlertsConnections, {
        onMutate: (ids) => {
            const newAlertConnections = ids
                .map(id => alertsConnections.find(item => item.id === id))
                .filter(Boolean)
                .map((item, index) => ({ ...item, order: index + 1 }));

            setAlertsConnections(newAlertConnections);
            queryClient.setQueryData(["alerts-connections"], newAlertConnections);
        },
    });

    return { alertsConnections, isLoading, sort, isFetching }
}