import React from "react";
import Button from "Components/Button";
import classes from "./ErrorFallback.module.css";
import imageWrongPass from 'assets/icon-wrong-pass.svg';

const ErrorFallback = () => {
    const handleClick = () => {
        window.location.href = "/";
    }

    return (
        <div className={classes.page}>
            <div className={classes.container}>
                <img src={imageWrongPass} width={75} height={75} alt="" />
                <div className="mb-4">
                    <h3 className="fw-500 mt-4">Error Desconocido</h3>
                    <div className="d-block mt-2 text-muted">Ha ocurrido un error desconocido en nuestros sistemas y no hemos podido completar tu solicitud. <strong>Prueba a recargar la página o inténtalo de nuevo más tarde.</strong></div>
                </div>
                <Button onClick={handleClick}>Recargar la página</Button>
            </div>
        </div>
    );
}

export default ErrorFallback;