
import PropTypes from "prop-types";
import React from "react";

const NavProviders = ({ color, size, ...props }) => (
    <svg viewBox="0 0 32 32" width={size} height={size} {...props}>
        
	<path d="M27.214 14.714c.985 0 1.786-.801 1.786-1.786v-1.253c0-.658-.36-1.26-.94-1.572L16.923 4.106a.893.893 0 0 0-.845 0L4.94 10.103c-.58.312-.94.914-.94 1.572v1.253c0 .985.8 1.786 1.786 1.786h.893v8.929h-.893c-.985 0-1.786.8-1.786 1.785v1.786C4 28.2 4.8 29 5.786 29h21.428C28.2 29 29 28.2 29 27.214v-1.786c0-.984-.8-1.785-1.786-1.785h-.893v-8.93h.893ZM5.786 11.675 16.5 5.907l10.714 5.768v1.253H5.786v-1.253Zm11.607 11.968v-8.93h2.678v8.93h-2.678Zm-4.464 0v-8.93h2.678v8.93H12.93Zm-4.465-8.93h2.679v8.93H8.464v-8.93Zm18.751 12.501H5.785v-1.786h21.43v1.786Zm-2.678-3.571h-2.679v-8.93h2.679v8.93Z" fill={color} fillRule="nonzero"/>

    </svg>
);

export default NavProviders;

NavProviders.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
